<template>
  <div
    id="retour"
    class="flex flex-row space-x-2 items-center w-fit p-2 bg-bluevk text-white rounded shadow cursor-pointer"
    @click="$router.push('/articles')"
  >
    <span class="material-icons-round text-sm"> arrow_back_ios_new </span>
    <span>Retour</span>
  </div>
  <!-- modal pour ref croisés -->
  <div
    v-if="openRefCross"
    :class="`modal ${
      !openRefCross && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openRefCross = false), closeImport()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container w-fit h-fit"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-center modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Références alternatives</p>
          <label
            v-if="!showFileContent"
            id="refAlternative-file"
            title="Importer Excel"
            class="cursor-pointer bg-emerald-700 hover:bg-emerald-600 text-white shadow-md px-2 py-1 flex justify-between rounded-md"
          >
            <span class="m-auto">Importer Excel</span>
            <span class="material-icons-outlined m-auto">file_upload</span>
            <input
              id="refAlternative-file"
              type="file"
              class="hidden"
              accept=".xlsx"
              @change="readExcel()"
            />
          </label>
          <div v-show="importLoading">
            <div class="animate-pulse">Importation en cours...</div>
          </div>
          <div v-show="showFinished">
            <div>Importation réussie !</div>
            <div class="flex justify-end">
              <button
                class="p-2 bg-blue-500 text-white rounded"
                @click="closeImport()"
              >
                Fermer
              </button>
            </div>
          </div>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="(openRefCross = false), closeImport()"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <div
          class="py-2 my-2 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2 max-h-40"
          v-show="showFileContent"
        >
          <div
            class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Code_EAN / Réf
                  </th>
                  <th
                    class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Marque / Préfixe
                  </th>
                </tr>
              </thead>

              <tbody class="bg-white">
                <!-- <tr v-for="(mp, index) in matricePrix" :key="index"> -->
                <tr v-for="(readCondi, index) in readFile" :key="index">
                  <td
                    class="px-6 py-3 font-medium leading-4 tracking-wider text-gray-500 border-b border-gray-200"
                  >
                    <div class="flex flex-col">
                      <span>{{ readCondi[0] }}</span>
                      <span class="font-medium">{{ readCondi[1] }}</span>
                    </div>
                  </td>
                  <td
                    class="px-6 py-3 font-medium leading-4 tracking-wider text-gray-500 border-b border-gray-200"
                  >
                    <div class="flex flex-col">
                      <span>{{ readCondi[2] }}</span>
                      <span class="font-medium">{{ readCondi[3] }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-show="!showFileContent">
          <div class="relative">
            <input
              placeholder="Chercher code article"
              v-model="articleSearch"
              v-on:keyup.enter="getOneArticle()"
              class="w-full rounded-md focus:outline-2 outline-sky-300 p-2 text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400"
            />
            <ul
              v-if="openSearch == true"
              class="absolute w-full mx-auto py-2 px-1 bg-gray-100 border border-b rounded-md shadow-lg"
            >
              <span
                @click="openSearch = false"
                class="flex justify-end mt-2 material-icons-outlined text-red-600 cursor-pointer"
              >
                clear
              </span>
              <li
                class="flex justify-between py-1 uppercase border-b border-gray-200 bg-white rounded-t-md"
              >
                <a class="mx-2 px-8"> EAN / Ref </a>
                <a class="mx-2 px-8">Marque / Préfixe</a>
                <a class="mx-2 px-8">Description</a>
              </li>
              <li
                v-for="(a, index) in Articles"
                :key="index"
                @click="addRefCroise(a)"
                class="flex justify-between py-2 border-b border-gray-200 hover:bg-blue-50 cursor-pointer bg-white rounded-b-md"
              >
                <a class="mx-2 flex flex-col mx-auto">
                  <span>{{ a.Code_EAN }}</span>
                  <span class="font-medium">{{ a.Ref_fournisseur }}</span>
                </a>
                <a class="mx-2 flex flex-col mx-auto">
                  <span>{{ a.Code_marque }}</span>
                  <span>{{ a.Prefixe_tarif }}</span>
                </a>
                <a class="mx-2 mx-auto">{{ a.Description }}</a>
              </li>
            </ul>
          </div>
          <div
            class="py-2 my-6 mt-3 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
            style="height: 30vh"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Code_EAN / Réf
                    </th>
                    <th
                      class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Marque / Préfixe
                    </th>
                    <th
                      class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Description
                    </th>
                    <th
                      class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(r, rIndex) in articleRefCroise"
                    :key="rIndex"
                    style="cursor: pointer"
                    class="hover:bg-blue-50"
                  >
                    <td
                      class="px-6 py-3 font-medium leading-4 tracking-wider text-gray-500 border-b border-gray-200"
                    >
                      <div class="flex flex-col">
                        <span>{{ r.Code_EAN }}</span>
                        <span class="font-medium">{{ r.Ref_fournisseur }}</span>
                      </div>
                    </td>
                    <td
                      class="px-6 py-3 font-medium leading-4 tracking-wider text-gray-500 border-b border-gray-200"
                    >
                      <div class="flex flex-col">
                        <span>{{ r.Code_marque }}</span>
                        <span class="font-medium">{{ r.Prefixe_tarif }}</span>
                      </div>
                    </td>
                    <td
                      class="px-6 py-3 font-medium leading-4 tracking-wider text-gray-500 border-b border-gray-200"
                    >
                      {{ r.Description }}
                    </td>
                    <td
                      class="px-6 py-4 text-sm leading-5 text-gray-500 border-b text-center border-gray-200 whitespace-nowrap"
                    >
                      <button
                        class="flex w-fit px-2 py-1 h-fit text-red-600 rounded-md hover:bg-gray-100 focus:outline-none items-center"
                        @click="removeCross(rIndex)"
                      >
                        <span class="material-icons-outlined m-auto"
                          >delete</span
                        >
                        <!-- <span class="m-auto">Supprimer</span> -->
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end mx-4" v-show="!showFileContent">
        <button
          @click="saveArticleCross()"
          v-if="showModifyCross == false && articleRefCroise.length > 0"
          class="rounded-md bg-blue-600 tracking-wide px-6 py-2 font-medium hover:bg-blue-500 focus:outline-none text-white"
        >
          Valider
        </button>
        <button
          @click="modifyCross(), (openRefCross = false)"
          v-if="showModifyCross == true && articleRefCroise.length > 0"
          class="rounded-md bg-blue-600 tracking-wide px-6 py-2 font-medium hover:bg-blue-500 focus:outline-none text-white"
        >
          Modifier
        </button>
      </div>
      <div class="flex justify-end mx-4" v-show="showFileContent">
        <button
          @click="importerFile('cross')"
          class="rounded-md bg-blue-600 tracking-wide px-6 py-2 font-medium hover:bg-blue-500 focus:outline-none text-white"
        >
          Importer
        </button>
      </div>
    </div>
  </div>

  <!-- modal pour ref remplacement -->
  <div
    v-if="openRefReplace"
    :class="`modal ${
      !openRefReplace && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openRefReplace = false), closeImport()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container w-fit h-fit"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-center modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Références de remplacement</p>
          <label
            v-if="!showFileContent"
            id="refAlternative-file"
            title="Importer Excel"
            class="cursor-pointer bg-emerald-700 hover:bg-emerald-600 text-white shadow-md px-2 py-1 flex justify-between rounded-md"
          >
            <span class="m-auto">Importer Excel</span>
            <span class="material-icons-outlined m-auto">file_upload</span>
            <input
              id="refAlternative-file"
              type="file"
              class="hidden"
              accept=".xlsx"
              @change="readExcel()"
            />
          </label>
          <div v-show="importLoading">
            <div class="animate-pulse">Importation en cours...</div>
          </div>
          <div v-show="showFinished">
            <div>Importation réussie !</div>
            <div class="flex justify-end">
              <button
                class="p-2 bg-blue-500 text-white rounded"
                @click="closeImport()"
              >
                Fermer
              </button>
            </div>
          </div>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="(openRefReplace = false), closeImport()"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div
          class="py-2 my-2 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2 max-h-40"
          v-show="showFileContent"
        >
          <div
            class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Code_EAN / Réf
                  </th>
                  <th
                    class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Marque / Préfixe
                  </th>
                </tr>
              </thead>

              <tbody class="bg-white">
                <!-- <tr v-for="(mp, index) in matricePrix" :key="index"> -->
                <tr v-for="(readCondi, index) in readFile" :key="index">
                  <td
                    class="px-6 py-3 font-medium leading-4 tracking-wider text-gray-500 border-b border-gray-200"
                  >
                    <div class="flex flex-col">
                      <span>{{ readCondi[0] }}</span>
                      <span class="font-medium">{{ readCondi[1] }}</span>
                    </div>
                  </td>
                  <td
                    class="px-6 py-3 font-medium leading-4 tracking-wider text-gray-500 border-b border-gray-200"
                  >
                    <div class="flex flex-col">
                      <span>{{ readCondi[2] }}</span>
                      <span class="font-medium">{{ readCondi[3] }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-show="!showFileContent">
          <div class="relative">
            <input
              placeholder="Chercher code article"
              v-model="articleSearch"
              v-on:keyup.enter="getOneArticle()"
              class="w-full rounded-md focus:outline-2 outline-sky-300 p-2 text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400"
            />
            <ul
              v-if="openSearch == true"
              class="absolute w-full mx-auto py-2 px-1 bg-gray-100 border border-b rounded-md shadow-lg"
            >
              <span
                @click="openSearch = false"
                class="flex justify-end mt-2 material-icons-outlined text-red-600 cursor-pointer"
              >
                clear
              </span>
              <li
                class="flex justify-between py-1 uppercase border-b border-gray-200 bg-white rounded-t-md"
              >
                <a class="mx-2 px-8"> EAN / Ref </a>
                <a class="mx-2 px-8">Marque / Préfixe</a>
                <a class="mx-2 px-8">Description</a>
              </li>
              <li
                v-for="(a, index) in Articles"
                :key="index"
                @click="addRefReplace(a)"
                class="flex justify-between py-2 border-b border-gray-200 hover:bg-blue-50 cursor-pointer bg-white rounded-b-md"
              >
                <a class="mx-2 flex flex-col mx-auto">
                  <span>{{ a.Code_EAN }}</span>
                  <span class="font-medium">{{ a.Ref_fournisseur }}</span>
                </a>
                <a class="mx-2 flex flex-col mx-auto">
                  <span>{{ a.Code_marque }}</span>
                  <span>{{ a.Prefixe_tarif }}</span>
                </a>
                <a class="mx-2 mx-auto">{{ a.Description }}</a>
              </li>
            </ul>
          </div>
          <div
            class="py-2 my-6 mt-3 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
            style="height: 30vh"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Code_EAN / Réf
                    </th>
                    <th
                      class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Marque / Préfixe
                    </th>
                    <th
                      class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Description
                    </th>
                    <th
                      class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(r, rIndex) in articleRefReplace"
                    :key="rIndex"
                    style="cursor: pointer"
                    class="hover:bg-blue-50"
                  >
                    <td
                      class="px-6 py-3 font-medium leading-4 tracking-wider text-gray-500 border-b border-gray-200"
                    >
                      <div class="flex flex-col">
                        <span>{{ r.Code_EAN }}</span>
                        <span class="font-medium">{{ r.Ref_fournisseur }}</span>
                      </div>
                    </td>
                    <td
                      class="px-6 py-3 font-medium leading-4 tracking-wider text-gray-500 border-b border-gray-200"
                    >
                      <div class="flex flex-col">
                        <span>{{ r.Code_marque }}</span>
                        <span class="font-medium">{{ r.Prefixe_tarif }}</span>
                      </div>
                    </td>
                    <td
                      class="px-6 py-3 font-medium leading-4 tracking-wider text-gray-500 border-b border-gray-200"
                    >
                      {{ r.Description }}
                    </td>
                    <td
                      class="px-6 py-4 text-sm leading-5 text-gray-500 border-b text-center border-gray-200 whitespace-nowrap"
                    >
                      <button
                        class="flex w-fit px-2 py-1 h-fit text-red-600 rounded-md hover:bg-gray-100 focus:outline-none items-center"
                        @click="removeReplace(rIndex)"
                      >
                        <span class="material-icons-outlined m-auto"
                          >delete</span
                        >
                        <!-- <span class="m-auto">Supprimer</span> -->
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end mx-4" v-show="!showFileContent">
        <button
          @click="saveArticleCross()"
          v-if="showModifyCross == false && articleRefReplace.length > 0"
          class="rounded-md bg-blue-600 tracking-wide px-6 py-2 font-medium hover:bg-blue-500 focus:outline-none text-white"
        >
          Valider
        </button>
        <button
          @click="modifyCross(), (openRefReplace = false)"
          v-if="showModifyCross == true && articleRefReplace.length > 0"
          class="rounded-md bg-blue-600 tracking-wide px-6 py-2 font-medium hover:bg-blue-500 focus:outline-none text-white"
        >
          Modifier
        </button>
      </div>
      <div class="flex justify-end mx-4" v-show="showFileContent">
        <button
          @click="importerFile('replace')"
          class="rounded-md bg-blue-600 tracking-wide px-6 py-2 font-medium hover:bg-blue-500 focus:outline-none text-white"
        >
          Importer
        </button>
      </div>
    </div>
  </div>

  <!-- modal pour update l'article -->
  <div
    v-if="openArticle"
    :class="`modal ${
      !openArticle && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openArticle = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container w-1/2"
    >
      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="text-center rounded-2xl bg-white mx-auto p-8">
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-prefix-tarif"
            >
              Préfixe_Tarif
            </label>
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-prefix-tarif"
              type="text"
              v-model="article.Prefixe_tarif"
              placeholder="ARN,..."
            />
          </div>
          <div class="w-full md:w-1/3 px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-ref-fournisseur"
            >
              Réf.Fournisseur
            </label>
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-ref-fournisseur"
              type="text"
              v-model="article.Ref_fournisseur"
              placeholder="P85101,..."
            />
          </div>
          <div class="w-full md:w-1/3 px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-ref-fournisseur"
            >
              Code EAN
            </label>
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-ref-fournisseur"
              type="text"
              v-model="article.Code_EAN"
              placeholder="0123456789,..."
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-prefix-tarif"
            >
              Réf courte
            </label>
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-prefix-tarif"
              type="text"
              v-model="article.Ref_courte"
              placeholder="ARN,..."
            />
          </div>
          <div class="w-full md:w-1/3 px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-ref-fournisseur"
            >
              Réf complementaire
            </label>
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-ref-fournisseur"
              type="text"
              v-model="article.Ref_complementaire"
              placeholder="P85101,..."
            />
          </div>
          <div class="w-full md:w-1/3 px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-ref-fournisseur"
            >
              Code remise
            </label>
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-ref-fournisseur"
              type="text"
              v-model="article.Code_remise"
              placeholder="P85101,..."
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-description"
            >
              Description
            </label>
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-description"
              type="text"
              v-model="article.Description"
              placeholder="REPLACEMENT SK-2814 BY SK-3062,..."
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-prix-ht"
            >
              Prix HT
            </label>
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-prix-ht"
              type="number"
              placeholder="45.10€"
            />
          </div>
          <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-ppc-ht"
            >
              P.P.C HT
            </label>
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-ppc-ht"
              type="number"
              v-model="article.Prix_euro"
              placeholder="60.50€"
            />
          </div>
          <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-quantite-stock"
            >
              Quantité Stock
            </label>
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-quantite-stock"
              type="number"
              v-model="article.Qte_stock"
              placeholder="15"
            />
          </div>
          <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-quantite-min"
            >
              Quantité min
            </label>
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-quantite-min"
              type="number"
              v-model="article.Qte_mini"
              placeholder="1"
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-2">
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-pnf"
            >
              PNF
            </label>
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-pnf"
              type="number"
              v-model="article.PNF"
              placeholder="38€"
            />
          </div>
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-pnt1"
            >
              PNT1
            </label>
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-pnt1"
              type="number"
              v-model="article.PNT1"
              placeholder="39.90€"
            />
          </div>
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-pnt2"
            >
              PNT2
            </label>
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-pnt2"
              type="number"
              v-model="article.PNT2"
              placeholder="40.20€"
            />
          </div>
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-pnt3"
            >
              PNT3
            </label>
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-pnt3"
              type="number"
              v-model="article.PNT3"
              placeholder="42€"
            />
          </div>
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-pnt4"
            >
              PNT4
            </label>
            <input
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-pnt4"
              type="number"
              v-model="article.PNT4"
              placeholder="44.20€"
            />
          </div>
        </div>
        <button
          @click="saveArticle(article._id)"
          class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 border border-green-700 rounded float-right mb-8 mt-12"
        >
          Modifier
        </button>
      </div>
    </div>
  </div>

  <div class="px-6 py-4 shadow rounded-xl bg-gray-50 mx-auto h-83">
    <div class="h-1/5 flex flex-row p-2">
      <div class="h-full w-1/2 flex flex-col space-y-1 p-2">
        <div class="flex flex-row space-x-4 items-center">
          <div class="text-2xl text-bluevk font-bold italic">
            Marque : <span class="not-italic">{{ article.Code_marque }}</span>
          </div>
          <div class="text-2xl text-bluevk font-bold italic">
            Ref. : {{ article.Prefixe_tarif }} - {{ article.Ref_fournisseur }}
          </div>
        </div>
        <div class="flex flex-row space-x-4 items-center">
          <div
            class="text-xl text-bluevk font-bold italic mr-4"
            v-if="article.Ref_complementaire"
          >
            Ref.complementaire :
            <span class="not-italic">{{ article.Ref_complementaire }}</span>
          </div>
          <div
            class="text-xl text-bluevk font-bold italic"
            v-if="article.Code_remise"
          >
            Code remise :
            <span class="text-orange-vk">{{ article.Code_remise }}</span>
          </div>
        </div>
        <div class="flex flex-row space-x-4 items-center">
          <div class="text-orange-vk font-semibold text-xl">
            {{ article.Description }}
          </div>
        </div>
        <div class="flex flex-row space-x-4 items-center">
          <div v-if="article.plateform == 'all_plateform'">
            <span class="p-1 bg-orange-500 text-white rounded shadow"
              >golda</span
            >
          </div>
          <div v-else>
            <span class="p-1 bg-blue-500 text-white rounded shadow">
              {{ article.plateform }}</span
            >
          </div>
        </div>
      </div>
      <div class="h-full w-1/2 flex justify-end p-2">
        <div class="flex flex-row space-x-4 items-center">
          <button
            @click="infoRefCroise()"
            class="bg-orange-500 hover:bg-orange-700 text-white font-bold p-1 border border-orange-700 rounded"
          >
            Références alternatives
          </button>
          <button
            @click="infoRefReplacement()"
            class="bg-orange-500 hover:bg-orange-700 text-white font-bold p-1 border border-orange-700 rounded"
          >
            Références de remplacement
          </button>
          <button
            v-if="article.plateform == user.proprietaire"
            class="bg-green-500 hover:bg-green-700 text-white font-bold p-1 border border-green-700 rounded"
            @click="updateArticle()"
          >
            Modifier
          </button>

          <button
            v-else-if="article.plateform == 'all_plateform'"
            class="bg-orange-500 hover:bg-orange-700 text-white font-bold p-1 border border-orange-700 rounded"
            @click="newArticle()"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
    <div class="h-4/5 flex flex-row">
      <div class="h-full w-3/5 flex flex-col">
        <div class="h-3/4 w-full flex flex-row p-2">
          <div class="h-full w-1/2 p-2">
            <div class="h-full w-full" v-if="article.img_url.length > 0">
              <div class="h-3/4 bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="article.img_url[0]" class="h-full mx-auto" />
                  <span
                    class="material-icons-round absolute top-0 right-0 text-red-500 cursor-pointer"
                    @click="deleteImg(0)"
                  >
                    delete_forever
                  </span>
                </div>
              </div>
              <div
                class="h-1/4 w-full p-2 flex flex-row items-center space-x-2 overflow-x-auto"
              >
                <div v-for="(img, indexImg) in article.img_url" :key="indexImg">
                  <div v-if="indexImg > 0">
                    <div class="relative">
                      <img
                        :src="article.img_url[indexImg]"
                        class="w-16 h-16 mx-auto"
                      />
                      <span
                        class="material-icons-round absolute top-0 right-0 text-red-500 cursor-pointer"
                        @click="deleteImg(indexImg)"
                      >
                        clear
                      </span>
                    </div>
                  </div>
                </div>
                <div class="flex items-center justify-center h-16 w-16">
                  <label
                    for="dropzone-file"
                    class="flex flex-col items-center justify-center w-full h-16 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div class="flex flex-col items-center justify-center">
                      <svg
                        class="w-8 h-8 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                    </div>
                    <input
                      id="dropzone-file"
                      @change="readImg($event, article.img_url.length)"
                      type="file"
                      class="hidden"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div
              v-else-if="article.plateform == user.proprietaire"
              class="flex h-full w-full"
            >
              <div class="flex items-center justify-center w-full">
                <label
                  for="dropzone-file"
                  class="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div
                    class="flex flex-col items-center justify-center pt-5 pb-6"
                  >
                    <svg
                      class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span class="font-semibold">Cliquer pour importer</span>
                      ou glisser et déposer
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">
                      SVG, PNG, JPG ou GIF (MAX. 800x400px)
                    </p>
                  </div>
                  <input
                    id="dropzone-file"
                    @change="readImg($event, 0)"
                    type="file"
                    class="hidden"
                  />
                </label>
              </div>
            </div>
          </div>
          <div class="h-full w-1/2 grid grid-cols-2 gap-2 p-2">
            <div class="h-full bg-white rounded-xl shadow p-2 flex">
              <div class="h-fit w-fit mx-auto my-auto flex flex-col space-y-2">
                <div class="w-fit mx-auto text-xl text-bluevk">
                  Prix d'achat
                </div>
                <div class="w-fit mx-auto text-5xl text-bluevk">
                  <span v-if="prixachat != 'N/A'">{{ prixachat }} €</span>
                  <span v-else class="material-icons-round text-5xl">
                    warning_amber
                  </span>
                </div>
              </div>
            </div>
            <div class="h-full bg-white rounded-xl shadow p-2 flex">
              <div class="h-fit w-fit mx-auto my-auto flex flex-col space-y-2">
                <div class="w-fit mx-auto text-xl text-bluevk">
                  Prix public conseillé
                </div>
                <div class="w-fit mx-auto text-5xl text-bluevk">
                  {{ article.Prix_euro }} €
                </div>
              </div>
            </div>
            <div class="h-full bg-white rounded-xl shadow p-2 flex">
              <div class="h-fit w-fit mx-auto my-auto flex flex-col space-y-2">
                <div class="w-fit mx-auto text-xl text-bluevk">Adresse</div>
                <div class="w-fit mx-auto text-5xl text-bluevk">
                  <span v-if="stockTab && stockTab.address">{{
                    stockTab.address
                  }}</span
                  ><span v-else>Non stocké</span>
                </div>
              </div>
            </div>
            <div class="h-full bg-white rounded-xl shadow p-2 flex">
              <div class="h-fit w-fit mx-auto my-auto flex flex-col space-y-2">
                <div class="w-fit mx-auto text-xl text-bluevk">Code barre</div>
                <div class="w-fit mx-auto text-xl text-bluevk">
                  {{ article.Code_EAN }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="h-1/4 w-full grid grid-cols-4 gap-4 p-4">
          <div class="h-full bg-white rounded-xl shadow p-2 flex">
            <div class="h-fit w-fit mx-auto my-auto flex flex-col space-y-2">
              <div class="w-fit mx-auto text-xl text-bluevk">
                Quantité minimum
              </div>
              <div class="w-fit mx-auto text-5xl text-orangevk">
                {{ article.Qte_mini }}
              </div>
            </div>
          </div>
          <div
            class="h-full bg-white rounded-xl shadow p-2 flex hover:bg-blue-100 cursor-pointer"
            @click="$router.push('/entreesortiestock')"
          >
            <div class="h-fit w-fit mx-auto my-auto flex flex-col space-y-2">
              <div class="w-fit mx-auto text-xl text-bluevk">
                Quantité en stock
              </div>
              <div class="w-fit mx-auto text-5xl text-orangevk">
                <span v-if="stockTab && stockTab.stock">{{
                  stockTab.stock
                }}</span
                ><span v-else>0</span>
              </div>
            </div>
          </div>
          <div
            class="h-full bg-white rounded-xl shadow p-2 flex hover:bg-blue-100 cursor-pointer"
            @click="$router.push('/StockArticle')"
          >
            <div class="h-fit w-fit mx-auto my-auto flex flex-col space-y-2">
              <div class="w-fit mx-auto text-xl text-bluevk">
                Mouvement stock
              </div>
              <div class="w-fit mx-auto text-5xl text-orangevk">
                <span v-if="mvtTab">{{ mvtTab }}</span
                ><span v-else>0</span>
              </div>
            </div>
          </div>
          <div
            class="h-full bg-white rounded-xl shadow p-2 flex"
            @click="$router.push('/StockArticle')"
          >
            <div class="h-fit w-fit mx-auto my-auto flex flex-col space-y-2">
              <div class="w-fit mx-auto text-xl text-bluevk">Mouvement +/-</div>
              <div class="w-fit mx-auto">
                <span class="material-icons-outlined text-5xl text-green-400">
                  trending_up
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h-full w-2/5 flex flex-col p-2">
        <div class="h-1/3 w-full p-2">
          <div class="flex flex-col space-y-1 p-2 bg-white rounded shadow">
            <div class="flex flex-row items-center justify-between">
              <span class="text-lg text-vk font-bold">Dimension</span>
              <span class="material-icons-round text-orange-vk">
                straighten
              </span>
            </div>
            <div class="flex flex-col">
              <div class="flex flex-row items-center justify-between">
                <span class="text-gray-500">Poids :</span>
                <span class="text-gray-800 font-semibold"
                  >{{ article.Poids }} g</span
                >
              </div>

              <div class="flex flex-row items-center justify-between">
                <span class="text-gray-500">Hauteur :</span>
                <span class="text-gray-800 font-semibold"
                  >{{ article.Hauteur }} cm</span
                >
              </div>

              <div class="flex flex-row items-center justify-between">
                <span class="text-gray-500">Longueur :</span>
                <span class="text-gray-800 font-semibold"
                  >{{ article.Longueur }} cm</span
                >
              </div>

              <div class="flex flex-row items-center justify-between">
                <span class="text-gray-500">Largeur :</span>
                <span class="text-gray-800 font-semibold"
                  >{{ article.Largeur }} cm</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="h-1/3 w-full grid grid-cols-4 gap-4 p-4">
          <div
            class="bg-white rounded-xl shadow p-2 flex flex-col space-y-2 h-fit cursor-pointer hover:bg-blue-100"
            @click="$router.push('/margeArticle')"
          >
            <div class="mx-auto">
              <span class="material-icons-outlined text-5xl text-bluevk">
                price_change
              </span>
            </div>
            <div class="mx-auto text-bluevk">Marge</div>
          </div>
          <div
            class="bg-white rounded-xl shadow p-2 flex flex-col space-y-2 h-fit cursor-pointer hover:bg-blue-100"
            @click="$router.push('/CrossBrand')"
          >
            <div class="mx-auto">
              <span class="material-icons-outlined text-5xl text-bluevk">
                travel_explore
              </span>
            </div>
            <div class="mx-auto text-bluevk">Cross</div>
          </div>
          <div
            class="bg-white rounded-xl shadow p-2 flex flex-col space-y-2 h-fit cursor-pointer hover:bg-blue-100"
            @click="$router.push('/PrixAchat')"
            v-if="prixachat != 'N/A'"
          >
            <div class="mx-auto">
              <span class="material-icons-outlined text-5xl text-bluevk">
                price_check
              </span>
            </div>
            <div class="mx-auto text-bluevk">Prix d'achat</div>
          </div>
        </div>
        <div class="h-1/3 w-full p-2 overflow-y-auto">
          <table class="h-full w-full text-sm text-gray-500">
            <thead class="text-xs text-white uppercase bg-bluevk">
              <tr>
                <th>Numéros OE</th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white border-b overflow-y-auto">
                <th
                  scope="row"
                  class="font-medium text-gray-700 whitespace-nowrap"
                >
                  <oecross-article
                    :Ref_fournisseur="article.Ref_fournisseur"
                    :Code_marque="article.Code_marque"
                  />
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { useToast } from "vue-toastification";
import readXlsxFile from "read-excel-file";
import * as XLSX from "xlsx";
import oecrossArticle from "@/components/articleInfo/oecrossArticle";
import { getPrixAchatPond } from "@/hooks/articles/articles.ts";
import { findStock } from "@/hooks/stock/stock.ts";

export default {
  name: "SetArticles",
  components: {
    oecrossArticle,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      fournTab: [],
      openArticle: false,
      mvtTab: 0,
      mvtStock: [],
      openRefCross: false,
      articleSearch: "",
      errorrecherchearticle: false,
      Articles: [],
      openSearch: false,
      articleRefCroise: [],
      showModifyCross: false,
      articleCross: {},
      // Remplacement
      openRefReplace: false,
      articleRefReplace: [],
      showModifyReplace: false,
      // Excel
      file: null,
      readFile: null,
      importLoading: false,
      showFinished: false,
      showFileContent: false,
      prixachat: [],
    };
  },
  computed: {
    ...mapGetters(["article", "user"]),
  },
  methods: {
    ...mapActions(["setArticle"]),
    getArticleFournisseur() {
      axios.get(`${process.env.VUE_APP_API}/famillearticle`).then((res) => {
        this.fournTab = res.data;
      });
    },
    updateArticle() {
      this.openArticle = true;
    },
    newArticle() {
      var dataArt = {
        type: this.article.type,
        Code_EAN: this.article.Code_EAN,
        fournisseur: this.article.fournisseur,
        plateform: this.user.proprietaire,
        //pneu
        Ref_pneu: this.article.Ref_pneu,
        Ref_externe_pneu: this.article.Ref_externe_pneu,
        marque_pneu: this.article.marque_pneu,
        largeur_pneu: this.article.largeur_pneu,
        serie_pneu: this.article.serie_pneu,
        jante_pneu: this.article.jante_pneu,
        charge_pneu: this.article.charge_pneu,
        iv_pneu: this.article.iv_pneu,
        volume_pneu: this.article.volume_pneu,
        poids_pneu: this.article.poids_pneu,
        quality_pneu: this.article.quality_pneu,
        url_eprel_pneu: this.article.url_eprel_pneu,
        saison_pneu: this.article.saison_pneu,
        renforce_pneu: this.article.renforce_pneu,
        categorie_pneu: this.article.categorie_pneu,
        resistence_roulement_pneu: this.article.resistence_roulement_pneu,
        freinage_mouille_pneu: this.article.freinage_mouille_pneu,
        dB_pneu: this.article.dB_pneu,
        ondes_pneu: this.article.ondes_pneu,
        runflat_pneu: this.article.runflat_pneu,
        type_pneu: this.article.type_pneu,
        url_img_pneu: this.article.url_img_pneu,
        famille_pneu: this.article.famille_pneu,
        sous_famille_pneu: this.article.sous_famille_pneu,
        sous_sous_famille_pneu: this.article.sous_sous_famille_pneu,
        //pneu
        PNF: this.article.PNF,
        PNT1: this.article.PNT1,
        PNT2: this.article.PNT2,
        PNT3: this.article.PNT3,
        PNT4: this.article.PNT4,
        PNT5: this.article.PNT5,
        PNT6: this.article.PNT6,
        Prix_vente: this.article.Prix_vente,
        PrixLotVH: this.article.PrixLotVH,
        Promo_set: this.article.Promo_set,
        FournisseurSelected: this.article.FournisseurSelected,
        commentaire: this.article.commentaire,
        prixtotal: this.article.prixtotal,
        prixfinal: this.article.prixfinal,
        Ref_complementaire: this.article.Ref_complementaire,
        stockData: this.article.stockData,
        Qty_cmd: this.article.Qty_cmd,
        Qty_rcpt: this.article.Qty_rcpt,
        Ref_fournisseur: this.article.Ref_fournisseur,
        Ref_fournisseur_courte: this.article.Ref_fournisseur_courte,
        Ref_fournisseur_trim: this.article.Ref_fournisseur_trim,
        Ref_courte: this.article.Ref_courte,
        Ref_remplacement: this.article.Ref_remplacement,
        Description: this.article.Description,
        Qte_mini: this.article.Qte_mini,
        Qte_maxi: this.article.Qte_maxi,
        Udm_qte_mini: this.article.Udm_qte_mini,
        Qte_cond: this.article.Qte_cond,
        Udm_qte_cond: this.article.Udm_qte_cond,
        Prix_euro: this.article.Prix_euro,
        Prix_ppc: this.article.Prix_ppc,
        Base_prix_euro: this.article.Base_prix_euro,
        Type_prix: this.article.Type_prix,
        Poids: this.article.Poids,
        Udm_poids: this.article.Udm_poids,
        Code_remise: this.article.Code_remise,
        Code_sousfamille_NU: this.article.Code_sousfamille_NU,
        Montant_consigne: this.article.Montant_consigne,
        Code_metier: this.article.Code_metier,
        Qte_multiple: this.article.Qte_multiple,
        Code_marque: this.article.Code_marque,
        Hauteur: this.article.Hauteur,
        Longueur: this.article.Longueur,
        Largeur: this.article.Largeur,
        Udm_dimensions: this.article.Udm_dimensions,
        Date_application: this.article.Date_application,
        Code_famille_NU: this.article.Code_famille_NU,
        Prefixe_tarif: this.article.Prefixe_tarif,
        Archived: this.article.Archived,
        Actif: this.article.Actif,
        StockOwner: this.article.StockOwner,
        Stock: this.article.Stock,
        StockFromSupplier: this.article.StockFromSupplier,
        StockToCustomer: this.article.StockToCustomer,
        StockReserved: this.article.StockReserved,
        // StockInfo: {
        //   Depot: {
        //     ID: this.article.StockInfo.Depot.ID,
        //     Prefix: this.article.StockInfo.Depot.Prefix,
        //     IsVirtual: this.article.StockInfo.Depot.IsVirtual,
        //   },
        //   Product: {
        //     Stock: this.article.StockInfo.Product.Stock,
        //     StockFromSupplier: this.article.StockInfo.Product.StockFromSupplier,
        //     StockToCustomer: this.article.StockInfo.Product.StockToCustomer,
        //     StockReserved: this.article.StockInfo.Product.StockReserved,
        //   },
        // },
        etatRetour: this.article.etatRetour,
        confState: this.article.confState,
      };
      axios
        .post(`${process.env.VUE_APP_API}/article`, dataArt)
        .then(() => {
          this.toast.success("Article ajouté !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.setArticle(dataArt);
          this.showArticleIn = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getOneArticle() {
      this.openSearch = true;
      if (this.articleSearch != "") {
        this.errorrecherchearticle = false;
        axios
          .post(`${process.env.VUE_APP_API}/article/getone`, {
            Ref_fournisseur: this.articleSearch,
          })
          .then((response) => {
            this.Articles = response.data;

            if (response.data == 0) {
              this.errorrecherchearticle = true;
            }
          });
      } else {
        this.errorrecherchearticle = true;
      }
    },
    saveArticle(articleid) {
      var roundedPrix_euro = parseFloat(this.article.Prix_euro).toFixed(2);
      this.article.Prix_euro = parseFloat(roundedPrix_euro);
      var roundedPNF = parseFloat(this.article.PNF).toFixed(2);
      this.article.PNF = parseFloat(roundedPNF);
      var roundedPNT1 = parseFloat(this.article.PNT1).toFixed(2);
      this.article.PNT1 = parseFloat(roundedPNT1);
      var roundedPNT2 = parseFloat(this.article.PNT2).toFixed(2);
      this.article.PNT2 = parseFloat(roundedPNT2);
      var roundedPNT3 = parseFloat(this.article.PNT3).toFixed(2);
      this.article.PNT3 = parseFloat(roundedPNT3);
      var roundedPNT4 = parseFloat(this.article.PNT4).toFixed(2);
      this.article.PNT4 = parseFloat(roundedPNT4);
      axios
        .put(`${process.env.VUE_APP_API}/article/${articleid}`, this.article)
        .then(() => {
          this.setArticle(this.article);
          this.openArticle = false;
          this.toast.success("Article modifié !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    async getMvtArticle() {
      const res = await axios.post(
        `${process.env.VUE_APP_API}/mvStock/getOneByEAN`,
        {
          Ref_fournisseur: this.article.Ref_fournisseur,
          Code_EAN: this.article.Code_EAN,
          Code_marque: this.article.Code_marque,
          plateform: this.user.proprietaire,
        }
      );
      this.mvtTab = res.data;
    },
    async getStockArticle() {
      const stock = await findStock(
        this.user._id,
        this.user.proprietaire,
        this.article.Ref_fournisseur,
        this.article.Code_marque,
        this.article.Prefixe_tarif
      );
      this.stockTab = stock.data;
    },
    // Cross
    modifyCross() {
      axios
        .put(
          `${process.env.VUE_APP_API}/articlecross/modifyCross/${this.articleCross._id}`,
          {
            data: {
              Cross: this.articleRefCroise,
              Replace: this.articleRefReplace,
            },
          }
        )
        .then(() => {
          this.toast.success("Modifié !", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    addRefCroise(objet) {
      // var existArticle = false;
      // this.articleRefCroise.forEach((element) => {
      //
      //
      //
      //
      //   if (
      //     element.Ref_fournisseur == objet.Ref_fournisseur &&
      //     element.Prefixe_tarif == objet.Prefixe_tarif
      //   ) {
      //     existArticle = true;
      //     //
      //   } else {
      //     existArticle = false;
      //     //
      //   }
      // });
      // if (!existArticle) {
      //   this.articleRefCroise.push(objet);
      // } else {
      //   this.toast.warning("Cette référence existe !", {
      //     position: "bottom-right",
      //     timeout: 2000,
      //     closeOnClick: true,
      //     pauseOnFocusLoss: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     draggablePercent: 1,
      //     showCloseButtonOnHover: false,
      //     hideProgressBar: true,
      //     closeButton: "button",
      //     icon: true,
      //     rtl: false,
      //   });
      // }
      this.articleRefCroise.push(objet);
      this.openSearch = false;
      this.articleSearch = "";
    },
    infoRefCroise() {
      this.openRefCross = true;
      this.getArticleCross();
    },
    saveArticleCross() {
      if (
        this.articleRefCroise.length > 0 ||
        this.articleRefReplace.length > 0
      ) {
        axios
          .post(`${process.env.VUE_APP_API}/articlecross`, {
            plateform: this.user.proprietaire,
            Prefixe_tarif: this.article.Prefixe_tarif,
            Ref_fournisseur: this.article.Ref_fournisseur,
            Code_EAN: this.article.Code_EAN,
            Code_marque: this.article.Code_marque,
            Cross: this.articleRefCroise,
            Replace: this.articleRefReplace,
          })
          .then(() => {
            this.toast.success("Référence croisé ajouté !", {
              position: "bottom-right",
              timeout: 2000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 1,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.openRefCross = false;
            this.openRefReplace = false;
          });
      }
    },
    getArticleCross() {
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/getone`, {
          plateform: this.user.proprietaire,
          Prefixe_tarif: this.article.Prefixe_tarif,
          Ref_fournisseur: this.article.Ref_fournisseur,
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.articleCross = response.data[0];
            this.articleRefCroise = response.data[0].Cross;
            this.articleRefReplace = response.data[0].Replace;
            this.showModifyCross = true;
            this.showModifyReplace = true;
          } else {
            this.showModifyCross = false;
            this.showModifyReplace = false;
          }
        });
    },
    removeCross(index) {
      this.articleRefCroise.splice(index, 1);
      this.modifyCross();
    },
    // Remplacement
    infoRefReplacement() {
      this.openRefReplace = true;
      this.getArticleCross();
    },
    addRefReplace(objet) {
      this.articleRefReplace.push(objet);
      this.openSearch = false;
      this.articleSearch = "";
    },
    removeReplace(index) {
      this.articleRefReplace.splice(index, 1);
      this.modifyCross();
    },
    // Excel
    async readExcel() {
      this.file = event.target.files ? event.target.files[0] : null;
      this.readFile = await readXlsxFile(this.file);
      this.readFile.splice(0, 1);
      this.showFileContent = true;
    },
    closeImport() {
      this.showFile = false;
      this.readFile = null;
      this.file = null;
      this.showFinished = false;
      this.showFileContent = false;
      this.importLoading = false;
      this.openRefReplace = false;
      this.showModifyCross = false;
      this.openRefCross = false;
      this.showModifyReplace = false;
    },
    importerFile(data) {
      this.importLoading = true;
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/importExcel`, {
          data: this.readFile,
          plateform: this.user.proprietaire,
          Ref_fournisseur: this.article.Ref_fournisseur,
          Prefixe_tarif: this.article.Prefixe_tarif,
          dataToModify: data,
        })
        .then((response) => {
          this.importLoading = false;
          this.showFinished = true;
        });
    },
    async readImg(e, position) {
      const file = e.target.files[0];
      const imagefile = file;

      var fd = new FormData();

      fd.append("image", imagefile);
      fd.append("_id", this.article._id);
      fd.append("plateform", this.user.proprietaire);
      fd.append("position", position);

      const createImage = await axios.post(
        `${process.env.VUE_APP_API}/article/image`,
        fd
      );
      this.setArticle(createImage.data);
    },
    async deleteImg(position) {
      const deleteImage = await axios.post(
        `${process.env.VUE_APP_API}/article/deleteImage`,
        {
          _id: this.article._id,
          plateform: this.user.proprietaire,
          position: position,
        }
      );
      this.setArticle(deleteImage.data);
    },
  },
  async mounted() {
    await this.getMvtArticle();
    await this.getStockArticle();
    this.getArticleFournisseur();
    this.prixachat = await getPrixAchatPond(
      this.user.proprietaire,
      this.article.Prefixe_tarif,
      this.article.Ref_fournisseur,
      this.article.Code_EAN
    );
  },
};
</script>

<style scoped>
.text-vk {
  color: #2262b3;
}

.text-orange-vk {
  color: #ff914d;
}
.bg-vk {
  background-color: #2262b3;
}
.hover-bg-bluevk:hover {
  background-color: #2262b3;
  color: #fff;
}

.hover-bg-bluevk:hover * {
  color: #fff;
}
.hover-bg-bluevk:hover span:first-child {
  color: #ff914d;
}
.hover-bg-bluevk:hover span:last-child {
  color: #ff914d;
}

.hover-bg-orangevk:hover * {
  background-color: #ff914d;
  color: #fff;
}
</style>
