<template>
  <remisesFournisseurs
    v-if="openRefSimil"
    :Ref_fournisseur="refSimil"
    @closeModalRemFourn="openRefSimil = $event"
  />

  <referencesFournisseurs
    v-if="openRefArticle"
    :Ref_fournisseur="refSimil"
    @closeModalRefFourn="openRefArticle = $event"
  />

  <div class="flex flex-col space-y-2 h-85 w-full">
    <div class="flex justify-between items-center">
      <div id="retour" class="flex justify-start">
        <backButton />
      </div>
      <div class="flex flex-row space-x-2 items-center">
        <span
          class="bg-blue-500 text-white p-2 rounded shadow animate-pulse"
          v-show="loadingPreparation"
          >Préparation en cours...</span
        >
        <button
          class="bg-blue-500 text-white p-2 rounded shadow"
          @click="$router.push('/hubReceptionFournisseur')"
          v-show="!loadingPreparation"
        >
          Récéptionner
        </button>
        <button
          class="bg-blue-500 text-white p-2 rounded shadow"
          @click="prepareCommande()"
          v-show="!loadingPreparation"
        >
          Dispo
        </button>
      </div>
    </div>
    <div class="flex flex-row space-x-2 p-2">
      <div v-for="(f, indexF) in fournisseurList" :key="indexF">
        <div
          class="bg-white rounded shadow p-2 text-sm cursor-pointer"
          @click="showActionF[indexF] = !showActionF[indexF]"
        >
          {{ f.CompanyName }}
        </div>
        <div v-show="showActionF[indexF]" class="absolute z-20 bg-white shadow">
          <ul class="text-sm">
            <li
              class="hover:bg-blue-500 hover:text-white p-2 cursor-pointer"
              @click="prepareCommandeByFournisseur(f.Prefixe_tarif)"
            >
              Dispo {{ f.CompanyName }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="w-full">
      <input
        v-on:keyup.enter="getOneArticle()"
        v-model="articleSearch"
        placeholder="Entrer un code article"
        class="relative focus:outline-2 outline-sky-300 w-full py-2 pl-6 pr-12 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r appearance-none focus:bg-white focus:placeholder-gray-600 focus:text-gray-700"
      />
      <div
        class="absolute w-11/12 z-20 bg-white rounded-b shadow"
        v-show="showArticleList"
      >
        <div
          v-for="(article, indexArticle) in searchTab"
          :key="indexArticle"
          @click="addPanier(article.article)"
        >
          {{ article.article.Ref_fournisseur }} :
          {{ article.article.Prefixe_tarif }}
        </div>
      </div>
    </div>
    <div class="h-4/5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500 text-gray-400">
          <thead
            class="text-xs text-white uppercase bg-blue-500 bg-gray-700 text-gray-400"
          >
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Ref.
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Désignation
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Fournisseur
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Quantité
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-gray-800"
              v-for="(artPanier, indexArtPanier) in panier.panier_inst"
              :key="indexArtPanier"
            >
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="flex space-x-2">
                  <span
                    v-if="artPanier.Prefixe_tarif == 'Vhi'"
                    class="material-icons-outlined my-auto cursor-pointer text-orange-500 hover:bg-gray-100 rounded hover:shadow hover:animate-spin"
                    style="font-size: 11px"
                    @click="getRefSimilaire(artPanier.Ref_fournisseur)"
                  >
                    sync
                  </span>
                  <span>{{ artPanier.Ref_fournisseur }}</span>
                  <span
                    v-if="
                      artPanier.Prefixe_tarif == 'Vhi' && artPanier.PrixLotVH
                    "
                    class="material-icons-outlined my-auto animate-pulse text-orange-500 cursor-pointer"
                    style="font-size: 15px"
                    @click="openModalRefSimil(artPanier.Ref_fournisseur)"
                  >
                    auto_graph
                  </span>
                </div>
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ artPanier.Description }}
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div
                  v-if="artPanier.FournisseurData"
                  class="flex flex-row items-center space-x-2"
                >
                  <div
                    class="p-1 w-fit bg-blue-500 text-white rounded shadow cursor-pointer text-sm"
                    @click="
                      openChangeFournisseur(
                        artPanier.Ref_fournisseur,
                        indexArtPanier
                      )
                    "
                  >
                    {{ artPanier.FournisseurData.CompanyName }}
                  </div>
                  <span
                    v-show="showChangeFournisseur[indexArtPanier]"
                    class="material-icons-round"
                  >
                    west
                  </span>
                  <div
                    v-show="showChangeFournisseur[indexArtPanier]"
                    class="flex flex-row items-center space-x-1"
                  >
                    <span
                      v-for="(c, indexC) in changeFournisseurTab"
                      :key="indexC"
                      @click="
                        changeFournisseur(c.article, artPanier, indexArtPanier)
                      "
                      class="w-fit cursor-pointer"
                      >{{ c.article.Prefixe_tarif }}</span
                    >
                  </div>
                  <span
                    v-show="showChangeFournisseur[indexArtPanier]"
                    class="material-icons-round text-red-500 hover:bg-opacity-25 rounded-sm text-white cursor-pointer"
                    @click="showChangeFournisseur[indexArtPanier] = false"
                  >
                    close
                  </span>
                </div>
                <div v-else class="flex flex-row items-center space-x-2">
                  <div
                    class="p-1 w-fit bg-blue-500 text-white rounded shadow cursor-pointer"
                    @click="
                      openChangeFournisseur(
                        artPanier.Ref_fournisseur,
                        indexArtPanier
                      )
                    "
                  >
                    Inconnu
                  </div>
                  <span
                    v-show="showChangeFournisseur[indexArtPanier]"
                    class="material-icons-round"
                  >
                    west
                  </span>
                  <div
                    v-show="showChangeFournisseur[indexArtPanier]"
                    class="flex flex-row items-center space-x-1"
                  >
                    <span
                      v-for="(c, indexC) in changeFournisseurTab"
                      :key="indexC"
                      @click="
                        changeFournisseur(c.article, artPanier, indexArtPanier)
                      "
                      class="w-fit cursor-pointer"
                      >{{ c.article.Prefixe_tarif }}</span
                    >
                  </div>
                  <span
                    v-show="showChangeFournisseur[indexArtPanier]"
                    class="material-icons-round text-red-500 hover:bg-opacity-25 rounded-sm text-white cursor-pointer"
                    @click="showChangeFournisseur[indexArtPanier] = false"
                  >
                    close
                  </span>
                </div>
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <input
                  type="Number"
                  class="w-14"
                  v-model="artPanier.quantity"
                  @input="changeQuantitiy(artPanier.quantity, indexArtPanier)"
                  @change="saveQuantity()"
                />
                <span
                  class="material-icons-round text-xs bg-red-500 hover:bg-opacity-25 rounded-sm text-white cursor-pointer"
                  @click="removeArticle(artPanier)"
                >
                  clear
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex justify-between items-center">
      <div></div>
      <div class="flex flex-row space-x-2 items-center">
        <button
          class="bg-red-500 text-white p-2 rounded shadow"
          @click="emptyPanier()"
          v-show="!loadingPreparation"
        >
          Vider le panier
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { regularSearchArticles } from "@/hooks/searchEngine/articles";
import {
  getPanier,
  addPanierInst,
  savePanier,
  removePanierInst,
  emptyPanierInst,
  prepareCommande,
} from "@/hooks/commandeFournisseur/commandeFournisseur";
import { remove } from "jszip";
import backButton from "@/components/Button/back.vue";
import remisesFournisseurs from "../components/Comptoir/remisesFournisseurs.vue";
import referencesFournisseurs from "../components/Comptoir/referencesFournisseurs.vue";

export default {
  data() {
    return {
      articleSearch: "",
      searchTab: [],
      panier: [],
      showArticleList: false,
      fournisseurList: [],
      showChangeFournisseur: [],
      changeFournisseurTab: [],
      showActionF: [],
      loadingPreparation: false,

      openRefSimil: false,
      openRefArticle: false,
      refSimil: "",
    };
  },
  computed: {
    ...mapGetters(["user", "comptoirSessionActive", "plateform"]),
  },
  components: {
    backButton,
    remisesFournisseurs,
    referencesFournisseurs,
  },
  methods: {
    ...mapActions(["setPreparationFournisseur"]),
    openModalRefSimil(ref) {
      this.refSimil = ref;
      this.openRefSimil = true;
    },
    getRefSimilaire(ref) {
      this.refSimil = ref;
      this.openRefArticle = true;
    },
    async getOneArticle() {
      this.showArticleList = true;
      if (this.articleSearch != "") {
        this.searchTab = [];
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        this.searchTab = await regularSearchArticles(
          this.articleSearch,
          plateform_mere
        );
        this.loadingSearchArticle = false;

        this.articleSearch = "";
      }
    },
    async addPanier(article) {
      await addPanierInst(this.user.proprietaire, article, 1);
      this.panier = await getPanier(this.user.proprietaire);
      this.showArticleList = false;
      this.getFournisseurList();
    },
    getFournisseurList() {
      var exist = false;
      this.fournisseurList = [];
      this.panier.panier_inst.forEach((element) => {
        exist = false;
        if (element.FournisseurData != null) {
          this.fournisseurList.forEach((fournisseur) => {
            if (
              fournisseur.CompanyName == element.FournisseurData.CompanyName
            ) {
              exist = true;
            }
          });
          if (!exist) {
            this.fournisseurList.push(element.FournisseurData);
          }
        }
      });
    },
    changeQuantitiy(quantite, index) {
      if (quantite == "") {
        this.panier.panier_inst[index].quantity = 0;
      }
    },
    async saveQuantity() {
      await savePanier(this.user.proprietaire, this.panier);
    },
    async removeArticle(article) {
      await removePanierInst(this.user.proprietaire, article);
      this.panier = await getPanier(this.user.proprietaire);
      this.getFournisseurList();
    },
    async emptyPanier() {
      await emptyPanierInst(this.user.proprietaire);
      this.panier = await getPanier(this.user.proprietaire);
      this.getFournisseurList();
    },
    async openChangeFournisseur(ref, index) {
      this.changeFournisseurTab = [];
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      this.changeFournisseurTab = await regularSearchArticles(
        ref,
        plateform_mere
      );
      this.showChangeFournisseur[index] = true;
    },
    async changeFournisseur(article, last_article, index) {
      await removePanierInst(this.user.proprietaire, last_article);
      await addPanierInst(
        this.user.proprietaire,
        article,
        parseInt(last_article.quantity)
      );
      this.showChangeFournisseur[index] = false;
      this.changeFournisseurTab = [];
      this.panier = await getPanier(this.user.proprietaire);
      this.getFournisseurList();
    },
    async prepareCommande() {
      this.loadingPreparation = true;
      this.setPreparationFournisseur({
        plateform: this.user.proprietaire,
        panier: this.panier.panier_inst,
        fournisseur: "all",
      });
      // await prepareCommande(
      //   this.user.proprietaire,
      //   this.panier.panier_inst,
      //   "all"
      // );
      this.$router.push("/prepareCommandeFournisseur");
    },
    async prepareCommandeByFournisseur(fournisseur) {
      this.loadingPreparation = true;
      var panierPrepa = this.panier.panier_inst.filter((article) => {
        return article.Prefixe_tarif == fournisseur;
      });
      this.setPreparationFournisseur({
        plateform: this.user.proprietaire,
        panier: panierPrepa,
        fournisseur: fournisseur,
      });
      // await prepareCommande(this.user.proprietaire, panierPrepa, fournisseur);
      this.$router.push("/prepareCommandeFournisseur");
    },
  },
  async beforeMount() {
    this.panier = await getPanier(this.user.proprietaire);
    this.getFournisseurList();
  },
};
</script>
<style></style>
