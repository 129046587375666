import axios from "axios";

export async function filter(
  plateform: string,
  page: number,
  limit: number,
  sortBy: string,
  sortOrder: string
) {
  const response = await axios.post(
    `${process.env.VUE_APP_API}/appointment/filter`,
    {
      plateform: plateform,
      page: page,
      limit: limit,
      sortBy: sortBy,
      sortOrder: sortOrder,
    }
  );

  return response.data;
}
