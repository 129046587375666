<template>
  <iframe
    width="500"
    height="50"
    style="border: 0"
    loading="lazy"
    allowfullscreen
    referrerpolicy="no-referrer-when-downgrade"
    :src="mapSrc"
  ></iframe>
</template>

<script>
export default {
  props: {
    latitude: {
      type: Number,
      required: true,
    },
    longitude: {
      type: Number,
      required: true,
    },
  },
  computed: {
    mapSrc() {
      return `https://www.google.com/maps/embed/v1/place?key=AIzaSyCbveQyyPOgSsoOD8e4B7P_ybu8yovR6A4&q=${this.latitude},${this.longitude}&center=${this.latitude},${this.longitude}&zoom=15`;
    },
  },
};
</script>

<style scoped></style>
