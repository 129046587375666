<template>
  <div class="bg-white w-full lg:p-4 xl:p-4 md:p-4 sm:p-4 rounded-sm shadow">
    <div class="flex justify-between">
      <backButton />
      <div class="flex justify-center">
        <button @click="createPDF(bdlSelected)" class="bg-orange-600 hover:bg-orange-700 text-white py-2 px-4 rounded">
          Imprimer
        </button>
      </div>
    </div>

    <div class="p-8 w-full">
      <!-- En-tête -->
      <div class="w-full">
        <div class="flex flex-col items-center w-full md:flex-row sm:flex-row lg:pb-10 xl:pb-10 md:pb-10 sm:pb-5">
          <div class="w-1/2 sm:w-1/4">
            <img :src="plateform.lien_logo" alt="Logo de l'entreprise" class="w-1/2 h-auto mx-auto" />
          </div>
        </div>

        <!-- "Bon de livraison" sous l'image en md et sm -->
        <div class="mt-4 md:mt-0 sm:mt-0 text-center">
          <div class="text-gray-700 uppercase font-medium text-xl sm:text-s max-w-3/4 mx-auto">
            Bon de livraison
            <span class="text-bluevk">n° {{ bdlSelected.Numero }}</span>
          </div>
        </div>
      </div>
      <div class="flex justify-end text-gray-700 text-xs font-medium pt-8">
        Le {{ getFRDate(bdlSelected.Date) }}
      </div>

      <!-- Adresses -->
      <div class="sm:flex sm:justify-between sm:pt-4 sm:pb-8">
        <div class="sm:w-2/4 md:mr-3 bg-white rounded-sm shadow-md">
          <div class="bg-bluevk text-white text-xs font-medium py-1 px-2 rounded-t-sm">
            Expéditeur
          </div>
          <div class="md:flex justify-between">
            <!-- Première partie -->
            <div class="flex-1 md:w-1/2 m-3">
              <div class="text-bluevk text-xs uppercase font-bold">
                {{ plateform.Titre2 }}
              </div>
              <div class="text-bluevk text-xs font-m mt-1">
                {{ plateform.Adresse }} <br />
                {{ plateform.Code_postale }}
                <span class="uppercase">{{ plateform.Ville }}</span><br />
                {{ plateform.Pays }}
              </div>
            </div>
            <!-- Filet entre les deux parties -->
            <div class="my-1 border-l border-gray-300"></div>
            <!-- Deuxième partie -->
            <div class="flex-1 m-3 pb-3">
              <div class="text-xs font-bold text-gray-700">
                {{ plateform.Titre1 }}
              </div>
              <div class="text-xs text-gray-700 font-m mt-1">
                {{ plateform.Email }}<br />
                {{ plateform.Telephone }}
              </div>
              <div class="text-xs text-gray-700 mt-1">
                {{ plateform.Siret }} - {{ plateform.Tva }} -
                {{ plateform.Naf }}
              </div>
            </div>
          </div>
        </div>
        <!-- encart destinataire -->
        <div class="sm:w-2/4 md:ml-3 bg-white rounded-sm shadow-md">
          <div class="bg-bluevk text-white text-xs font-medium py-1 px-2 rounded-t-sm">
            Destinataire
          </div>
          <div class="md:flex justify-between" v-if="bdlSelected.clientInfo[0]">
            <!-- Première partie -->
            <div class="flex-1 m-3">
              <div class="text-bluevk text-xs uppercase font-bold">
                {{ bdlSelected.Client }}
              </div>
              <div class="text-bluevk text-xs font-m mt-1">
                {{ bdlSelected.clientInfo[0].Adresse }}<br />
                {{ bdlSelected.clientInfo[0].PostalCode }}
                <span class="uppercase">{{
                  bdlSelected.clientInfo[0].City
                }}</span><br />
                {{ bdlSelected.clientInfo[0].Country }}
              </div>
            </div>
            <!-- Filet entre les deux parties -->
            <div class="my-1 border-l border-gray-300"></div>
            <!-- Deuxième partie -->
            <div class="flex-1 m-3 pb-3">
              <div class="text-xs text-gray-700 font-bold">
                {{ bdlSelected.Name }}
              </div>
              <div class="text-xs text-gray-700 font-m mt-1">
                {{ bdlSelected.clientInfo[0].email }}<br />
                {{ bdlSelected.clientInfo[0].telephone }}
              </div>
              <div class="text-xs text-gray-700 mt-1">
                {{ bdlSelected.clientInfo[0].siret }} -
                {{ bdlSelected.clientInfo[0].numerotva }} -
                {{ bdlSelected.clientInfo[0].nafape }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="md:mt-8">
          <div class="text-xs font-bold text-gray-700">
            Servi par : {{ bdlSelected.Name }}
          </div>
          <div class="text-xs italic font-medium text-bluevk ml-auto">
            Commentaire : {{ bdlSelected.Note }}
          </div>
        </div>
        <!-- tableau responsive -->
        <div class="overflow-x-auto">
          <table class="mt-2 mb-4 w-full text-gray-700 text-xs rounded-sm border-collapse">
            <!-- Table headers -->
            <thead>
              <tr class="bg-bluevk text-white text-left px-3 rounded-t-sm">
                <th class="px-2 py-1 text-xs font-medium">Qté</th>
                <th class="px-2 py-1 text-xs font-medium">Réf.</th>
                <th class="px-2 py-1 text-xs font-medium">Appellation</th>
                <th class="px-2 py-1 text-xs font-medium" v-if="user.accountType == 'Administrateur'">
                  PA €
                </th>
                <th class="px-2 py-1 text-xs font-medium" v-if="user.accountType == 'Administrateur'">
                  Marge sur PV €
                </th>
                <th class="px-2 py-1 text-xs font-medium" v-if="user.accountType == 'Administrateur'">
                  Marge sur PV %
                </th>
                <th class="px-2 py-1 text-xs font-medium">Prix brut HT</th>
                <th class="px-2 py-1 text-xs font-medium">Remise</th>
                <th class="px-2 py-1 text-xs font-medium">Prix net HT</th>
                <th class="px-2 py-1 text-xs font-medium">Montant HT</th>
              </tr>
            </thead>

            <!-- Table body -->
            <tbody class="">
              <!-- Rows -->
              <tr v-for="(article, index) in bdlSelected.pdfData" :key="index">
                <td class="border border-gray-300 p-2">
                  {{ article.quantite }}
                </td>

                <td class="border border-gray-300 p-2">{{ article.ref }}</td>

                <td class="border border-gray-300 p-2">
                  {{ article.description }}
                </td>

                <!-- Prix achat -->
                <td class="border border-gray-300 p-2 font-bold text-orange-600"
                  v-if="user.accountType == 'Administrateur'">
                  {{
                    bdlSelected.Articles[index].Prix_achat ?
                      parseFloat(bdlSelected.Articles[index].Prix_achat || 0).toFixed(2) : '-'
                  }}
                  €
                </td>

                <!-- Marge sur PV € -->
                <td class="border border-gray-300 p-2 font-bold text-green-600"
                  v-if="user.accountType == 'Administrateur'">
                  {{ setMargeArticle(index) }} €
                </td>

                <!-- Marge sur PV % -->
                <td class="border border-gray-300 p-2 font-bold text-green-600"
                  v-if="user.accountType == 'Administrateur'">
                  {{ setMargeArticlePercent(index) }} %
                </td>

                <td class="border border-gray-300 p-2">
                  {{
                    parseFloat(bdlSelected.Articles[index].Prix_euro).toFixed(2)
                  }}
                  €
                </td>

                <td class="border border-gray-300 p-2">
                  <div v-if="
                    bdlSelected.Articles[index].PrixFournisseurType !=
                    'prixnet'
                  ">
                    {{ article.remise }}%
                  </div>
                  <div v-else>prix net</div>
                </td>

                <td class="border border-gray-300 p-2">
                  <div v-if="bdlSelected.Articles[index].PrixFournisseurType != 'prixnet'">
                    {{
                      parseFloat(
                        (bdlSelected.Articles[index].Prix_euro) -
                        ((bdlSelected.Articles[index].Prix_euro) * ((article.remise) / 100))
                      ).toFixed(2)
                    }}
                    €
                  </div>
                  <div v-else>
                    {{
                      parseFloat(bdlSelected.Articles[index].Prix_vente || 0).toFixed(2)
                    }}
                    €
                  </div>
                </td>

                <td class="border border-gray-300 p-2">
                  {{ parseFloat(article.total).toFixed(2) }} €
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="flex justify-end items-center pb-8 text-gray-700">
        <div class="w-1/3 flex justify-end">
          <table class="mt-4 mb-8 w-full rounded border-collapse border border-gray-300">
            <thead></thead>
            <tbody class="text-xs font-medium">
              <!-- Première ligne -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">Transport</td>
                <td class="border border-gray-300 p-2">
                  {{ parseFloat(bdlSelected.Transport).toFixed(2) }} €
                </td>
              </tr>

              <!-- Deuxième ligne -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">Total HT</td>
                <td class="border border-gray-300 p-2">
                  {{ parseFloat(bdlSelected.TotalPrice).toFixed(2) }} €
                </td>
              </tr>

              <!-- Troisième ligne -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">TVA</td>
                <td class="border border-gray-300 p-2">
                  {{ parseFloat(bdlSelected.TotalPrice * 0.2).toFixed(2) }} €
                </td>
              </tr>

              <!-- Quatrième ligne -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">Total TTC</td>
                <td class="border border-gray-300 p-2">
                  {{
                    parseFloat(
                      bdlSelected.TotalPrice + bdlSelected.TotalPrice * 0.2
                    ).toFixed(2)
                  }}
                  €
                </td>
              </tr>

              <!-- Prix achat -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">Total Prix Achat</td>
                <td class="border border-gray-300 p-2">
                  {{
                    setTotalPrixAchat
                  }}
                  €
                </td>
              </tr>

              <!-- Prix vente -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">Total Prix Vente</td>
                <td class="border border-gray-300 p-2">
                  {{
                    setTotalPrixVente
                  }}
                  €
                </td>
              </tr>

              <!-- Cinquième ligne -->
              <tr v-if="user.accountType == 'Administrateur'">
                <td class="border border-gray-300 font-bold p-2">Marge</td>
                <td class="border border-gray-300 font-bold p-2 text-green-600">
                  {{ setMargeTotal }} €, soit {{ setMargeTotalePourcentage }} %
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="bonDeLivraisonImprime" class="mt-4">
      <h2 class="text-xl font-semibold mb-2">Bon de Livraison</h2>
      <p><strong>Numéro de commande :</strong> {{ numeroCommande }}</p>
      <p><strong>Nom du client :</strong> {{ nomClient }}</p>
      <p><strong>Articles :</strong></p>
      <pre>{{ articles }}</pre>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import backButton from "@/components/Button/back.vue";
import { createlivraison } from "@/hooks/pdf/livraison";
import { createGroupedDoc } from "@/hooks/pdf/groupedDoc.js";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";

export default {
  data() {
    return {
      bonDeLivraisonImprime: false,
    };
  },
  computed: {
    ...mapGetters(["user", "plateform", "bdlSelected"]),
    setTotalPrixAchat() {
      var totalPrixAchat = 0.0;
      for (const art of this.bdlSelected.Articles) {
        if (art.Prix_achat && (art.Prix_vente || art.Prix_euro)) {
          totalPrixAchat += parseFloat(parseFloat(art.Prix_achat).toFixed(2));
        }
      }
      return totalPrixAchat ? parseFloat(parseFloat(totalPrixAchat).toFixed(2)) : '-'
    },
    setTotalPrixVente() {
      var totalPrixVente = 0.0;
      for (const art of this.bdlSelected.Articles) {
        if (art.Prix_achat && (art.Prix_vente || art.Prix_euro)) {
          const prixVente = art.PrixFournisseurType == 'prixnet'
            ? parseFloat(parseFloat(art.Prix_vente).toFixed(2))
            : parseFloat(parseFloat(art.Prix_euro - art.Prix_euro * (art.remise / 100)).toFixed(2));

          totalPrixVente += parseFloat(parseFloat(prixVente).toFixed(2));
        }
      }
      return totalPrixVente ? parseFloat(parseFloat(totalPrixVente).toFixed(2)) : '-'
    },
    setMargeTotal() {
      var total = 0.0;
      for (const [index, art] of this.bdlSelected.Articles.entries()) {
        if (art.Prix_achat && (art.Prix_vente || art.Prix_euro)) {

          total += this.setMargeArticle(index);
        }
      }
      return total ? parseFloat(parseFloat(total).toFixed(2)) : '-';
    },
    setMargeTotalePourcentage() {
      const total = (1 - (this.setTotalPrixAchat / this.setTotalPrixVente)) * 100;
      return total ? parseFloat(parseFloat(total).toFixed(2)) : '-';
    }
  },
  components: {
    backButton,
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    setMargeArticle(indexArt) {
      var marge = 0.0;
      for (const [index, art] of this.bdlSelected.Articles.entries()) {
        if (indexArt == index && art.Prix_achat && (art.Prix_vente || art.Prix_euro)) {
          const prixVente = art.PrixFournisseurType == 'prixnet'
            ? parseFloat(parseFloat(art.Prix_vente).toFixed(2))
            : parseFloat(parseFloat(art.Prix_euro - art.Prix_euro * (art.remise / 100)).toFixed(2));

          marge = prixVente - parseFloat(art.Prix_achat).toFixed(2);
        }
      }
      return marge ? parseFloat(parseFloat(marge).toFixed(2)) : '-'
    },
    setMargeArticlePercent(indexArt) {
      var marge = 0.0;
      for (const [index, art] of this.bdlSelected.Articles.entries()) {
        if (indexArt == index && art.Prix_achat && (art.Prix_vente || art.Prix_euro)) {
          const prixVente = art.PrixFournisseurType == 'prixnet'
            ? parseFloat(parseFloat(art.Prix_vente).toFixed(2))
            : parseFloat(parseFloat(art.Prix_euro - art.Prix_euro * (art.remise / 100)).toFixed(2));

          marge = (1 - (parseFloat(art.Prix_achat).toFixed(2) / prixVente)) * 100;
        }
      }
      return marge ? parseFloat(parseFloat(marge).toFixed(2)) : '-'
    },
    createPDF(objet) {
      if (objet.groupedTab && objet.groupedTab.length > 0) {
        this.openGroupedPDF(objet);
      } else {
        var today = new Date();
        var da = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        var dateedition = getFrDateSansH(objet.Date);
        var datepdf = da + "/" + mm + "/" + yyyy;
        if (dateedition == datepdf) {
          if (objet.TotalPrice >= 0) {
            createlivraison(objet, false, false);
          } else {
            createlivraison(objet, false, true);
          }
        } else if (objet.TotalPrice >= 0) {
          createlivraison(objet, true, false);
        } else {
          createlivraison(objet, true, true);
        }
      }
    },
    openGroupedPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createGroupedDoc(objet, "Bon de livraison", "BL", false);
      } else {
        createGroupedDoc(objet, "Bon de livraison", "BL", true);
      }
    },
  },
};
</script>
