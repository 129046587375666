<template>
  <!-- modal récap import -->
  <div
    v-if="openImportRecap"
    :class="`modal ${
      !openImportRecap && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openImportRecap = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 68vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Récapitulatif de l'import</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openImportRecap = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <div
          class="bg-white p-4 inline-block h-fit min-w-full align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="p-2 border shadow-sm mt-1 min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Réf. / Marque
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Description / Préfixe tarif
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Stock
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Réservé
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  min / max
                </th>
              </tr>
            </thead>
            <tbody class="bg-white max-h-36 overflow-y-auto text-sm">
              <tr
                v-for="(art, index) in importData.toUpdate"
                :key="index"
                class="odd:bg-white even:bg-gray-100 hover:bg-bluevk hover:text-white border-black hover:shadow-lg"
              >
                <td class="px-2 border-b border-gray-200 whitespace-nowrap">
                  <p>{{ art.Ref_fournisseur }}</p>
                  <p>{{ art.Code_marque }}</p>
                </td>

                <td class="px-2 border-b border-gray-200 whitespace-nowrap">
                  <p>
                    {{ art.article.Description }}
                  </p>
                  <p class="text-md text-orangevk">{{ art.Prefixe_tarif }}</p>
                </td>

                <td class="px-2 border-b border-gray-200 whitespace-nowrap">
                  <p>{{ art.stock }}</p>
                </td>

                <td class="px-2 border-b border-gray-200 whitespace-nowrap">
                  <p>{{ art.reserved }}</p>
                </td>

                <td class="px-2 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex">
                    <p>{{ art.min }}</p>
                    /
                    <p>{{ art.max }}</p>
                  </div>
                </td>
              </tr>
              <tr
                v-for="(art, index) in importData.unfound"
                :key="index"
                class="odd:bg-red-100 even:bg-red-50 hover:bg-bluevk hover:text-white border-black hover:shadow-lg"
              >
                <td class="px-2 border-b border-gray-200 whitespace-nowrap">
                  {{ art }}
                </td>

                <td
                  class="px-2 border-b border-gray-200 whitespace-nowrap"
                ></td>

                <td
                  class="px-2 border-b border-gray-200 whitespace-nowrap"
                ></td>

                <td
                  class="px-2 border-b border-gray-200 whitespace-nowrap"
                ></td>

                <td
                  class="px-2 border-b border-gray-200 whitespace-nowrap"
                ></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-between items-center">
    <!-- Retour Button -->
    <div id="retour" class="flex justify-start">
      <backButton :route="'/articleHub'" />
    </div>

    <!-- Breadcrumb -->
    <div class="flex justify-end space-x-4">
      <label
        id="dropzone-file"
        @click="exportTemplate()"
        title="Télécharger un template Excel"
        class="cursor-pointer bg-orange-600 hover:bg-orange-500 text-white shadow-md px-2 py-1 flex justify-between rounded-md"
      >
        <span class="m-auto">Template</span>
        <span class="material-icons-outlined m-auto">download</span>
      </label>

      <label
        id="dropzone-file"
        title="Importer Excel"
        class="cursor-pointer bg-emerald-700 hover:bg-emerald-600 text-white shadow-md px-2 py-1 flex justify-between rounded-md"
      >
        <span class="m-auto">Importer Excel</span>
        <span class="material-icons-outlined m-auto">file_upload</span>
        <input
          id="dropzone-file"
          type="file"
          class="hidden"
          accept=".xlsx"
          @change="readExcel()"
        />
      </label>

      <button
        class="bg-blue-500 text-white p-2 ml-6 rounded shadow"
        v-if="!loadingReception"
        @click="updateStock()"
      >
        Valider
      </button>

      <button
        class="flex flex-row items-center space-x-2 font-medium text-white bg-gray-400 rounded-md p-2 ml-6"
        v-else
        disabled
      >
        <span>Valider</span>
        <span class="material-icons-round animate-spin"> cloud_sync </span>
      </button>
    </div>
  </div>

  <div>
    <div class="mt-4 relative">
      <input
        v-on:keyup.enter="searchArticle()"
        v-model="searchRef"
        placeholder="Entrer un code article"
        class="block focus:outline-2 outline-sky-300 mt-4 w-full py-2 pl-6 pr-12 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r appearance-none focus:bg-white focus:placeholder-gray-600 focus:text-gray-700"
      />
      <ul
        v-show="showAutoComplete"
        class="absolute z-20 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <li
          class="bg-white px-4 hover:bg-gray-300 cursor-pointer"
          v-for="(auto, index) in autoCompleteTab"
          :key="index"
          @click="setAutoComplete(auto.Ref_fournisseur)"
        >
          {{ auto.Ref_fournisseur }}
        </li>
        <div class="flex justify-end bg-white">
          <span
            class="material-icons-outlined rounded-full p-0.5 text-red-600 hover:bg-red-400 hover:bg-opacity-25 cursor-pointer"
            @click="showAutoComplete = false"
            >close</span
          >
        </div>
      </ul>
      <ul
        v-show="showArticleList"
        class="absolute z-20 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <div class="bg-white" v-show="loadingSearchArticle">
          Recherche en cours...
        </div>
        <!-- Table pour trouver un produit -->
        <table class="min-w-full" v-if="!loadingSearchArticle">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Réf./Equipe.
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Descr./Fourn.
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                P.P.C.
              </th>
              <th
                class="py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                <button class="cursor-pointer" @click="showArticleList = false">
                  <span
                    class="material-icons-outlined rounded-full p-0.5 text-red-600 hover:bg-red-400 hover:bg-opacity-25"
                    >close</span
                  >
                </button>
              </th>
            </tr>
          </thead>

          <tbody class="bg-white" v-if="searchTab.length > 0">
            <tr
              v-for="(csArticle, index) in searchTab"
              :key="index"
              @click="handlerArticle(csArticle)"
              class="bg-green-300 hover:bg-opacity-25 cursor-pointer"
              style="max-height: 10px"
            >
              <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                <div class="text-sm leading-5 text-gray-900">
                  {{ csArticle.article.Ref_fournisseur }}
                </div>
                <div class="text-sm leading-5 text-gray-900">
                  {{ csArticle.article.Code_marque }}
                </div>
              </td>

              <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                <div class="text-sm leading-5 text-gray-500">
                  {{ csArticle.article.Description }}
                </div>
                <div
                  class="text-sm leading-5 bg-blue-500 text-white p-1 rounded w-fit"
                >
                  {{ csArticle.article.Prefixe_tarif }}
                </div>
              </td>

              <td class="px-6 border-b border-gray-200 whitespace-nowrap"></td>

              <td
                class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                <span v-if="csArticle.article.Prix_ppc"
                  >{{ financial(csArticle.article.Prix_ppc) }} €</span
                >
                <span v-else
                  >{{ financial(csArticle.article.Prix_euro) }} €</span
                >
              </td>
              <td
                class="w-12 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              ></td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
              ></th>
            </tr>
          </thead>
        </table>
        <!-- Fin Table pour trouver un produit -->
      </ul>
    </div>
    <div class="flex animate-pulse" v-show="loadingImport">
      Importation en cours...
    </div>
    <div class="flex flex-col mt-4">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Code EAN
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Article
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Marque
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Stock actuel
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Mouvement
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Stock final
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(u, index) in ESStock" :key="index">
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-row items-center">
                    <div class="ml-2">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ u.article.Code_EAN }}
                      </div>
                    </div>
                    <div class="ml-2">
                      <span
                        class="material-icons-round bg-red-500 rounded-sm text-white cursor-pointer"
                        style="font-size: small"
                        @click="suppLine(index)"
                      >
                        clear
                      </span>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ u.article.Ref_fournisseur }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.article.Code_marque }}
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.article.Description }}
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <span v-if="u.stockData.length > 0">
                    {{ parseInt(u.stockData[0].stock) }}
                    <!-- <span v-if="setStock[index]">
                      =>
                      <span
                        v-if="
                          parseInt(u.stockData[0].stock) >
                          parseInt(u.stockData[0].stock) + setStock[index]
                        "
                        class="bg-red-500 p-1 text-white rounded-full"
                        >{{
                          parseInt(u.stockData[0].stock) + setStock[index]
                        }}</span
                      ><span
                        v-else
                        class="bg-green-500 p-1 text-white rounded-full"
                        >{{
                          parseInt(u.stockData[0].stock) + setStock[index]
                        }}</span
                      ></span> --> </span
                  ><span v-else
                    >0
                    <!-- <span v-if="setStock[index]">
                      =>
                      <span
                        v-if="0 > 0 + setStock[index]"
                        class="bg-red-500 p-1 text-white rounded-full"
                        >{{ 0 + setStock[index] }}</span
                      ><span
                        v-else
                        class="bg-green-500 p-1 text-white rounded-full"
                        >{{ 0 + setStock[index] }}</span
                      ></span
                    > -->
                  </span>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <input
                    type="number"
                    v-model="setStock[index]"
                    class="p-2 rounded shadow focus:outline-2 outline-sky-300"
                  />
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <span v-if="u.stockData.length > 0">
                    <!-- {{ parseInt(u.stockData[0].stock) }} -->
                    <span v-if="setStock[index]">
                      <!-- => -->
                      <span
                        v-if="
                          parseInt(u.stockData[0].stock) >
                          parseInt(u.stockData[0].stock) + setStock[index]
                        "
                        class="bg-red-500 p-1 text-white rounded-full"
                        >{{
                          parseInt(u.stockData[0].stock) + setStock[index]
                        }}</span
                      ><span
                        v-else
                        class="bg-green-500 p-1 text-white rounded-full"
                        >{{
                          parseInt(u.stockData[0].stock) + setStock[index]
                        }}</span
                      ></span
                    > </span
                  ><span v-else>
                    <!-- 0 -->
                    <span v-if="setStock[index]">
                      <!-- => -->
                      <span
                        v-if="0 > 0 + setStock[index]"
                        class="bg-red-500 p-1 text-white rounded-full"
                        >{{ 0 + setStock[index] }}</span
                      ><span
                        v-else
                        class="bg-green-500 p-1 text-white rounded-full"
                        >{{ 0 + setStock[index] }}</span
                      ></span
                    ></span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { regularSearchArticles } from "@/hooks/searchEngine/articles";
import { useToast } from "vue-toastification";
import readXlsxFile from "read-excel-file";
import * as XLSX from "xlsx";
import {
  esstock,
  importEsStock,
  findStock,
  checkEmptyLines,
  importStock,
} from "@/hooks/stock/stock.ts";
import backButton from "@/components/Button/back.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      open: false,
      ESStock: [],
      searchRef: "",
      setStock: [],
      finalStockTab: [],
      autoCompleteTab: [],
      showAutoComplete: false,
      showArticleList: false,
      searchTab: [],
      loadingSearchArticle: false,
      file: null,
      readFile: null,
      showFile: false,
      showFileContent: true,
      importLoading: false,
      showFinished: false,
      loadingImport: false,
      loadingReception: false,

      openImportRecap: false,
      importData: null,
    };
  },
  computed: {
    ...mapGetters(["user", "plateform", "comptoirSessionActive"]),
  },
  methods: {
    async searchArticle() {
      this.showAutoComplete = false;
      this.showArticleList = true;
      if (this.searchRef != "") {
        this.searchTab = [];
        this.loadingSearchArticle = true;
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        this.searchTab = await regularSearchArticles(
          this.searchRef,
          plateform_mere
        );
        this.loadingSearchArticle = false;

        this.searchRef = "";
      } else {
        if (this.searchTab.length >= 1) {
          this.handlerArticle(this.searchTab[0]);
          this.showAutoComplete = false;
          this.showArticleList = false;
          this.searchRef = "";
        }
      }
    },
    async handlerArticle(data) {
      let stock = await findStock(
        this.user._id,
        this.user.proprietaire,
        data.article.Ref_fournisseur,
        data.article.Code_marque,
        data.article.Prefixe_tarif
      );
      if (stock.state === "do not exist") {
        data.stockData = [];
      } else {
        data.stockData = [stock.data];
      }
      this.ESStock.push(data);
      this.setStock.push(1);
      this.showAutoComplete = false;
      this.showArticleList = false;
      this.searchRef = "";
    },
    suppLine(index) {
      this.ESStock.splice(index, 1);
      this.setStock.splice(index, 1);
    },
    async updateStock() {
      this.loadingReception = true;
      this.ESStock.forEach((element, index) => {
        if (element.stockData.length > 0) {
          this.finalStockTab.push({
            Code_EAN: element.article.Code_EAN,
            Ref_fournisseur: element.article.Ref_fournisseur,
            Code_marque: element.article.Code_marque,
            article_id: element.article._id,
            setStock:
              parseInt(element.stockData[0].stock) + this.setStock[index],
            plateform: this.user.proprietaire,
            Prefixe_tarif: element.article.Prefixe_tarif,
            genericArticleId: element.article.genericArticleId,
          });
        } else {
          this.finalStockTab.push({
            Code_EAN: element.article.Code_EAN,
            Ref_fournisseur: element.article.Ref_fournisseur,
            article_id: element.article._id,
            setStock: 0 + this.setStock[index],
            plateform: this.user.proprietaire,
            Prefixe_tarif: element.article.Prefixe_tarif,
            Code_marque: element.article.Code_marque,
            genericArticleId: element.article.genericArticleId,
          });
        }
        var oldStock;
        if (element.stockData.length > 0) {
          oldStock = parseInt(element.stockData[0].stock);
        } else {
          oldStock = 0;
        }
      });

      await esstock(
        this.user.proprietaire,
        this.finalStockTab,
        this.user.username,
        "Mise à jour du stock via ES Stock par " + this.user.username,
        "Pas de client",
        0,
        this.user._id
      );
      this.toast.success("Enregistrée !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      this.finalStockTab = [];
      this.ESStock = [];
      this.setStock = [];
      this.loadingReception = false;
    },
    autoComplete(ref) {
      if (this.searchRef != "") {
        axios
          .get(`${process.env.VUE_APP_API}/article/autocomplete/${ref}`)
          .then((response) => {
            this.showAutoComplete = true;
            this.autoCompleteTab = response.data;
          });
      } else {
        this.showAutoComplete = false;
      }
    },
    setAutoComplete(ref) {
      this.searchRef = ref;
      this.showAutoComplete = false;
      this.searchArticle();
    },
    async readExcel() {
      this.loadingImport = true;
      this.file = event.target.files ? event.target.files[0] : null;
      this.readFile = await readXlsxFile(this.file);
      this.readFile.splice(0, 1);
      let check = await checkEmptyLines(
        this.user._id,
        this.user.proprietaire,
        this.readFile
      );
      if (check.state) {
        this.toast.error("Certaines lignes du tableau sont incomplètes!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else {
        this.importData = await importStock(
          this.user.proprietaire,
          this.readFile,
          this.user.username,
          "Mise à jour du stock via ES Stock par " + this.user.username,
          "Pas de client",
          0,
          this.user._id
        );

        this.openImportRecap = true;
      }

      this.loadingImport = false;
    },
    exportTemplate() {
      const dataToExport = [
        {
          Code_EAN: "",
          Ref_fournisseur: "",
          Code_marque: "",
          Prefixe_tarif: "",
          Quantité: "",
        },
      ];

      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(dataToExport);
      XLSX.utils.book_append_sheet(wb, ws, "stock");

      XLSX.writeFile(wb, "EntréeStockTemplate" + ".xlsx");
    },
    importerFile() {
      this.importLoading = true;
      axios
        .post(`${process.env.VUE_APP_API}/stock/importExcel`, {
          data: this.readFile,
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.importLoading = false;
          this.showFinished = true;
        });
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
  },
  components: {
    backButton,
  },
};
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
