<template>
  <div>
    <div
      v-if="infoInGrpBdl"
      :class="`modal ${
        !infoInGrpBdl && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="infoInGrpBdl = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 78rem"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-center modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Détails de la Facture Pro</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="infoInGrpBdl = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <div
            class="py-2 -my-2 mt-3 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
            style="height: 30vh"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Créé par
                    </th>
                    <th
                      class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Numéro
                    </th>
                    <th
                      class="px-6 py-3 font-medium leading-4 tracking-wider uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Prix unitaire
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(u, index) in detailFacture.Bdls"
                    :key="index"
                    style="cursor: pointer"
                    class="hover:bg-blue-50"
                  >
                    <td
                      class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
                    >
                      {{ u.Name }}
                    </td>
                    <td
                      class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
                    >
                      {{ u.Numero }}
                    </td>
                    <td
                      class="px-6 py-3 text-xl font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200"
                    >
                      {{ u.TotalPrice }} €
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Breadcrumb -->

    <!-- <div class="flex flex-col mt-3 sm:flex-row">
      <div class="flex">
        <div class="relative">
          <select
            class="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option>5</option>
            <option>10</option>
            <option>20</option>
          </select>

          <div
            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
          >
            <svg
              class="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>

        <div class="relative">
          <select
            class="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border-t border-b border-r border-gray-400 rounded-r appearance-none sm:rounded-r-none sm:border-r-0 focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
          >
            <option>All</option>
            <option>Active</option>
            <option>Inactive</option>
          </select>

          <div
            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
          >
            <svg
              class="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="relative block mt-2 sm:mt-0">
        <span class="absolute inset-y-0 left-0 flex items-center pl-2">
          <svg viewBox="0 0 24 24" class="w-4 h-4 text-gray-500 fill-current">
            <path
              d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"
            />
          </svg>
        </span>

        <input
          placeholder="Search"
          class="block w-full focus:outline-2 outline-sky-300 py-2 pl-8 pr-6 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r appearance-none sm:rounded-l-none focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
        />
      </div>
    </div> -->
    <div class="flex flex-row items-center space-x-2">
      <input
        type="text"
        placeholder="Ex. : auto"
        v-model="inputFilter"
        class="px-1 py-0.5 w-56 rounded-md focus:outline-2 outline-sky-300"
      />
      <button
        @click="filterByValue(inputFilter)"
        class="px-6 py-1 font-medium tracking-wide text-white bg-blue-400 rounded-md hover:bg-blue-300 outline-sky-300"
      >
        Rechercher
      </button>
    </div>

    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  v-show="groupeState"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Select
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Référence client</span>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Date</span>
                  <span
                    v-if="showSortDate"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByDate(),
                        (showReverseDate = true),
                        (showSortDate = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseDate"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByDate(),
                        (showSortDate = true),
                        (showReverseDate = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Numéro</span>
                  <span
                    v-if="showSortNumero"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByNumero(),
                        (showReverseNumero = true),
                        (showSortNumero = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseNumero"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByNumero(),
                        (showSortNumero = true),
                        (showReverseNumero = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Client</span>
                  <span
                    v-if="showSortAlpha"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByAlpha(),
                        (showReverseAlpha = true),
                        (showSortAlpha = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseAlpha"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByAlpha(),
                        (showSortAlpha = true),
                        (showReverseAlpha = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Créé par</span>
                  <span
                    v-if="showSortUsername"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByUsername(),
                        (showReverseUsername = true),
                        (showSortUsername = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseUsername"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByUsername(),
                        (showSortUsername = true),
                        (showReverseUsername = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Total Prix</span>
                  <span
                    v-if="showSortTotalPrice"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByTotalPrice(),
                        (showReverseTotalPrice = true),
                        (showSortTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseTotalPrice"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByTotalPrice(),
                        (showSortTotalPrice = true),
                        (showReverseTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  PDF
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(u, index) in infosBdl"
                :key="index"
                class="hover:bg-gray-100"
              >
                <td
                  v-show="groupeState"
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <input
                    type="checkbox"
                    class="focus:outline-2 outline-sky-300"
                  />
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Note }}
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex items-center">
                    <!-- <div class="flex-shrink-0 w-10 h-10">
                      <img
                        class="w-10 h-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt
                      />
                    </div>-->

                    <div class="ml-4">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ getFRDate(u.Date) }}
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ u.Numero }}
                    <span
                      @click="(infoInGrpBdl = true), getDetailFacture(u)"
                      title="Plus d'informations"
                      class="material-icons-outlined text-base text-cyan-600 cursor-pointer"
                    >
                      info
                    </span>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Client }}
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Name }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPrice).toFixed(2) }} €
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <!-- <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                    v-if="u.IsProcessed === true"
                    ><span class="material-icons-outlined"> done </span></span
                  >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full"
                    v-else
                    ><span class="material-icons-outlined">close</span></span
                  > -->
                  <div class="flex space-x-4">
                    <div class="flex flex-col relative">
                      <span
                        class="material-icons-outlined cursor-pointer text-blue-600 hover:text-opacity-25"
                        @click="setEmails(u, index)"
                      >
                        email
                      </span>
                      <modalFacture
                        v-if="showEmail[index]"
                        :showEmail="showEmail[index]"
                        :ind="index"
                        :objet="u"
                        :tabFacture="infosFacture[index].clientInfo[0]"
                        :tabTempInfosFacture="infosFacture"
                        @close="showEmail[index] = false"
                      />
                      <!-- <div
                        v-show="showEmail[index]"
                        class="flex justify-center -ml-20 absolute mt-4 bg-gray-200 p-2 rounded shadow-lg z-20 text-sm"
                      >
                        <div class="flex flex-col space-y-2">
                          <form
                            @change="
                              checkForm(plateform.Email, u.clientInfo[0].email)
                            "
                            class="space-y-1"
                          >
                            <p v-if="errors.length">
                              <span
                                v-for="(error, index) in errors"
                                :key="index"
                                class="text-red-600"
                                >{{ error }}</span
                              >
                            </p>
                            <p v-else></p>
                            <div class="flex m-auto space-x-1">
                              <label for="emailFrom" class="m-auto w-6 text-xs"
                                >De :</label
                              >
                              <input
                                @change="errors == []"
                                disabled
                                type="email"
                                id="emailFrom"
                                name="emailFrom"
                                placeholder="Expéditeur"
                                class="w-fit focus:outline-2 outline-sky-300 w-full px-2 py-1 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                                v-model="plateform.Email"
                              />
                            </div>

                            <div class="flex m-auto space-x-1">
                              <label for="emailTo" class="m-auto w-6 text-xs"
                                >À :</label
                              >
                              <input
                                @change="errors == []"
                                type="email"
                                id="emailTo"
                                name="emailTo"
                                placeholder="Destinataire"
                                class="w-fit focus:outline-2 outline-sky-300 w-full px-2 py-1 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                                v-model="u.clientInfo[0].email"
                              />
                            </div>
                          </form>
                          <div class="flex justify-center space-x-1">
                            <button
                              @click="showEmail[index] = false"
                              class="px-6 py-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
                            >
                              Annuler
                            </button>
                            <input
                              type="submit"
                              value="Envoyer"
                              class="w-fit cursor-pointer px-6 py-2 font-medium tracking-wide text-white bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none"
                              @click="
                                sendEmail(
                                  u,
                                  index,
                                  plateform.Email,
                                  u.clientInfo[0].email
                                )
                              "
                            />
                          </div>
                        </div>
                      </div> -->
                    </div>

                    <span
                      class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
                      @click="createPDF(u)"
                    >
                      download
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="flex flex-col items-center px-5 py-5 bg-white border-t xs:flex-row xs:justify-between"
          >
            <span class="text-xs text-gray-900 xs:text-sm"
              >1 à 4 sur 50 Articles</span
            >

            <div class="inline-flex mt-2 xs:mt-0">
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded-l hover:bg-gray-400"
              >
                Précédent
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
              >
                1
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
              >
                2
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
              >
                3
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
              >
                4
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded-r hover:bg-gray-400"
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { createfacturepro } from "@/hooks/pdf/groupebdl";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";
// import {sendemailfacturepro} from "@/hooks/email/emailGroupebdl";
import { useToast } from "vue-toastification";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyBdl",
  data: () => ({
    infosBdl: [],
    infosBdlTmp: [],
    groupeState: false,
    produits: [],
    inputFilter: "",

    showSortDate: true,
    showReverseDate: false,
    showSortNumero: true,
    showReverseNumero: false,
    showSortAlpha: true,
    showReverseAlpha: false,
    showSortUsername: true,
    showReverseUsername: false,
    showSortTotalPrice: true,
    showReverseTotalPrice: false,

    showEmail: [],
    errors: [],

    infoInGrpBdl: false,
    detailFacture: [],
  }),
  computed: {
    ...mapGetters(["user", "plateform"]),
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    getDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      return dd + "/" + mm + "/" + yyyy;
    },
    getDatePaiement() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 2).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      return dd + "/" + mm + "/" + yyyy;
    },
    getInfosBdl() {
      axios
        .post(`${process.env.VUE_APP_API}/groupebdl/getByOwner`, {
          uid: this.user.proprietaire,
        })
        .then((response) => {
          console.log(response.data);
          this.infosBdl = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDetailFacture(u) {
      this.detailFacture = u;
    },
    setEmails(objet, index) {
      if (
        objet.clientInfo[0] &&
        objet.clientInfo[0].Emails &&
        objet.clientInfo[0].Emails.length > 0
      ) {
        objet.clientInfo[0].Emails.forEach((element, index) => {
          if (element.facture == false) {
            objet.clientInfo[0].Emails.splice(index, 1);
          } else {
            this.checkForm(this.plateform.Email, element.address);
          }
        });
        this.showEmail[index] = !this.showEmail[index];
      } else {
        this.checkForm(this.plateform.Email, objet.clientInfo[0].email);
        this.showEmail[index] = !this.showEmail[index];
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm(emailFrom, emailTo) {
      console.log(emailTo);
      this.errors = [];
      if (!emailFrom || !emailTo) {
        this.errors = [];
        this.errors.push("E-mail obligatoire.");
      } else if (!this.validEmail(emailFrom) || !this.validEmail(emailTo)) {
        this.errors = [];
        this.errors.push("E-mail incorrect.");
      } else {
        this.errors = [];
      }
    },
    createPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        if (objet.TotalPrice >= 0) {
          createfacturepro(objet, false, false);
        } else {
          createfacturepro(objet, false, true);
        }
      } else if (objet.TotalPrice >= 0) {
        createfacturepro(objet, true, false);
      } else {
        createfacturepro(objet, true, true);
      }
    },
    sortByDate() {
      this.infosBdl.sort((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
    },
    reverseByDate() {
      this.sortDate = this.infosBdl.sort((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
      this.sortDate.reverse((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
    },
    sortByNumero() {
      this.infosBdl.sort((a, b) => {
        if (a.Numero < b.Numero) {
          return -1;
        }
        if (a.Numero > b.Numero) {
          return 1;
        }
        return 0;
      });
    },
    reverseByNumero() {
      this.sortNumero = this.infosBdl.sort((a, b) => {
        if (a.Numero < b.Numero) {
          return -1;
        }
        if (a.Numero > b.Numero) {
          return 1;
        }
        return 0;
      });
      this.sortNumero.reverse((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    sortByAlpha() {
      this.infosBdl.sort((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    reverseByAlpha() {
      this.sortAlpha = this.infosBdl.sort((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
      this.sortAlpha.reverse((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    sortByUsername() {
      this.infosBdl.sort((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
    },
    reverseByUsername() {
      this.sortUsername = this.infosBdl.sort((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
      this.sortUsername.reverse((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
    },
    sortByTotalPrice() {
      this.infosBdl.sort((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
    },
    reverseByTotalPrice() {
      this.sortTotalPrice = this.infosBdl.sort((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
      this.sortTotalPrice.reverse((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
    },
    filterByValue(value) {
      var filterTab = [];
      if (value != "") {
        this.infosBdl = this.infosBdlTmp;
        this.infosBdl.filter((obj) => {
          //loop through each object
          for (const key in obj) {
            if (
              obj[key].toString().toLowerCase().includes(value.toLowerCase())
            ) {
              filterTab.push(obj);
              break;
            }
          }
        });
        this.infosBdl = filterTab;
      }
    },
  },
  mounted() {
    this.getInfosBdl();
  },
};
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
