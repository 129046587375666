<template>
  <!-- Back Button -->
  <div class="flex justify-between">
    <div id="retour" class="flex justify-start mb-2">
      <backButton :route="'/suiviDepot'" />
    </div>
    <div>
      <h3 class="text-lg font-medium">
        Articles {{ fournisseurTab.CompanyName }} des bons de livraison
      </h3>
    </div>
    <div>
      <button
        v-if="!loadingExport"
        class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded-[7px] mr-1 flex justify-center items-center"
        @click="exportExcel()"
      >
        <span class="material-icons-outlined text-xl mr-2"> download </span>
        <span>Exporter en Excel</span>
      </button>
      <button
        v-else
        class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
      >
        <PulseLoader color="#ffff" class="mr-2" />
        <span>Export en cours </span>
      </button>
    </div>
  </div>

  <!-- Recherche -->
  <div class="flex justify-between">
    <searchInput
      :placeholder="'Rechercher dans le tableau'"
      @searchWord="getArticleLogs(1, 10, null, $event, '_id', -1, false)"
      @catchFalse="searchCatchError = $event"
      :searchStarted="searchInProgress"
      :catchError="searchCatchError"
      class="mt-2"
    />

    <div class="flex space-x-2 my-auto">
      <button
        @click="
          getArticleLogs(
            1,
            10,
            'DA SILVA',
            plateform.dasilva.codeClient,
            '_id',
            -1,
            false
          )
        "
        class="w-32 bg-blue-500 hover:bg-blue-600 shadow-lg rounded-md text-white px-2 py-1 border-2 border-white"
      >
        BDL DA SILVA</button
      ><button
        @click="
          getArticleLogs(
            1,
            10,
            '',
            plateform.dasilva.codeClient,
            '_id',
            -1,
            false
          )
        "
        class="w-32 bg-orange-400 hover:bg-orange-500 shadow-lg rounded-md text-white px-2 py-1 border-2 border-white"
      >
        BDL {{ user.proprietaire }}
      </button>
    </div>
  </div>

  <div class="flex flex-col mt-2">
    <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Réf. / Description
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Quantité
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Prix
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Transporteur
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Frais de transport
              </th>
            </tr>
          </thead>

          <template v-for="(bdl, indexBdl) in articlesTab" :key="indexBdl">
            <!-- Affichage des informations du bdl avant chaque liste d'articles -->
            <tr
              class="font-medium bg-gray-100 hover:shadow border-t-2 border-gray-400"
              :class="
                bdl.codeClientDsv != plateform.dasilva.codeClient
                  ? 'text-orangevk'
                  : 'text-bluevk'
              "
            >
              <td colspan="3" class="px-6 py-4 text-sm">
                <h1>
                  BL n°{{ bdl.docNumber }} - {{ getFRDate(bdl.date) }} -
                  {{ bdl.client }} - {{ bdl.user }} - {{ bdl.refClient }}
                </h1>
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 text-gray-900 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
              >
                <input
                  class="outline-2 focus:outline-sky-300 border border-gray-800 px-1 py-0.5 rounded-md shadow-md"
                  v-model="bdl.transporteur"
                  @change="saveBdl(bdl, 'transporteur', bdl.transporteur)"
                />
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
              >
                <input
                  class="outline-2 focus:outline-sky-300 border border-gray-800 px-1 py-0.5 rounded-md shadow-md"
                  v-model="bdl.Transport"
                  type="number"
                  @change="saveBdl(bdl, 'Transport', bdl.Transport)"
                />
                €
              </td>
            </tr>

            <!-- Affichage des articles associés au bdl -->
            <tr
              v-for="(art, index) in bdl.articles"
              :key="index"
              class="hover:bg-gray-100 cursor-pointer hover:shadow text-gray-600"
            >
              <td
                class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
              >
                <div class="flex flex-col">
                  <div class="text-sm font-medium leading-5">
                    {{ art.Ref_fournisseur }}
                  </div>
                  <div class="text-sm font-medium leading-5">
                    {{ art.Description }}
                  </div>
                </div>
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
              >
                {{ art.quantity }}
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
              >
                -
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
              ></td>

              <td
                class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
              ></td>
            </tr>
          </template>
        </table>

        <pageNumber
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="articlesTabLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(articlesTabLength / 10)"
          :clickFunction="
            (arg1, arg2) =>
              getArticleLogs(
                arg1,
                arg2,
                searchField,
                searchTerm,
                '_id',
                -1,
                false
              )
          "
          :currentPage="currentPage"
          :typeList="'bons de livraison'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import backButton from "@/components/Button/back.vue";
import { getFournisseurByID } from "@/hooks/fournisseur/fournisseur.ts";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import * as XLSX from "xlsx";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import { getFrDate } from "@/hooks/tools/date.ts";
import { useToast } from "vue-toastification";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      fournisseurTab: {},
      articlesTab: [],
      articlesTabLength: 0,

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,

      searchActive: false,
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,

      loadingExport: false,
      searchField: null,
      searchTerm: null,
    };
  },
  components: {
    backButton,
    PulseLoader,
    pageNumber,
    searchInput,
  },
  computed: {
    ...mapGetters(["fournisseurID", "user", "plateform"]),
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    async getFournisseur() {
      this.fournisseurTab = await getFournisseurByID(
        this.fournisseurID,
        this.user.proprietaire
      );
    },
    async getArticleLogs(
      skipValue,
      limitValue,
      searchField,
      searchTerm,
      sortBy,
      sortOrder,
      exportation
    ) {
      this.currentPage = skipValue;
      this.searchField = searchField;
      this.searchTerm = searchTerm;
      await axios
        .post(`${process.env.VUE_APP_API}/emailDaSilva/getArticleLogs`, {
          plateform: this.user.proprietaire,
          fournisseur: this.fournisseurTab.CompanyName,
          searchField: searchField,
          searchTerm: searchTerm,
          sortBy: sortBy,
          sortOrder: sortOrder,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          console.log(response);
          if (exportation) {
            this.allArticlesTab = response.data.tab;
          } else {
            this.articlesTab = response.data.tab;
            this.articlesTabLength = response.data.count;
          }
        });
    },
    async exportExcel() {
      try {
        this.loadingExport = true;

        console.log(this.articlesTabLength, this.searchField, this.searchTerm);

        // Récupérer les données des articles avant l'exportation
        await this.getArticleLogs(
          1,
          this.articlesTabLength,
          this.searchField,
          this.searchTerm,
          "_id",
          -1,
          true
        );

        // Créer un tableau pour les données à exporter
        let articlesData = [];

        // Parcourir chaque élément dans articlesTab et extraire les articles
        this.allArticlesTab.forEach((articleEntry) => {
          articleEntry.articles.forEach((article) => {
            articlesData.push({
              "Numéro Document": articleEntry.docNumber,
              Client: articleEntry.client,
              "Code Client DSV": articleEntry.codeClientDsv,
              "Référence Client": articleEntry.refClient,
              Date: this.getFRDate(articleEntry.date),
              Utilisateur: articleEntry.user,
              Référence: article.Ref_fournisseur,
              Description: article.Description,
              Quantité: article.quantity,
              Transporteur: articleEntry.transporteur,
              Transport: articleEntry.Transport,
            });
          });
        });

        // Créer un nouveau classeur Excel
        let wb = XLSX.utils.book_new();

        // Convertir les données des articles en feuille de calcul
        let ws = XLSX.utils.json_to_sheet(articlesData);

        // Ajouter la feuille de calcul au classeur
        XLSX.utils.book_append_sheet(
          wb,
          ws,
          "Articles " + (this.fournisseurTab?.CompanyName || "Inconnu")
        );

        // Écrire le fichier Excel
        XLSX.writeFile(
          wb,
          `Liste_Articles_${this.fournisseurTab?.CompanyName || "Inconnu"}.xlsx`
        );

        this.loadingExport = false;

        // Afficher une notification de succès
        this.toast.success("Fichier téléchargé avec succès !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          closeButton: "button",
        });
      } catch (error) {
        this.loadingExport = false;
        console.error("Erreur lors de l'exportation :", error);
        this.toast.error("Erreur lors de l'exportation du fichier.", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          closeButton: "button",
        });
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    saveBdl(bdl, field, data) {
      axios
        .put(`${process.env.VUE_APP_API}/bdl/${bdl._id}`, { [field]: data })
        .then(() => {
          this.toast.success("Modifié avec succès !", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  async mounted() {
    await this.getFournisseur();
    this.getArticleLogs(1, 10, null, "", "_id", -1, false);
    this.pageSwitch();
  },
};
</script>
