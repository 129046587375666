<template>
  <div v-if="caisse" class="-mt-2">
    <!-- Back Button -->
    <div class="flex justify-between ml-6.5 mb-3">
      <div id="retour" class="flex justify-start">
        <backButton :route="'/bdl'" />
      </div>
    </div>
    <!-- Fin Back Button -->

    <!-- MODALS -->

    <!-- modal promos  -->
    <div v-if="promoShow" :class="`modal ${!promoShow && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`">
      <div @click="promoShow = false" class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>

      <div class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 40rem">
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close">
          <svg class="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
            viewBox="0 0 18 18">
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Promotions</p>
            <div class="z-50 cursor-pointer modal-close" @click="promoShow = false">
              <svg class="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                viewBox="0 0 18 18">
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div v-for="(promo, index) in tabPromo" :key="index" class="grid">
            <div v-if="promo.type == 'remise'" class="flex">
              <h5 class="ml-12 mt-2 mr-2 text-orange-400">Remise :</h5>
              <div class="flex flex-col mt-2">
                <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                  <div
                    class="inline-block h-fit min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg overflow-y-auto">
                    <table class="min-w-full">
                      <thead>
                        <tr>
                          <th
                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                            Réf.
                          </th>

                          <th
                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                            Qté
                          </th>

                          <th
                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                            Remise
                          </th>
                        </tr>
                      </thead>

                      <tbody class="bg-white">
                        <tr class="cursor-pointer hover:bg-orange-200 hover:bg-opacity-25" @click="
                          setRemisePromo(promo.Remise), (promoShow = false)
                          ">
                          <td
                            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                            {{ promo.Ref_fournisseur }}
                          </td>

                          <td
                            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                            1
                          </td>

                          <td
                            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                            {{ promo.Remise }} %
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- <span
                class="material-icons-outlined ml-2 text-base text-orange-600 cursor-pointer"
              >
                discount
              </span>
              <span
                class="cursor-pointer"
                @click="setRemisePromo(promo.Remise), (promoShow = false)"
                >Remise => {{ promo.Remise }}%</span
              > -->
              </div>
            </div>

            <div v-else-if="promo.type == 'prixnet'" class="flex">
              <h5 class="ml-12 mt-2 mr-2 text-orange-400">Prix net :</h5>
              <div class="flex flex-col mt-2">
                <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                  <div
                    class="inline-block h-fit min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg overflow-y-auto">
                    <table class="min-w-full">
                      <thead>
                        <tr>
                          <th
                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                            Réf.
                          </th>

                          <th
                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                            Qté
                          </th>

                          <th
                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                            Prix
                          </th>
                        </tr>
                      </thead>

                      <tbody class="bg-white">
                        <tr class="cursor-pointer hover:bg-orange-200 hover:bg-opacity-25" @click="
                          setPrixNet(1, promo.PrixNet, 0), (promoShow = false)
                          ">
                          <td
                            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                            {{ promo.Ref_fournisseur }}
                          </td>

                          <td
                            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                            1
                          </td>

                          <td
                            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                            {{ financial(promo.PrixNet) }} €
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- <span
                class="material-icons-outlined ml-2 text-base text-orange-600 cursor-pointer"
              >
                discount
              </span>
              <span
                class="cursor-pointer"
                @click="setPrixNet(1, promo.PrixNet, 0), (promoShow = false)"
                >Prix net => {{ promo.PrixNet }} €</span
              > -->
              </div>
            </div>

            <div v-else-if="promo.type == 'quantitatif'" class="flex">
              <h5 class="mt-2 mr-2 text-orange-400">Prix quantitatif :</h5>
              <div class="flex flex-col mt-2">
                <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                  <div
                    class="inline-block h-fit min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg overflow-y-auto">
                    <table class="min-w-full">
                      <thead>
                        <tr>
                          <th
                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                            Réf.
                          </th>

                          <th
                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                            Désignation
                          </th>

                          <th
                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                            Qté
                          </th>

                          <th
                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                            Prix/unité
                          </th>
                        </tr>
                      </thead>

                      <tbody class="bg-white">
                        <tr class="cursor-pointer hover:bg-orange-200 hover:bg-opacity-25"
                          v-for="(qtif, indexQ) in promo.PrixQuantitatif" :key="indexQ" @click="
                            setPrixQuantitatif(qtif.quantite, qtif.prixnet),
                            (promoShow = false)
                            ">
                          <td
                            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                            {{ promo.Ref_fournisseur }}
                          </td>

                          <td
                            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                            {{ qtif.designation }}
                          </td>

                          <td
                            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                            1
                          </td>

                          <td
                            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                            {{ financial(qtif.prixnet) }} €
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- <div
                v-for="(qtif, indexQ) in promo.PrixQuantitatif"
                :key="indexQ"
                class="flex shadow bg-yellow-400 bg-opacity-25 cursor-pointer py-2 px-4 rounded-full mb-4"
                @click="
                  setPrixQuantitatif(qtif.quantite, qtif.prixnet),
                    (promoShow = false)
                "
              >
                <span
                  class="material-icons-outlined mt-1 mr-1 text-base text-orange-600"
                >
                  discount
                </span>
                <span class="">
                  Promo quantitatif => {{ qtif.designation }} =
                  {{ qtif.prixnet }} €</span
                >
              </div> -->
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button @click="promoShow = false"
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none">
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Supprimer modal  -->
    <div v-if="showModal" class="z-50 fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div class="bg-white p-6 rounded-lg">
        <p>Êtes-vous sûr de vouloir supprimer ce document ?</p>
        <div class="mt-4 flex justify-end">
          <button class="px-4 py-2 bg-red-500 text-white rounded-md mr-3 hover:bg-blue-400 focus:outline-none"
            @click="confirmDelete">
            Oui
          </button>
          <button class="px-4 py-2 bg-gray-300 rounded-md hover:bg-blue-400 focus:outline-none"
            @click="showModal = false">
            Non
          </button>
        </div>
      </div>
    </div>
    <!-- valider modal -->
    <div v-if="modifierModal" class="z-50 fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div class="bg-white p-6 rounded-lg">
        <p>Êtes-vous sûr de vouloir enregistrer les modifications ?</p>
        <div class="mt-4 flex justify-end">
          <button class="px-4 py-2 bg-green-500 text-white rounded-md mr-3 hover:bg-blue-400 focus:outline-none"
            @click="confirmModification">
            Oui
          </button>
          <button class="px-4 py-2 bg-gray-300 rounded-md hover:bg-blue-400 focus:outline-none"
            @click="modifierModal = false">
            Non
          </button>
        </div>
      </div>
    </div>
    <!-- fin modal promos -->

    <!-- modal encaissement -->
    <div v-if="openSave" :class="`modal ${!openSave && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`">
      <div @click="openSave = false" class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>

      <div class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md">
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close">
          <svg class="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
            viewBox="0 0 18 18">
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Encaissement</p>
            <div class="z-50 cursor-pointer modal-close" @click="openSave = false">
              <svg class="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                viewBox="0 0 18 18">
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div class="px-5 py-6 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Commentaire interne</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
                <span class="material-icons-round"> subtitles </span>
              </span>

              <input type="text" v-model="titreDocument"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none" />
            </div>
          </div>

          <!-- <div
            class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
            v-if="vehiculeSet"
          >
            <label class="text-xs">Référence client</label>

            <div class="relative mt-2 rounded-md shadow-sm" v-if="vehicule">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round"> article </span>
              </span>

              <input
                type="textfield"
                :placeholder="vehicule[0].data.AWN_immat"
                v-model="noteDocument"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none"
              />
            </div>
          </div> -->
          <div class="px-5 py-6 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Mode de règlement</label>
            <div class="relative">
              <label class="text-xs">Montant à payer : {{ financial(setTotalTTC) }} €</label>
            </div>

            <div class="relative mt-2 rounded-md shadow-sm" v-for="(reglement, index) in reglementTab" :key="index">
              <span class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
                <span class="material-icons-round"> {{ reglement.icon }} </span>
              </span>

              <input type="Number" :placeholder="reglement.placeholder" v-model="reglementTab[index].model"
                class="w-2/5 focus:outline-2 outline-sky-300 pl-12 pr-2 py-2 border-transparent rounded-md appearance-none" />
              <input type="Date" placeholder="Date" v-model="reglementTab[index].date"
                class="ml-2 focus:outline-2 outline-sky-300 w-2/5 px-5 py-2 border-transparent rounded-md appearance-none" />
              <span class="w-1/5 px-5 py-2 border-transparent rounded-md appearance-none">
                <span class="mt-2 material-icons-outlined text-red-600 cursor-pointer" @click="removeReglement(index)">
                  clear
                </span>
              </span>
            </div>
            <!-- <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round"> add </span>
              </span>

              <input
                type="text"
                v-on:keyup.enter="setReglement()"
                v-model="reglementMethod"
                placeholder="Ajouter"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none"
              />
            </div> -->
            <div class="relative mt-3 rounded-md">
              <label class="text-l cursor-pointer shadow-sm w-1/5 px-3 py-2 bg-white rounded-md"
                @click="setReglement('es')">Espèce</label>
              <label class="text-l cursor-pointer shadow-sm ml-1 w-1/5 px-3 py-2 bg-white rounded-md"
                @click="setReglement('cb')">CB</label>
              <label class="text-l cursor-pointer shadow-sm ml-1 w-1/5 px-3 py-2 bg-white rounded-md"
                @click="setReglement('cc')">Chèque</label>
              <label class="text-l cursor-pointer shadow-sm ml-1 w-1/5 px-3 py-2 bg-white rounded-md"
                @click="setReglement('vi')">Virement</label>
              <label v-if="showPro" class="text-l cursor-pointer shadow-sm ml-1 w-1/5 px-3 py-2 bg-white rounded-md"
                @click="setReglement('lc')">LCR</label>
            </div>
            <div class="relative mt-3" v-show="errorPaymentMethod">
              <label class="text-xs text-red-500">Cette methode de paiement n'existe pas</label>
            </div>
            <div class="relative mt-3" v-show="errorEncaissement">
              <label class="text-xs text-red-500">Veuillez encaisser la facture</label>
            </div>
            <div class="relative mt-3">
              <label class="text-l">Reste dû : {{ financial(setRestant) }} €</label>
              <label class="text-xs"> - </label>
              <label class="text-l">A rendre : {{ financial(setRendu) }} €</label>
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button @click="openSave = false"
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none">
              Fermer
            </button>
            <button @click="saveDocument()"
              class="cursor-pointer px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none">
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- fin modal encaissement -->

    <!-- Modal Retour -->
    <div v-if="openRetour" :class="`modal ${!openRetour && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`">
      <div @click="openRetour = false" class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>

      <div style="max-width: 80rem"
        class="z-50 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md">
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close">
          <svg class="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
            viewBox="0 0 18 18">
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Retour article</p>
            <div class="z-50 cursor-pointer modal-close" @click="openRetour = false">
              <svg class="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                viewBox="0 0 18 18">
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div>
            <div v-show="!tabRetourHistorique" class="h-fit">
              <span class="material-icons-round cursor-pointer rounded-full hover:bg-orange-400 hover:bg-opacity-25"
                @click="tabRetourHistorique = !tabRetourHistorique">
                arrow_back
              </span>
              <div
                class="grid grid-cols-4 justify-items-stretch shadow p-2 bg-blue-400 bg-opacity-25 rounded-full w-full overflow-y-auto"
                v-for="(retourArticles, index) in retourSelected.Articles" :key="index">
                <div class="justify-self-auto flex mx-4">
                  <p class="text-gray-600">Qté :</p>
                  <input class="ml-2 px-0.5 focus:outline-2 outline-sky-300" style="width: 6vh" type="number"
                    :value="retourArticles.quantity" />
                  <!-- <p class="ml-2">{{ retourArticles.quantity }}</p> -->
                </div>
                <div class="justify-self-auto flex mx-4">
                  <p class="text-gray-600">Réf. :</p>
                  <p class="ml-2">{{ retourArticles.Ref_fournisseur }}</p>
                </div>
                <div class="justify-self-auto flex mx-4">
                  <p class="text-gray-600">Descr. :</p>
                  <p class="ml-2">{{ retourArticles.Description }}</p>
                </div>
                <div class="justify-self-auto flex mx-4">
                  <p class="text-gray-600">Marque :</p>
                  <p class="ml-2">{{ retourArticles.Code_marque }}</p>
                </div>
              </div>
            </div>
            <div class="py-2 -my-2 mt-3 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 hover:bg-white"
              style="height: 50vh" v-show="tabRetourHistorique">
              <div
                class="rounded-full min-w-full overflow-hidden align-middle hover:bg-blue-200 hover:text-white border border-gray-200 shadow">
                <table class="min-w-full">
                  <thead>
                    <tr v-for="(historiqueFile, index) in historique" :key="index" @click="attachRetour(historiqueFile)"
                      style="cursor: pointer" class="bg-white hover:bg-blue-500">
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                        {{ historiqueFile.Type }} N° {{ historiqueFile.Numero }}
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                        Date : {{ getFRDate(historiqueFile.Date) }}
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                        Client : {{ historiqueFile.Client }}
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                        Crée par : {{ historiqueFile.Name }}
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                        Prix Total :
                        {{ financial(historiqueFile.TotalPrice) }} €
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                        Desc : {{ historiqueFile.Note }}
                      </th>

                      <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button @click="
              (openRetour = false),
              (tabRetourHistorique = !tabRetourHistorique)
              "
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none">
              Fermer
            </button>
            <button @click="addRetour()" v-show="!tabRetourHistorique"
              class="cursor-pointer px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none">
              Valider
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Modal Retour -->

    <!-- modal historique  -->
    <div v-if="historiqueShow" :class="`modal ${!historiqueShow && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`">
      <div @click="historiqueShow = false" class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>

      <div class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 78rem">
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close">
          <svg class="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
            viewBox="0 0 18 18">
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Historique</p>
            <div class="z-50 cursor-pointer modal-close" @click="historiqueShow = false">
              <svg class="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                viewBox="0 0 18 18">
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div class="flex flex-row mt-3">
            <label>
              <input type="checkbox" class="w-5 h-5 text-blue-600 rounded-md focus:outline-2 outline-sky-300"
                name="radio" v-model="checkboxDevis" @change="filterHistoriqueFile('Devis', checkboxDevis)" /><span
                class="ml-2 text-gray-700">Devis</span>
            </label>
            <label>
              <input type="checkbox" class="w-5 ml-5 h-5 text-blue-600 rounded-md focus:outline-2 outline-sky-300"
                name="radio" v-model="checkboxFacturation" @change="
                  filterHistoriqueFile('Facturation', checkboxFacturation)
                  " /><span class="ml-2 text-gray-700">Facturation</span>
            </label>
          </div>
          <div class="py-2 -my-2 mt-3 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8" style="height: 30vh">
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
              <table class="min-w-full">
                <thead>
                  <tr v-for="(historiqueFile, index) in historique" :key="index" @click="
                    setHistoriqueFile(historiqueFile.Articles),
                    (historiqueShow = false)
                    " style="cursor: pointer" class="hover:bg-blue-50">
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                      {{ historiqueFile.Type }} N° {{ historiqueFile.Numero }}
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                      Date : {{ getFRDate(historiqueFile.Date) }}
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                      Client : {{ historiqueFile.Client }}
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                      Crée par : {{ historiqueFile.Name }}
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                      Prix Total : {{ financial(historiqueFile.TotalPrice) }} €
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                      Desc : {{ historiqueFile.Note }}
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                      Validation :
                      <span
                        class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                        v-if="historiqueFile.IsProcessed === true">
                        <span class="material-icons-outlined">
                          done
                        </span></span>
                      <span
                        class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full"
                        v-else>
                        <span class="material-icons-outlined">close</span>
                      </span>
                    </th>
                    <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
                  </tr>
                </thead>
              </table>
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button @click="historiqueShow = false"
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none">
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- fin modal historique -->

    <!-- modal ajout client -->
    <div v-if="showClientPar" :class="`modal ${!showClientPar && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`">
      <div @click="showClientPar = false" class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>

      <div class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 52rem">
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close">
          <svg class="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
            viewBox="0 0 18 18">
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Ajouter un client particulier</p>
            <div class="z-50 cursor-pointer modal-close" @click="showClientPar = false">
              <svg class="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                viewBox="0 0 18 18">
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div>
            <div class="hidden px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Professionnel ?</label>

              <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                <div class="pl-4 py-2 border-transparent rounded-md appearance-none">
                  <input class="focus:outline-2 outline-sky-300" disabled type="radio"
                    @click="professionnelParticulier = true" v-model="professionnelParticulier" value="true"
                    data-val="true" data-val-required="Ce champ est obligatoire." id="professionnelParticulier"
                    name="professionnelParticulier" data-enable="True" data-for-field="professionnelParticulier" />
                  <label class="pl-2 checkbox">Oui</label>
                </div>

                <div class="pl-12 py-2 border-transparent rounded-md appearance-none">
                  <input class="focus:outline-2 outline-sky-300" disabled type="radio"
                    @click="professionnelParticulier = false" v-model="professionnelParticulier" value="false"
                    id="professionnelParticulier" name="professionnelParticulier" data-enable="True"
                    data-for-field="professionnelParticulier" />
                  <label class="pl-2 checkbox">Non</label>
                </div>
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Nom</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input v-model="nomParticulier" type="text"
                  class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none" />
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Prénom</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input v-model="prenomParticulier" type="text"
                  class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none" />
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Adresse</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input v-model="adresseParticulier" type="text"
                  class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none" />
              </div>
            </div>

            <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Complément adresse</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <input v-model="complementParticulier" type="text"
                  class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none" />
              </div>
            </div>

            <div class="grid grid-cols-3">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Code postal</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input v-model="codepostalParticulier" type="text"
                    class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none" />
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Ville</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input v-model="villeParticulier" type="text"
                    class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none" />
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Pays</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input v-model="paysParticulier" type="text"
                    class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none" />
                </div>
              </div>
            </div>

            <div class="grid grid-cols-2">
              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">E-mail</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input v-model="emailParticulier" type="text"
                    class="lowercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none" />
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Téléphone</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input v-model="telephoneParticulier" type="text"
                    class="uppercase focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none" />
                </div>
              </div>
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end mt-4">
            <button @click="showClientPar = false"
              class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-blue-600 hover:text-white hover:text-blue-400 focus:outline-none">
              Fermer
            </button>
            <button @click="(showClientPar = false), postPartClients()"
              class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none">
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- fin modal ajout client -->

    <!-- Modal Détails dispo -->
    <div v-if="openRefSimil" :class="`modal ${!openRefSimil && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`">
      <div @click="openRefSimil = false" class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>

      <div style="max-width: 50rem"
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md">
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
          @click="openRefSimil = false">
          <svg class="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
            viewBox="0 0 18 18">
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Remises fournisseurs</p>
            <div class="z-50 cursor-pointer modal-close" @click="openRefSimil = false">
              <svg class="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                viewBox="0 0 18 18">
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
              </svg>
            </div>
          </div>

          <!--Body-->
          <table class="min-w-full mt-4">
            <thead class="tableHEADTR">
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                  Qté limite inférieure
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                  Qté limite supérieure
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                  Prix
                </th>
              </tr>
            </thead>

            <tbody class="bg-white w-fit" v-if="PrixLot[indexRemise]">
              <tr v-for="(r, index) in PrixLot[indexRemise]" :key="index" class="tableHEADTR">
                <td
                  class="bg-white px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH">
                  <div class="text-center">{{ r.$.QtyLowerLimit }} pcs</div>
                </td>

                <td
                  class="bg-white px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH">
                  <div class="text-center">{{ r.$.QtyUpperLimit }} pcs</div>
                </td>

                <td
                  class="bg-white px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH">
                  <div class="text-center">{{ financial(r.$.Price) }} €</div>
                </td>
              </tr>
            </tbody>
          </table>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button @click="openRefSimil = false"
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none">
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Modal Détails dispo -->

    <!-- Modal Détails dispo -->
    <div v-if="openDetailsDispo" :class="`modal ${!openDetailsDispo && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`">
      <div @click="openDetailsDispo = false" class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>

      <div style="max-width: 50rem"
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md">
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
          @click="openDetailsDispo = false">
          <svg class="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
            viewBox="0 0 18 18">
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Disponibilité</p>
            <div class="z-50 cursor-pointer modal-close" @click="openDetailsDispo = false">
              <svg class="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                viewBox="0 0 18 18">
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
              </svg>
            </div>
          </div>

          <!--Body-->
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Stock VHIP
                </th>
                <!-- <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Equivalent A
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Equivalent B
                </th> -->
              </tr>
            </thead>
            <tbody v-if="tabDispoUAN[indexDelaisLivraison]">
              <tr v-for="(a, index) in tabDispoUAN[indexDelaisLivraison][0].Depot" :key="index">
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  Livraison {{ a.typeLivraison }}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="bg-sky-500 px-1 w-fit">
                    {{
                      a.qtyDepot +
                      "pcs " +
                      a.dateLivraison +
                      " à " +
                      a.heureLivraison
                    }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button @click="openDetailsDispo = false"
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none">
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Modal Détails dispo -->

    <!-- Modal Ref Article -->
    <div v-if="openRefArticle" :class="`modal ${!openRefArticle && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`">
      <div @click="openRefArticle = false" class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>

      <div style="max-width: 50rem"
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md">
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
          @click="openRefArticle = false">
          <svg class="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
            viewBox="0 0 18 18">
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Références</p>
            <div class="z-50 cursor-pointer modal-close" @click="openRefArticle = false">
              <svg class="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                viewBox="0 0 18 18">
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
              </svg>
            </div>
          </div>

          <!--Body-->
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Réf
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Marque
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Prix
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Stock
                </th>
              </tr>
            </thead>
            <tbody v-if="RefSim[indexRef]">
              <tr v-for="(ref, index) in RefSim[indexRef]" :key="index">
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="text-sm leading-5 text-gray-900">
                    {{ ref.article[0].Ref_fournisseur }}
                  </div>
                  <div class="text-sm leading-5 text-gray-900">
                    {{ ref.article[0].Description }}
                  </div>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  <div class="text-sm leading-5 text-gray-900">
                    {{ ref.article[0].Code_marque }}
                  </div>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                  {{ financial(ref.article[0].Prix_euro) }} €
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap" v-if="ref.stock.length > 0">
                  {{ ref.stock[0].stock }}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap" v-else>
                  0
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap" v-if="prixtab[index] != 'NOK'">
                  <span class="material-icons-round cursor-pointer ml-4 text-blue-600" @click="
                    searchTranslatedDirectAdd({
                      artNum: ref.article[0].Ref_fournisseur,
                      plateform: this.user.proprietaire,
                      quantityAdded: 1,
                    }),
                    (openRefArticle = false),
                    success()
                    ">
                    add_shopping_cart
                  </span>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap" v-else>
                  --
                </td>
              </tr>
            </tbody>
          </table>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button @click="openRefArticle = false"
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none">
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Modal Ref Article -->

    <!-- Modal recap cmd vanheck -->
    <div v-if="openRecapVH" :class="`modal ${!openRecapVH && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`">
      <div v-if="detailRecapVH" @click="openRecapVH = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>
      <div v-else @click="(openRecapVH = false), getDoc()"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>

      <div style="max-width: 50rem"
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md">
        <div v-if="detailRecapVH"
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
          @click="openRecapVH = false">
          <svg class="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
            viewBox="0 0 18 18">
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <div v-else
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
          @click="(openRecapVH = false), getDoc()">
          <svg class="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
            viewBox="0 0 18 18">
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold" v-show="detailRecapVH">
              Récapitulatif de la commande
            </p>
            <div v-if="detailRecapVH" class="z-50 cursor-pointer modal-close" @click="openRecapVH = false">
              <svg class="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                viewBox="0 0 18 18">
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
              </svg>
            </div>

            <div v-else class="z-50 cursor-pointer modal-close" @click="(openRecapVH = false), getDoc()">
              <svg class="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                viewBox="0 0 18 18">
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
              </svg>
            </div>
          </div>

          <!--Body-->

          <div class="flex justify-end mt-4 space-x-4" v-show="!commandState" v-if="VH_a02Tab.length > 0">
            <div class="flex space-x-4">
              <div class="p-2 bg-sky-500 text-white rounded shadow-lg hover:bg-sky-300 cursor-pointer"
                @click="changeDepot('STANDARD', -1, plateform.grossierID)">
                Standard
              </div>
              <div v-for="(depotFound, indexdepot) in depotTab" :key="indexdepot">
                <div class="p-2 bg-sky-500 text-white rounded shadow-lg hover:bg-sky-300 cursor-pointer" @click="
                  changeDepot(depotFound.name, indexdepot, depotFound.code)
                  ">
                  {{ depotFound.name }}
                </div>
              </div>
            </div>
          </div>

          <div class="flex space-x-1 justify-center" v-show="!commandState">
            <div class="py-2 mt-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8" v-show="detailRecapVH">
              <div
                class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                <table class="min-w-full" style="max-height: 18vh">
                  <thead>
                    <tr>
                      <th
                        class="px-2 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                        Ref
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                        Description
                      </th>
                      <th style="width: 3%"
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                        Qté
                      </th>
                      <th style="width: 10%"
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                        Dispo
                      </th>
                      <th style="width: 5%"
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                        Prix
                      </th>
                      <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
                    </tr>
                  </thead>

                  <tbody class="bg-white">
                    <tr v-for="(line, index) in VH_a02Tab[indexDepot].lines" :key="index">
                      <td class="px-2 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                        {{ line.B1_Artikelnummer[0] }}
                        <span v-show="!loadingCommand"
                          class="inline-flex px-2 text-xs font-semibold leading-5 text-red-500 rounded-full"
                          style="cursor: pointer" @click="deleteLine(index)">
                          <span class="material-icons-round bg-red-500 rounded-sm text-white" style="font-size: small">
                            clear
                          </span>
                        </span>
                      </td>

                      <td class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                        {{ line.B1_Omschrijving[0] }}
                      </td>

                      <td style="width: 3%"
                        class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                        <input v-show="!loadingCommand" type="number" v-model="qtyCmdVH[index]"
                          class="border rounded-md focus:outline-2 outline-sky-300" style="width: 4vh" :placeholder="parseInt(line.B1_LA_Aantal_response[0]) +
                            parseInt(line.B1_LB_Aantal_response[0])
                            " @keyup.enter="
                              getDispoCmdVH(
                                this.VH_articleTab[index].Ref_fournisseur,
                                this.VH_articleTab[index]
                                  .Code_famille_fournisseur,
                                qtyCmdVH[index],
                                index
                              )
                              " />
                        <span v-show="loadingCommand">
                          {{
                            parseInt(line.B1_LA_Aantal_response[0]) +
                            parseInt(line.B1_LB_Aantal_response[0])
                          }}
                        </span>
                      </td>

                      <td v-if="showDispoCmdVH[index]"
                        class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH">
                        <div v-if="tabDispoUAN[index]"
                          class="rounded p-1 flex flex-col text-xs px-6 space-y-1 cursor-pointer"
                          @click="openDetailsDispo = true">
                          <div v-for="(a, index) in tabDispoUAN[index][0].Depot" :key="index">
                            <div v-if="a.typeLivraison == 'SameDay 1'">
                              <div style="width: 55%" class="flex border border-yellow-500 p-0.5 rounded shadow">
                                <span class="material-icons-outlined text-yellow-500"
                                  style="font-size: 15px">light_mode</span>
                                <span class="text-xs text-black">
                                  {{ a.qtyDepot }}
                                </span>
                              </div>
                            </div>

                            <div v-else-if="a.typeLivraison == 'VOSS nacht'">
                              <div style="width: 55%" class="flex border border-blue-700 p-0.5 rounded shadow">
                                <span class="material-icons-outlined text-blue-700"
                                  style="font-size: 15px">nightlight</span>
                                <span class="text-xs text-black">
                                  {{ a.qtyDepot }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td v-else style="width: 10%"
                        class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                        <div class="space-y-1">
                          <div v-if="line.B1_LA_DatumTijd[0]" class="flex">
                            <div class="flex border border-blue-700 p-0.5 rounded shadow">
                              <span class="material-icons-outlined text-blue-700"
                                style="font-size: 15px">nightlight</span>
                              <span class="text-xs text-black">
                                {{ line.B1_LA_Aantal_response[0] }}
                              </span>
                            </div>
                            <div class="ml-2">
                              le {{ line.B1_LA_DatumTijd[0] }}
                            </div>
                          </div>

                          <div v-if="parseInt(line.B1_LB_Aantal_response[0]) > 0" class="flex">
                            <div v-if="line.B1_LB_DatumTijd[0]"
                              class="flex border border-yellow-500 p-0.5 rounded shadow">
                              <span class="material-icons-outlined text-yellow-500"
                                style="font-size: 15px">light_mode</span>
                              <span class="text-xs text-black">
                                {{ line.B1_LB_Aantal_response[0] }}
                              </span>
                            </div>
                            <div class="ml-2">
                              le {{ line.B1_LB_DatumTijd[0] }}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td style="width: 5%"
                        class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                        <div class="flex flex-col">
                          <div>
                            {{ parseFloat(line.B1_Brutoprijs[0]).toFixed(2) }} €
                          </div>

                          <div class="flex flex-col" v-if="parseFloat(line.B1_Korting[0]) > 0">
                            <span class="text-gray-800 text-xs">PA :
                              {{
                                parseFloat(
                                  parseFloat(line.B1_Brutoprijs[0]) -
                                  parseFloat(line.B1_Brutoprijs[0]) *
                                  (parseFloat(line.B1_Korting[0]) / 100)
                                ).toFixed(2)
                              }}
                              €</span>
                            <span class="text-gray-800 text-xs">( {{ line.B1_Korting[0] }} % )</span>
                          </div>

                          <span class="text-gray-800 text-xs" v-else-if="line.B1_Nettoprijs[0]">PA : {{
                            line.B1_Nettoprijs[0] }} €</span>

                          <span class="text-gray-800 text-xs" v-else>Pas de remise</span>

                          <span class="text-gray-800 text-xs" v-if="parseFloat(line.B1_Korting[0]) == 0">(prix
                            net)</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="flex justify-center" v-show="cmdEncaissementVH">
            <div class="flex flex-col border-t" v-show="!commandState">
              <p class="text-2xl font-bold text-red-600">
                Voulez-vous passer la commande ?
              </p>
              <div class="flex flex-col">
                <div class="flex justify-center space-x-6 text-xl text-white">
                  <div class="cursor-pointer mb-1 py-1 px-2 rounded-md bg-sky-400 hover:bg-sky-300" @click="getCmdVH()">
                    <span class="">oui</span>
                  </div>
                  <div id="select" class="cursor-pointer mb-1 py-1 px-2 rounded-md bg-red-400 hover:bg-red-300"
                    @click="(openRecapVH = false), getDoc()">
                    <span class="">non</span>
                  </div>
                </div>
                <div class="mt-6 flex flex-row space-x-4 justify-center items-center" v-show="loadingCommand">
                  <span>Commande en cours...</span>
                  <PulseLoader color="#3b82f6" v-show="true" />
                </div>
              </div>
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button v-if="detailRecapVH" @click="openRecapVH = false"
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none">
              Fermer
            </button>
            <button v-else @click="(openRecapVH = false), getDoc()"
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none">
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin modal récap cmd vanheck -->

    <!-- FIN MODALS -->

    <!-- BODY -->
    <div class="flex -ml-2 space-x-1">
      <!-- début partie outils -->
      <div class="flex flex-col space-y-8">
        <div class="flex flex-col space-y-4 bg-white bg-opacity-50 rounded-md py-1 px-0.5 h-fit">
          <div class="flex space-x-6 m-auto">
            <button @click="showSaisieChaud = !showSaisieChaud" @mouseover="showNameSaiseChaud = true"
              @mouseleave="showNameSaiseChaud = false"
              class="relative material-icons-outlined bg-sky-500 hover:bg-sky-400 text-white shadow rounded-md px-0.5 cursor-pointer z-10">
              drive_file_rename_outline
            </button>
            <div v-show="showNameSaiseChaud" style="font-size: 12px"
              class="absolute rounded-tr-full rounded-br-full m-auto px-1 mt-1 ml-1 bg-sky-400 text-white z-10">
              Saisie rapide
            </div>
          </div>
          <div class="flex space-x-6 m-auto">
            <button @click="
              (showSaisieComment = !showSaisieComment),
              (showInputSaisieComment = [])
              " @mouseover="showNameSaiseComment = true" @mouseleave="showNameSaiseComment = false"
              class="relative material-icons-outlined bg-sky-500 hover:bg-sky-400 text-white shadow rounded-md px-0.5 cursor-pointer z-10">
              rate_review
            </button>
            <div v-show="showNameSaiseComment" style="font-size: 12px"
              class="absolute rounded-tr-full rounded-br-full m-auto px-1 mt-1 ml-1 bg-sky-400 text-white z-10">
              Saisie commentaire
            </div>
          </div>
        </div>
      </div>
      <!-- fin partie outils -->

      <div>
        <div class="flex flex-col">
          <div class="flex flex-row justify-between">
            <!-- second select -->
            <div class="flex flex-row sm:flex-row">
              <div class="flex">
                <div class="relative">
                  <div style="width: 25em"
                    class="block w-full bg-green-200 focus:outline-2 outline-sky-300 py-2 pl-6 pr-2 text-sm text-gray-900 font-bold placeholder-gray-400 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:bg-gray-300 focus:placeholder-gray-600 focus:text-gray-700 hover:bg-white">
                    {{ documentData.Client }}
                  </div>
                </div>
              </div>

              <div
                class="w-40 h-8 ml-8 mt-1 flex rounded-md px-2 text-xs text-black bg-gray-100 border border-sky-400 border-opacity-25 shadow-sm">
                <span class="material-icons-outlined py-1 text-sky-400">call</span>
                <span class="m-auto px-2" v-if="documentData.clientInfo">{{
                  documentData.clientInfo[0].telephone
                }}</span>
              </div>

              <div
                class="w-32 h-8 ml-8 mt-1 flex rounded-md px-2 text-xs text-black bg-gray-100 border border-sky-400 border-opacity-25 shadow-sm">
                <span class="m-auto text-sky-400 text-left">Franco :</span>
                <span class="m-auto" v-if="documentData.clientInfo">{{ documentData.clientInfo[0].francodeport }}
                  €</span>
              </div>
            </div>

            <div class="flex flex-row">
              <div class="relative flex flex-row">
                <div
                  class="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r appearance-none focus:outline-2 outline-sky-300">
                  {{ documentData.type }} n° {{ documentData.Numero }}
                </div>
              </div>
              <button :disabled="loadingModify" :class="loadingModify
                ? 'bg-blue-400'
                : 'bg-blue-500 hover:bg-blue-400'
                " class="px-6 py-2 ml-3 font-medium tracking-wide text-white rounded-md focus:outline-none"
                @click="modifierModal = true">
                Valider
              </button>
              <button
                class="px-6 py-2 ml-3 font-medium tracking-wide text-white bg-red-500 rounded-md hover:bg-blue-400 focus:outline-none"
                @click="showModal = true">
                Supprimer BL en cours
              </button>
            </div>
          </div>
        </div>

        <div class="tableHEADTR relative block mt-1 sm:mt-0">
          <input v-on:keyup.enter="
            ((showAjoutArticle = false), (showArticleList = true)),
            getOneArticle()
            " v-model="articleSearch" @input="autoComplete(articleSearch)" placeholder="Entrez un code article"
            class="block focus:outline-2 outline-sky-300 mt-4 w-full py-2 pl-6 pr-12 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r appearance-none focus:bg-white focus:placeholder-gray-600 focus:text-gray-700" />
          <ul v-show="showAutoComplete"
            class="absolute z-20 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <li class="bg-white px-4 hover:bg-gray-300 cursor-pointer" v-for="(auto, index) in autoCompleteTab"
              :key="index" @click="setAutoComplete(auto.Ref_fournisseur)">
              {{ auto.Ref_fournisseur }}
            </li>
            <div class="flex justify-end bg-white">
              <span
                class="material-icons-outlined rounded-full p-0.5 text-red-600 hover:bg-red-400 hover:bg-opacity-25 cursor-pointer"
                @click="showAutoComplete = false">close</span>
            </div>
          </ul>
          <ul v-show="showArticleList"
            class="absolute z-20 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <!-- Table pour trouver un produit -->
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    Réf./Equipe.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    Descr./Fourn.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    Stock
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    P.P.C.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    P.V.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    Remise
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    Px final
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    Total
                  </th>
                  <th
                    class="py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    <button class="cursor-pointer" @click="showArticleList = false">
                      <span
                        class="material-icons-outlined rounded-full p-0.5 text-red-600 hover:bg-red-400 hover:bg-opacity-25">close</span>
                    </button>
                  </th>
                </tr>
              </thead>

              <tbody class="bg-white" v-if="comptoirsearchtab.length > 0">
                <tr v-for="(csArticle, index) in comptoirsearchtab[0].article" :key="index" @click="
                  handlerArticle(csArticle, comptoirsearchtab[0].stockData)
                  " class="bg-green-300 hover:bg-opacity-25 cursor-pointer" style="max-height: 10px">
                  <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                    <div class="text-sm leading-5 text-gray-900">
                      {{ csArticle.Ref_fournisseur }}
                    </div>
                    <div class="text-sm leading-5 text-gray-900">
                      {{ csArticle.Code_marque }}
                    </div>
                  </td>

                  <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                    <div class="text-sm leading-5 text-gray-500">
                      {{ csArticle.Description }}
                    </div>
                    <div class="text-sm leading-5 bg-blue-500 text-white p-1 rounded w-fit">
                      {{ csArticle.Prefixe_tarif }}
                    </div>
                  </td>

                  <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                    <span v-if="comptoirsearchtab[0].stockData.length > 0">
                      {{ comptoirsearchtab[0].stockData[0].stock }}
                    </span>
                    <span v-else>0</span>
                  </td>

                  <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                    0 €
                  </td>

                  <td class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                    {{ financial(csArticle.Prix_euro) }} €
                  </td>
                  <td class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                    0 %
                  </td>
                  <td class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                    {{ financial(csArticle.Prix_euro) }} €
                  </td>
                  <td class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                    {{ financial(csArticle.Prix_euro) }} €
                  </td>
                  <td class="w-12 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap">
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                  </th>
                  <th
                    class="px-6 py-3 text-xs leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                    <span style="margin-left: -100px"
                      class="cursor-pointer px-2 py-1 mt-3 text-xs hover:bg-opacity-25 hover:text-white tracking-wide text-white bg-blue-500 rounded-md hover:bg-blue-500 focus:outline-none"
                      @click="
                        (showAjoutArticle = true), (showArticleList = false)
                        ">
                      Ajouter un article
                    </span>
                  </th>
                </tr>
              </thead>
            </table>
            <!-- Fin Table pour trouver un produit -->
          </ul>

          <!-- Table ajout article -->

          <ul v-show="showAjoutArticle"
            class="absolute z-20 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    EAN
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    Réf./Equipe.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    Descr./Fourn.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    Qté
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    P.V.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    Code
                  </th>
                  <th
                    class="py-3 text-xs font-medium leading-4 tracking-wider text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    <button class="cursor-pointer" style="margin-left: 32px" @click="showAjoutArticle = false">
                      <span
                        class="material-icons-outlined rounded-full p-0.5 text-red-600 hover:bg-red-400 hover:bg-opacity-25">close</span>
                    </button>
                  </th>
                </tr>
              </thead>

              <tbody class="bg-white">
                <tr>
                  <td class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap">
                    <div class="text-sm leading-5 text-gray-900">
                      <input v-model="ajoutCode_ean" class="border px-1 py-0.5 focus:outline-2 outline-sky-300"
                        type="text" placeholder="Code-barres" />
                    </div>
                  </td>
                  <td class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap">
                    <div class="text-sm leading-5 text-gray-900">
                      <input v-model="ajoutReference" class="border px-1 py-0.5 focus:outline-2 outline-sky-300"
                        type="text" placeholder="Réf." />
                    </div>
                    <div class="text-sm leading-5 text-gray-900">
                      <input v-model="ajoutMarque" class="border px-1 py-0.5 focus:outline-2 outline-sky-300"
                        type="text" placeholder="Marque" />
                    </div>
                  </td>
                  <td class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap">
                    <div class="text-sm leading-5 text-gray-500">
                      <input v-model="ajoutDescription" class="border px-1 py-0.5 focus:outline-2 outline-sky-300"
                        type="text" placeholder="Description" />
                    </div>
                    <div class="text-sm leading-5 text-gray-500">
                      <select v-model="ajoutFournisseur" class="border px-1 py-0.5 focus:outline-2 outline-sky-300">
                        <option v-for="(c, index) in fournisseurList" :key="index" :value="c">
                          {{ c.CompanyName }}
                        </option>
                      </select>
                    </div>
                  </td>
                  <td class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap">
                    <input v-model="ajoutQuantite" style="width: 90%"
                      class="border px-1 py-0.5 focus:outline-2 outline-sky-300" type="number" placeholder="" />
                  </td>
                  <td class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap">
                    <div class="flex flex-row space-x-2">
                      <button class="bg-blue-500 text-white p-1 rounded shadow-sm" @click="showAjoutRemise = true">
                        Remise
                      </button>
                      <button class="bg-blue-500 text-white p-1 rounded shadow-sm" @click="showAjoutRemise = false">
                        PrixNet
                      </button>
                    </div>
                    <input v-model="ajoutPrix" style="width: 90%"
                      class="border mt-1 px-1 py-0.5 focus:outline-2 outline-sky-300" type="text" placeholder="PPC" />
                    €
                    <div v-if="showAjoutRemise">
                      <input v-model="ajoutCodeRemise" style="width: 90%"
                        class="border px-1 py-0.5 focus:outline-2 outline-sky-300" type="text"
                        placeholder="Code Remise" />
                    </div>
                    <div class="flex flex-col" v-else>
                      <input v-model="ajoutPA" style="width: 90%"
                        class="border px-1 py-0.5 focus:outline-2 outline-sky-300" type="text" placeholder="PA" />
                      <input v-model="ajoutPNT1" style="width: 90%"
                        class="border px-1 py-0.5 focus:outline-2 outline-sky-300" type="text" placeholder="PNT1" />
                      <input v-model="ajoutPNT2" style="width: 90%"
                        class="border px-1 py-0.5 focus:outline-2 outline-sky-300" type="text" placeholder="PNT2" />
                      <input v-model="ajoutPNT3" style="width: 90%"
                        class="border px-1 py-0.5 focus:outline-2 outline-sky-300" type="text" placeholder="PNT3" />
                    </div>
                  </td>
                  <td class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap">
                    <div class="text-sm leading-5 text-gray-500">
                      <input v-model="ajoutCode_famille" class="border px-1 py-0.5 focus:outline-2 outline-sky-300"
                        type="text" placeholder="Code famille" />
                    </div>
                    <div class="text-sm leading-5 text-gray-500">
                      <input v-model="ajoutCode_sous_famille" class="border px-1 py-0.5 focus:outline-2 outline-sky-300"
                        type="text" placeholder="Code sous famille" />
                    </div>
                  </td>
                  <td class="py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"></td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  </th>
                  <th
                    class="cursor-pointer px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                    <button
                      class="cursor-pointer px-6 py-2 mt-3 hover:bg-opacity-25 hover:text-white tracking-wide text-white bg-blue-500 rounded-md hover:bg-blue-500 focus:outline-none"
                      @click="
                        postAjoutArticle(),
                        getAjoutArticle(),
                        (showAjoutArticle = false)
                        ">
                      Valider
                    </button>
                  </th>
                </tr>
              </thead>
            </table>
          </ul>
          <!-- Fin Table ajout article -->

          <div class="absolute mt-4 inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
            <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>

        <div class="flex justify-end mr-7 mt-1"></div>
        <div class="flex flex-col mt-2">
          <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg h-full">
              <table class="min-w-full">
                <thead class="tableHEADTR">
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                      Réf./Equipe.
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                      Stock
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                      <span>Descr./Fourn.</span>
                      <span @click="getDispos()" style="font-size: 17px"
                        class="material-icons-outlined p-0.5 text-blue-500 cursor-pointer hover:animate-spin hover:bg-gray-100 rounded-full">cloud_sync</span>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                      Qté
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                      P.V.
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                      Remise
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                      Px final
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white tableBODY">
                  <tr v-for="(articleAct, index) in documentData.Articles" :key="index" class="tableHEADTR">
                    <td class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH">
                      <div class="flex space-x-2">
                        <div class="flex flex-col">
                          <div class="flex flex-row items-center">
                            <div class="space-x-1 flex text-sm leading-5 text-gray-900">
                              <span v-if="articleAct.Prefixe_tarif == 'Vhi'"
                                class="material-icons-outlined m-auto cursor-pointer text-orange-500 hover:bg-gray-100 rounded hover:shadow hover:animate-spin"
                                style="font-size: 11px" @click="
                                  getRefSimilaire(
                                    articleAct.Ref_fournisseur,
                                    index
                                  ),
                                  (openRefArticle = true)
                                  ">
                                sync
                              </span>
                              <span>{{ articleAct.Ref_fournisseur }}</span>
                            </div>
                          </div>
                          <div class="text-sm leading-5 text-gray-900">
                            {{ articleAct.Code_marque }}
                          </div>
                        </div>

                        <div class="flex flex-col space-y-1 mt-1">
                          <span v-if="articleAct.Promo_set"
                            class="material-icons-outlined animate-pulse text-orange-500 cursor-pointer"
                            style="font-size: 15px" @click="
                              openPromo(
                                articleAct.Ref_fournisseur,
                                articleAct.Code_marque,
                                index
                              )
                              ">
                            discount
                          </span>
                          <span v-if="
                            articleAct.Prefixe_tarif == 'Vhi' &&
                            articleAct.PrixLotVH
                          " class="material-icons-outlined animate-pulse text-orange-500 cursor-pointer"
                            style="font-size: 15px" @click="
                              getPrixLot(articleAct.Ref_fournisseur, index),
                              (openRefSimil = true)
                              ">
                            auto_graph
                          </span>
                        </div>
                      </div>
                    </td>

                    <td class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none">
                      <div class="flex space-x-4">
                        <div class="flex flex-col w-12 text-xs border border-gray-300 p-0.5 rounded-full">
                          <div class="flex space-x-1 m-auto">
                            <span class="text-green-600 m-auto" v-if="articleAct.stockData">{{ articleAct.stockData
                              }}</span>

                            <span class="text-red-600 m-auto" v-else>0</span>
                          </div>
                        </div>
                        <div v-if="articleAct.Prefixe_tarif == 'Vhi'">
                          <span class="flex flex-row space-x-2" v-if="
                            articleAct.stockData - articleAct.quantity < 0
                          ">
                            <span class="material-icons-round text-red-500 text-base">
                              priority_high
                            </span>
                            <span class="material-icons-round text-blue-500 text-base cursor-pointer"
                              v-show="!VH_cmd_state[index]" @click="
                                addToCommandVH(
                                  articleAct.Ref_fournisseur,
                                  articleAct.quantity - articleAct.stockData,
                                  index
                                )
                                ">
                              add_shopping_cart
                            </span>
                            <span class="material-icons-round animate-spin text-orange-500 text-base"
                              v-show="VH_cmd_Loading[index]">
                              published_with_changes
                            </span>
                            <span class="material-icons-round text-green-500 text-base" v-show="VH_cmd_state[index]">
                              published_with_changes
                            </span>
                          </span>
                          <span v-else><span class="material-icons-round text-green-500 text-base">
                              check_circle
                            </span></span>
                        </div>
                      </div>
                    </td>

                    <td class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH" style="overflow: none">
                      <div class="text-sm leading-5">
                        {{ articleAct.Description }}
                      </div>
                      <div class="text-sm leading-5 text-gray-900">
                        <span class="text-xs text-blue-500">{{
                          articleAct.FournisseurSelected
                        }}</span>

                        <span v-if="articleAct.Prefixe_tarif == 'Vhi'" @click="
                          (showDispo[index] = true),
                          getDispo(
                            articleAct.Ref_fournisseur,
                            articleAct.Code_famille_fournisseur,
                            articleAct.quantity,
                            index
                          )
                          " style="font-size: 15px"
                          class="material-icons-outlined p-0.5 text-blue-500 cursor-pointer hover:animate-spin hover:bg-gray-100 rounded-full">cloud_sync</span>

                        <button v-show="showSaisieComment" @click="
                        (showInputSaisieComment[index] =
                          !showInputSaisieComment[index]),
                          (articleAct.commentaire = '')
                          " style="font-size: 15px"
                          class="material-icons-outlined ml-8 p-0.5 text-white cursor-pointer bg-orange-300 hover:bg-orange-400 rounded-full">
                          rate_review
                        </button>
                      </div>
                      <textarea v-show="showInputSaisieComment[index]" v-model="articleAct.commentaire" rows="2"
                        cols="40" class="border px-1 py-0.5 focus:outline-2 outline-sky-300 text-xs"></textarea>
                    </td>

                    <td v-if="showDispo[index]"
                      class="items-center px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH">
                      <div v-if="tabDispoUAN[index]"
                        class="rounded p-1 flex flex-col text-xs px-6 space-y-1 cursor-pointer"
                        @click="openDetailsDispo = true">
                        <div v-for="(a, index) in tabDispoUAN[index][0].Depot" :key="index">
                          <div v-if="a.typeLivraison == 'SameDay 1'">
                            <div style="width: 55%" class="flex border border-yellow-500 p-0.5 rounded shadow">
                              <span class="material-icons-outlined text-yellow-500"
                                style="font-size: 15px">light_mode</span>
                              <span class="text-xs text-black">
                                {{ a.qtyDepot }}
                              </span>
                            </div>
                          </div>

                          <div v-else-if="a.typeLivraison == 'VOSS nacht'">
                            <div style="width: 55%" class="flex border border-blue-700 p-0.5 rounded shadow">
                              <span class="material-icons-outlined text-blue-700"
                                style="font-size: 15px">nightlight</span>
                              <span class="text-xs text-black">
                                {{ a.qtyDepot }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td v-else class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"></td>

                    <td class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH" @keyup.enter="
                      retourArticles(
                        documentData.Articles[index].quantity,
                        articleAct
                      )
                      ">
                      <div class="flex flex-row items-center space-x-1">
                        <input class="border rounded border-gray-400 focus:outline-2 outline-sky-300"
                          v-model="documentData.Articles[index].quantity" style="width: 35%" type="number"
                          :placeholder="articleAct.Qte_mini" />

                        <span class="inline-flex px-2 h-fit text-xs font-semibold leading-5 text-red-500 rounded-full"
                          style="cursor: pointer" @click="
                            deleteArticle(index),
                            (showDispo[index] = false),
                            (tabDispoUAN[index] = false),
                            suppVHloadingstate({ index: index })
                            ">
                          <span
                            class="material-icons-round bg-red-500 hover:bg-opacity-25 rounded-sm text-white cursor-pointer"
                            style="font-size: small">
                            clear
                          </span>
                        </span>
                      </div>
                    </td>

                    <td class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH">
                      <span class="cursor-pointer" v-if="articleAct.PrixFournisseurType != 'prixnet'"
                        @mouseover="showPA[index] = true" @mouseleave="showPA[index] = false"><input type="Number"
                          class="w-3/5" v-model="documentData.Articles[index].Prix_euro" />€</span>
                      <span class="cursor-pointer" v-else @mouseover="showPA[index] = true"
                        @mouseleave="showPA[index] = false"><input class="w-3/5" type="Number"
                          v-model="documentData.Articles[index].Prix_euro" />
                        €</span>
                      <div class="absolute mt-2 bg-gray-200 p-2 rounded shadow-lg z-20" v-show="showPA[index]">
                        <div class="text-sm leading-2 text-blue-500">
                          <span v-if="articleAct.PrixFournisseurType != 'prixnet'">PA :
                            {{
                              financial(
                                articleAct.Prix_euro -
                                articleAct.Prix_euro *
                                (articleAct.remise / 100)
                              )
                            }}
                            €</span>
                          <span v-else>PA :
                            {{ financial(articleAct.PNF) }}
                            €</span>
                        </div>
                      </div>
                    </td>

                    <td class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH">
                      <span class="text-sm">{{
                        articleAct.PrixFournisseurType
                      }}</span>
                      <div class="flex text-sm leading-5 text-gray-900" @mouseover="showMarge[index] = true"
                        @mouseleave="showMarge[index] = false">
                        <div class="flex">
                          <input v-model="documentData.Articles[index].remise"
                            v-if="articleAct.PrixFournisseurType != 'prixnet'"
                            class="border rounded border-gray-400 focus:outline-2 outline-sky-300" type="number"
                            style="width: 100%" placeholder="0" min="0" max="100" maxlength="3" step="5" />
                          <span v-if="articleAct.PrixFournisseurType != 'prixnet'">%</span>
                        </div>
                      </div>
                      <div class="absolute mt-2 bg-gray-200 p-2 rounded shadow-lg z-20" v-show="showMarge[index]">
                        <div class="text-sm leading-2 text-blue-500" v-if="articleAct.PrixFournisseurType != 'prixnet'">
                          Marge :
                          {{
                            getMarge(
                              articleAct.Prix_euro,
                              articleAct.Prix_euro -
                              articleAct.Prix_euro *
                              (tempRemiseFourn[index] / 100),
                              documentData.Articles[index].remise,
                              articleAct.PrixFournisseurType
                            )
                          }}
                          %
                        </div>
                        <div class="text-sm leading-2 text-blue-500" v-else>
                          Marge :
                          {{
                            getMarge(
                              articleAct.Prix_euro,
                              articleAct.PNF,
                              0,
                              articleAct.PrixFournisseurType
                            )
                          }}
                          %
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-6 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap tableTDTH">
                      <div class="flex space-x-1 text-sm leading-5 text-gray-900" @mouseover="showTTC1[index] = true"
                        @mouseleave="showTTC1[index] = false">
                        <span v-if="articleAct.PrixFournisseurType != 'prixnet'">
                          {{
                            financial(
                              articleAct.Prix_euro -
                              articleAct.Prix_euro * (articleAct.remise / 100)
                            )
                          }}
                        </span>
                        <span v-else>
                          <span><input class="w-3/5" type="Number" v-model="articleAct.Prix_vente" /></span>
                        </span>

                        <span>€</span>
                      </div>
                      <div class="absolute mt-2 bg-gray-200 p-2 rounded shadow-lg z-20" v-show="showTTC1[index]">
                        <div class="text-sm leading-2 text-blue-500">
                          <span>
                            TTC :
                            {{
                              financial(
                                (articleAct.Prix_euro -
                                  articleAct.Prix_euro *
                                  (articleAct.remise / 100)) *
                                0.2 +
                                (articleAct.Prix_euro -
                                  articleAct.Prix_euro *
                                  (articleAct.remise / 100))
                              )
                            }}
                          </span>
                          <span>€</span>
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH">
                      <div class="flex space-x-1 text-sm leading-5 text-gray-900" @mouseover="showTTC2[index] = true"
                        @mouseleave="showTTC2[index] = false">
                        <span v-if="articleAct.PrixFournisseurType != 'prixnet'">
                          {{
                            financial(
                              (articleAct.Prix_euro -
                                articleAct.Prix_euro *
                                (articleAct.remise / 100)) *
                              articleAct.quantity
                            )
                          }}
                        </span>
                        <span v-else>
                          {{
                            financial(
                              articleAct.Prix_vente * articleAct.quantity
                            )
                          }}</span>
                        <span>€</span>
                      </div>
                      <div class="absolute mt-2 bg-gray-200 p-2 rounded shadow-lg z-20" v-show="showTTC2[index]">
                        <div class="text-sm leading-2 text-blue-500">
                          <span>
                            TTC :
                            {{
                              financial(
                                (articleAct.Prix_euro -
                                  articleAct.Prix_euro *
                                  (articleAct.remise / 100)) *
                                articleAct.quantity *
                                0.2 +
                                (articleAct.Prix_euro -
                                  articleAct.Prix_euro *
                                  (articleAct.remise / 100)) *
                                articleAct.quantity
                              )
                            }}
                          </span>
                          <span>€</span>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <!-- saisie rapide -->
                  <tr class="tableHEADTR shadow-lg border border-gray-100 rounded-lg" v-show="showSaisieChaud">
                    <td class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH">
                      <div class="relative flex flex-col">
                        <input class="border px-1 py-0.5 focus:outline-2 outline-sky-300" type="text" placeholder="Réf"
                          v-model="lineAddArticle_ref" @keyup.enter="addManualLine()" />

                        <div class="mt-1 flex flex-col">
                          <div class="relative">
                            <input type="text" v-model="lineAddArticle_marque" @focus="showAllBrands"
                              @input="checkBrand()" @keyup.enter="selectFirstBrand()" placeholder="Marque"
                              class="text-sm border px-4 py-0.5 mb-1 focus:outline-2 outline-sky-300 w-full" />
                            <span
                              class="material-icons-outlined absolute text-[15px] right-2 top-1.5 mb-2 text-gray 700 cursor-pointer"
                              @click="showAllBrands">
                              keyboard_arrow_down
                            </span>
                          </div>
                          <button @click="lineAddArticle_marque = null"
                            class="material-icons-round absolute text-xs text-red-500 hover:text-red-400 mt-1.5 ml-1 mr-0.5">
                            cancel
                          </button>
                          <div v-if="lineAddArticle_marque_List.length > 0" @click="lineAddArticle_marque_List = []"
                            class="absolute w-screen h-screen z-20 top-0 left-0"></div>
                          <div v-if="lineAddArticle_marque_List.length > 0"
                            class="absolute mt-7 z-20 bg-white border border-gray-300 max-h-40 overflow-y-auto">
                            <ul>
                              <li v-for="(
                brand, index
              ) in lineAddArticle_marque_List" :key="index" @click="selectBrand(brand)"
                                class="cursor-pointer hover:bg-sky-100 px-4 py-1">
                                {{ brand }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class="px-14 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none" colspan="3">
                      <div class="flex flex-col overflow-y-auto text-sm leading-5 whitespace-normal">
                        <input class="text-sm border px-1 py-0.5 focus:outline-2 outline-sky-300" type="text"
                          placeholder="Description" v-model="lineAddArticle_desc" @keyup.enter="addManualLine()" />

                        <div class="mt-1 flex">
                          <button @click="lineAddArticle_fournisseur_selected = null"
                            class="material-icons-round absolute text-xs text-red-500 hover:text-red-400 mt-1 ml-1">
                            cancel
                          </button>
                          <select v-model="lineAddArticle_fournisseur_selected" placeholder="Fournisseur"
                            class="text-sm border px-4 py-0.5 focus:outline-2 outline-sky-300">
                            <option value=""></option>
                            <option v-for="(c, index) in fournisseurList" :key="index" :value="c">
                              {{ c.CompanyName }}
                            </option>
                          </select>
                        </div>

                        <input class="mt-1 border px-1 py-0.5 focus:outline-2 outline-sky-300" type="number"
                          placeholder="Poids en g" v-model="lineAddArticle_poids" @keyup.enter="addManualLine()" />
                      </div>
                    </td>

                    <!-- <td
    class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
  >
    
  </td> -->

                    <td class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH">
                      <div class="flex flex-col">
                        <input class="mx-auto border rounded border-gray-400 focus:outline-2 outline-sky-300"
                          style="width: 45%" type="number" v-model="lineAddArticle_quantity"
                          @keyup.enter="addManualLine()" />
                        <div class="flex flex-col space-y-1 mt-1">
                          <button class="text-sm bg-bluevk hover:bg-sky-300 shadow-lg p-1 text-white rounded-md"
                            @click="addManualLine()">
                            Valider saisie
                          </button>
                          <button @click="showSaisieChaud = false"
                            class="text-sm bg-gray-100 hover:bg-gray-200 shadow-lg p-1 text-bluevk rounded-md">
                            Annuler
                          </button>
                        </div>
                      </div>
                    </td>

                    <td class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH">
                      <div class="flex flex-col space-y-1">
                        <div>
                          PA :
                          <input class="border rounded border-gray-400 focus:outline-2 outline-sky-300"
                            style="width: 35%" type="number" v-model="lineAddArticle_pa"
                            @keyup.enter="addManualLine()" />
                          €
                        </div>
                        <div>
                          PV :
                          <input class="border rounded border-gray-400 focus:outline-2 outline-sky-300"
                            style="width: 35%" type="number" v-model="lineAddArticle_pv"
                            @keyup.enter="addManualLine()" />
                          €
                        </div>
                      </div>
                    </td>

                    <td class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH">
                      <div class="mb-2 flex items-center space-x-1">
                        <span class="text-blue-800 text-sm">Remise</span>
                        <label class="relative inline-flex items-center cursor-pointer">
                          <input type="checkbox" v-model="stateTypeSaisie" @change="handleSaisieChange"
                            class="sr-only peer" />
                          <div
                            class="w-7 h-4 bg-blue-700 peer-focus:outline-none text-left dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:left-[3px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-blue-800 peer-checked:bg-orange-500">
                          </div>
                          <span class="ml-1 text-orange-500 text-sm">Prix net</span>
                        </label>
                      </div>

                      <div v-if="!stateTypeSaisie" class="flex justify-center">
                        <input class="border rounded border-gray-400 focus:outline-2 outline-sky-300" style="width: 35%"
                          type="number" v-model="lineAddArticle_remise" @keyup.enter="addManualLine()" />
                        %
                      </div>
                      <div v-else>prix net</div>
                    </td>

                    <!-- <td
    class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
  ></td> -->
                    <td
                      class="px-6 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap tableTDTH">
                      <input class="border rounded border-gray-400 focus:outline-2 outline-sky-300" style="width: 35%"
                        type="number" v-model="calculatedValue" @input="updateManualValue" />
                      €
                    </td>

                    <td
                      class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH">
                      {{
                        financial(
                          (lineAddArticle_pv -
                            lineAddArticle_pv * (lineAddArticle_remise / 100)) *
                          lineAddArticle_quantity
                        )
                      }}
                      €
                    </td>

                    <td
                      class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH">
                      {{
                        financial(
                          (lineAddArticle_pv -
                            lineAddArticle_pv * (lineAddArticle_remise / 100)) *
                          lineAddArticle_quantity *
                          0.2 +
                          (lineAddArticle_pv -
                            lineAddArticle_pv *
                            (lineAddArticle_remise / 100)) *
                          lineAddArticle_quantity
                        )
                      }}
                      €
                    </td>
                  </tr>
                </tbody>
                <thead class="tableHEADTR">
                  <tr class="tableHEADTR">
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                      <div
                        class="flex w-22 cursor-pointer items-center px-2 py-2 text-gray-600 border rounded-md hover:text-white hover:bg-blue-600 hover:opacity-40 m-auto"
                        @click="clearPanier()">
                        <span class="material-icons-outlined" style="color: red">delete_forever</span>
                        <button class="text-xs mx-4">
                          <span class="font-bold" href="#">Vider</span>
                        </button>
                      </div>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                    </th>
                    <th
                      class="w-96 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                      <div class="relative flex flex-col">
                        <span>Réf. client :</span>
                        <div class="my-6 absolute inset-y-0 left-0 flex items-center px-1 text-gray-700 h-4"
                          @click="documentData.Note = ''">
                          <span style="font-size: 15px"
                            class="material-icons-outlined cursor-pointer rounded-full bg-sky-200 hover:bg-sky-300 text-white hover:from-transparent">cancel</span>
                        </div>
                        <input
                          class="block border rounded-md pl-5 pr-1 py-1 border-2 border-sky-200 focus:outline-2 outline-sky-300 text-sm font-bold uppercase"
                          v-model="documentData.Note" />
                      </div>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                      {{ setQuantity }} pcs
                    </th>
                    <th
                      class="w-1 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                    </th>
                    <th
                      class="w-86 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                      <div class="relative flex flex-col">
                        <span>Remise gén. :</span>
                        <div class="my-6 absolute inset-y-0 left-0 flex items-center px-1 text-gray-700 h-4"
                          @click="docToModify.pdfData[0].remisegenerale = 0">
                          <span style="font-size: 15px"
                            class="material-icons-outlined cursor-pointer rounded-full bg-sky-200 hover:bg-sky-300 text-white hover:from-transparent">cancel</span>
                        </div>
                        <input type="number" style="width: 12vh" min="0" max="100" maxlength="3" step="5"
                          class="block border rounded-md pl-5 pr-1 py-1 border-2 border-sky-200 focus:outline-2 outline-sky-300 text-sm font-bold uppercase"
                          v-model="docToModify.pdfData[0].remisegenerale" />
                      </div>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                      <!-- {{ documentData }} -->
                      <div v-if="documentData.clientInfo" class="relative flex flex-col">
                        <span class="">Frais de port :</span>
                        <div class="my-6 absolute inset-y-0 left-0 flex items-center px-1 text-gray-700 h-4">
                          <span style="font-size: 15px"
                            class="material-icons-outlined cursor-pointer rounded-full bg-sky-200 hover:bg-sky-300 text-white hover:from-transparent">cancel</span>
                        </div>
                        <input type="number" min="0" v-model="documentData.Transport"
                          class="block border rounded-md w-3/4 pl-5 pr-1 py-1 border-2 border-sky-200 focus:outline-2 outline-sky-300 text-sm font-bold uppercase" />
                      </div>
                    </th>
                    <th
                      class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH">
                      <div v-if="docToModify.pdfData[0].remisegenerale != 0.0" class="text-sm leading-5 text-gray-900">
                        <div>
                          Total HT :
                          {{ financial(setTotalSansRemise) }} €
                        </div>
                        <div>Total HT Remisé : {{ financial(setTotal) }} €</div>
                      </div>
                      <div v-else class="flex flex-col text-sm leading-5 text-gray-900">
                        Total HT :
                        {{ financial(setTotal) }} €
                      </div>
                      <div class="text-sm leading-5 text-gray-500">
                        Total TTC :
                        {{ financial(setTotalTTC) }} €
                      </div>
                    </th>
                    <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <!-- <button
        class="px-6 py-2 mt-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
      >
        Encaisser
      </button> -->
      </div>
    </div>
  </div>

  <div v-else>
    <div class="flex">
      <span>Veuillez ouvrir la caisse pour pouvoir utiliser le comptoir</span>
    </div>
    <div class="flex">
      <router-link to="/caisse">
        <button
          class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none">
          Aller à la caisse
        </button>
      </router-link>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { createlivraison } from "@/hooks/pdf/livraison";
import { createfacture } from "@/hooks/pdf/facture";
import { updateStockByQuantity } from "@/hooks/stock/stock";
import { mvStock } from "@/hooks/stock/stock.ts";
import {
  deleteByBLNumber,
  updateByBLTotal,
} from "@/hooks/transport/panierTransport.ts";
import { createGroupedDoc } from "@/hooks/pdf/groupedDoc.js";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";
import backButton from "@/components/Button/back.vue";
import { createSaisieRapide } from "@/hooks/articles/articles";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      totalDebutFrais: "",
      documentDataClient: [],
      openSave: false,
      openRetour: false,
      showPro: true,
      showPar: false,
      showArticleList: false,
      showAjoutArticle: false,
      openRecapVH: false,
      cmdEncaissementVH: false,
      detailRecapVH: false,
      openCmdVH: false,
      tabRetourHistorique: true,
      Clients: [],
      clientChosed: "",
      groupClient: "",
      articleSearch: "",
      foundArticle: [],
      documentType: "Bon de livraison",
      titreDocument: "",
      noteDocument: "",
      remisegenerale: "",
      totalSansRemise: "",
      historique: [],
      numDoc: 0,
      tel: "",
      franco: "",
      qtyTab: [],
      historiqueShow: false,
      remiseTab: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ],
      // remiseTabSCD: [
      //   0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      //   0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      //   0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      // ],
      prixRemise: 0,
      checkboxDevis: false,
      checkboxFacturation: false,
      checkboxFacturation1: false,
      tabFilterHistorique: [],
      reglementTab: [],
      errorPaymentMethod: false,
      fournisseurs: [],
      fournisseursModel: [],
      tempRemiseFourn: [],
      retourSelected: [],
      retour_id: "",
      retour_art: "",
      // reglementMethod: "",
      showAjoutRemise: true,
      ajoutCode_ean: "",
      ajoutReference: "",
      ajoutMarque: "",
      ajoutDescription: "",
      ajoutFournisseur: null,
      ajoutQuantite: "",
      ajoutPrix: "",
      ajoutCode_famille: "",
      ajoutCode_sous_famille: "",
      ajoutPA: "",
      ajoutPNT1: "",
      ajoutPNT2: "",
      ajoutPNT3: "",
      ajoutCodeRemise: "",
      promoShow: false,
      tabPromo: [],
      indexArticlePromo: 0,
      dataConsigne: [],
      showClientPar: false,
      showOutilsClient: false,
      showNameSaiseChaud: false,
      showNameSaiseComment: false,
      showNameCmdVH: false,
      showNameCmdVH2: false,
      showNameDoyen: false,
      showNameIdlp: false,
      showNameAutoPartner: false,
      showNameDaSilva: false,
      showNameMeyle: false,
      showNameProcodis: false,
      showNameVanWezel: false,
      showNameSkf: false,
      showNameKraftwerk: false,
      showNameJbm: false,
      showNameHistClient: false,
      showNameClientPar: false,

      qtyCmdVH: [],
      showDispoCmdVH: [],
      TabCmdFactureVHtemp: [],
      TabCreateFacture: [],
      TabCmdBLVHtemp: [],
      TabCreateLivraison: [],
      TotalPriceTemp: "",
      loadingCommand: false,
      commandState: false,
      resultA05: [],
      savea02Tab: [],

      professionnelParticulier: false,
      nomParticulier: "",
      prenomParticulier: "",
      adresseParticulier: "",
      complementParticulier: "",
      codepostalParticulier: "",
      villeParticulier: "",
      paysParticulier: "FRANCE",
      emailParticulier: "",
      telephoneParticulier: "",

      tabDispoUAN: [],
      showDispo: [],
      openDetailsDispo: false,
      indexDelaisLivraison: 0,
      openRefSimil: false,
      PrixLot: [],
      indexRemise: 0,
      openRefArticle: false,
      // showClickRef: [],
      indexRef: 0,
      RefSim: [],
      showPA: [],
      showMarge: [],
      showTTC1: [],
      showTTC2: [],
      // showAddRemise: [],
      vehicule_set: false,
      loadingPNT: false,
      errorEncaissement: false,
      fournisseurList: [],
      originalBrandList: [],
      lineAddArticle_quantity: 1,
      lineAddArticle_pv: null,
      lineAddArticle_pa: null,
      stateTypeSaisie: false,
      lineAddArticle_remise: 0,
      lineAddArticle_marque: null,
      lineAddArticle_marque_List: [],
      lineAddArticle_ref: "",
      lineAddArticle_desc: "",
      lineAddArticle_poids: "",
      lineAddArticle_fournisseur_selected: null,
      showSaisieChaud: false,
      showSaisieComment: false,
      showInputSaisieComment: [],
      saisiecommentaire: "",
      autoCompleteTab: [],
      showAutoComplete: false,
      documentData: { Articles: [{ Prix_euro: 0.0, quantity: 0 }] },

      indexDepot: 0,
      depotTab: [],
      // logCode: "9980177",
      depot: "STANDARD",
      documentTMP: [],
      deleteArticleTab: [],
      addedArticleTab: [],

      loadingModify: false,
      showModal: false,
      modifierModal: false,
    };
  },
  components: {
    PulseLoader,
    backButton,
  },
  computed: {
    ...mapGetters([
      "actionArticle",
      "user",
      "plateform",
      "caisse",
      "crossoetab",
      "generalloading",
      "comptoirsearchtab",
      "carcatToken",
      "carCatLink",
      "carCatReference",
      "clientFocus",
      "comptoirSession",
      "prixtab",
      "vehicule",
      "vehiculeSet",
      "VH_a02Tab",
      "VH_cmd_Loading",
      "VH_cmd_state",
      "VH_articleTab",
      "docToModify",
    ]),

    setTotal() {
      var total = 0.0;
      var totalRemise = 0.0;
      var totalRemiseGen = 0.0;
      var totaldebut = 0.0;
      // var totalRemiseSupp = 0.0;
      this.documentData.Articles.forEach((element, index) => {
        if (element.PrixFournisseurType == "prixnet") {
          totalRemise = parseFloat(element.Prix_vente);
        } else {
          totalRemise =
            parseFloat(element.Prix_euro) -
            parseFloat(element.Prix_euro) *
            (this.documentData.Articles[index].remise / 100);
          // totalRemiseSupp =
          //   totalRemise - totalRemise * (this.remiseTabSCD[index] / 100);
        }
        totaldebut =
          totaldebut + totalRemise * this.documentData.Articles[index].quantity;
        this.totalDebutFrais = totaldebut;
      });

      if (parseFloat(this.documentDataClient.francodeport) >= 0) {
        if (totaldebut >= parseFloat(this.documentDataClient.francodeport)) {
          this.documentData.Transport == "0";

          total = totaldebut + parseFloat(this.documentData.Transport);
        } else {
          total = totaldebut + parseFloat(this.documentData.Transport);
        }
      }
      totalRemiseGen =
        parseFloat(totaldebut) -
        parseFloat(totaldebut) *
        (this.docToModify.pdfData[0].remisegenerale / 100) +
        parseFloat(this.documentData.Transport);

      if (totaldebut >= 0) {
        if (
          this.docToModify.pdfData[0].remisegenerale &&
          this.docToModify.pdfData[0].remisegenerale != 0
        ) {
          return totalRemiseGen;
        } else {
          return total;
        }
      } else {
        if (
          this.docToModify.pdfData[0].remisegenerale &&
          this.docToModify.pdfData[0].remisegenerale != 0
        ) {
          return totalRemiseGen;
        } else {
          return totaldebut;
        }
      }
    },
    setTotalTTC() {
      return this.setTotal + this.setTotal * 0.2;
    },
    setTotalSansRemise() {
      // var total = 0.0;
      var totalFinal = 0.0;
      var totalFrais = this.totalDebutFrais;

      // total = totalFrais - totalFrais * (this.comptoirSessionActive[0].remisegenerale / 100);

      totalFinal =
        parseFloat(totalFrais) + parseFloat(this.documentData.Transport);

      return totalFinal;
    },
    setQuantity() {
      var qty = 0;
      this.documentData.Articles.forEach((element) => {
        qty = qty + parseFloat(element.quantity);
      });
      return qty;
    },
    setRestant() {
      var restant = 0;
      var paye = 0;
      this.reglementTab.forEach((element) => {
        paye = paye + parseFloat(element.model);
      });
      restant = parseFloat(this.setTotalTTC) - paye;
      if (restant < 0) {
        restant = 0;
      }
      return restant;
    },
    setRendu() {
      var rendu = 0;
      var paye = 0;
      this.reglementTab.forEach((element) => {
        paye = paye + parseFloat(element.model);
      });
      if (paye > this.setTotalTTC) {
        rendu = Math.abs(paye - this.setTotalTTC);
      }
      return rendu;
    },
    articleTotalLKQ() {
      return this.VH_a02Tab.length;
    },
  },
  methods: {
    ...mapActions([
      "addactionArticle",
      "suppactionArticle",
      "clearactionArticle",
      "updateactionArticle",
      "updatestockactionArticle",
      "updateremiseactionArticle",
      "addcaisse",
      "suppStockArticle",
      "addStockArticle",
      // "setMvtStock",
      "searchCross",
      "searchDirect",
      "searchTranslatedDirect",
      "searchTranslatedDirectAdd",
      "storecarcattoken",
      "clearcarcattoken",
      "addpaniercarcat",
      "setcarcatlinks",
      "clearclientfocus",
      "setprixquantitatif",
      "removeprixquantitatif",
      "checkArticleVH",
      "clearVHloadingstate",
      "suppVHloadingstate",
      "suppVH_a02Tab",
      "clearVH_a02Tab",
      "clearvehicule",
      "getpanierfournisseur",
      "suppVH_PrixLot",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    changeDocType() {
      this.documentType = "Facturation";
      this.clientChosed = "";
      this.tel = "";
      this.franco = "";
    },
    changeDocType1() {
      this.documentType = "Bon de livraison";
      this.clientChosed = "";
      this.tel = "";
      this.franco = "";
    },
    getFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.fournisseurList = response.data;
        });
    },
    async addManualLine() {
      if (
        this.lineAddArticle_marque != null &&
        this.lineAddArticle_fournisseur_selected != null &&
        this.lineAddArticle_ref != "" &&
        this.lineAddArticle_desc != "" &&
        this.lineAddArticle_quantity != "" &&
        this.lineAddArticle_pv != null &&
        this.lineAddArticle_pa != null
      ) {
        var dataTab = {};
        var m = Math;
        var d = Date;
        var h = 16;
        var s = (s) => m.floor(s).toString(h);
        dataTab._id =
          s(d.now() / 1000) +
          " ".repeat(h).replace(/./g, () => s(m.random() * h));

        dataTab.quickEntry = true;
        dataTab.quantity = this.lineAddArticle_quantity;
        dataTab.quantityPrep = 0;
        dataTab.quantityReturned = 0;
        dataTab.remise = this.lineAddArticle_remise;
        dataTab.commentaire = "";
        dataTab.quantityPrep = 0;
        dataTab.prixtotal = dataTab.Prix_euro;
        dataTab.prixfinal = dataTab.Prix_euro;
        dataTab.Ref_fournisseur = this.lineAddArticle_ref;
        dataTab.Code_marque = this.lineAddArticle_marque;
        dataTab.Description = this.lineAddArticle_desc;
        dataTab.Poids = this.lineAddArticle_poids;
        dataTab.Prix_euro = this.lineAddArticle_pv;
        dataTab.Prix_vente = this.lineAddArticle_pv;
        dataTab.Prix_achat = this.lineAddArticle_pa;
        dataTab.PrixFournisseurType = this.stateTypeSaisie
          ? "prixnet"
          : "remise";
        if (this.lineAddArticle_fournisseur_selected) {
          dataTab.fournisseur =
            this.lineAddArticle_fournisseur_selected?.CompanyName;
          dataTab.Prefixe_tarif =
            this.lineAddArticle_fournisseur_selected?.Prefixe_tarif;
          dataTab.FournisseurSelected =
            this.lineAddArticle_fournisseur_selected?.CompanyName;
        } else {
          dataTab.FournisseurSelected = "Aucun";
        }

        this.documentData.Articles.push(dataTab);

        await createSaisieRapide({
          type: "piece",
          plateform: this.user.proprietaire,
          Prefixe_tarif:
            this.lineAddArticle_fournisseur_selected?.Prefixe_tarif,
          Ref_fournisseur: this.lineAddArticle_ref,
          Description: this.lineAddArticle_desc,
          Code_EAN: "",
          Code_marque: this.lineAddArticle_marque,
          Poids: this.lineAddArticle_poids,
          fournisseur: this.lineAddArticle_fournisseur_selected.CompanyName,
          Prix_euro: this.lineAddArticle_pv,
          Prix_achat: this.lineAddArticle_pa,
          PrixFournisseurType: this.stateTypeSaisie ? "prixnet" : "remise",
          quantity: this.lineAddArticle_quantity,
          remise: this.lineAddArticle_remise,
          Code_remise: "divers",
        });

        this.showSaisieChaud = false;
        this.originalBrandList = [];
        this.lineAddArticle_quantity = 1;
        this.lineAddArticle_pv = null;
        this.lineAddArticle_pa = null;
        this.stateTypeSaisie = false;
        this.lineAddArticle_remise = 0;
        this.lineAddArticle_marque = null;
        this.lineAddArticle_marque_List = [];
        this.lineAddArticle_ref = "";
        this.lineAddArticle_desc = "";
        this.lineAddArticle_poids = "";
        this.lineAddArticle_fournisseur_selected = null;
      } else {
        this.toast.warning("Remplissez les champs obligatoires !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    setRemiseArticle(ind, rem, qty, prix) {
      const PrixFinal = parseFloat(prix) - parseFloat(prix) * (rem / 100);
      const prixTotal = PrixFinal * qty;
      const dataArt = {
        indexArt: ind,
        quantity: qty,
        remise: rem,
        prixtotal: prixTotal,
        prixfinal: PrixFinal,
      };
      this.documentData.Articles[ind].remise = dataArt.remise;
      this.documentData.Articles[ind].prixfinal = dataArt.prixfinal;
      this.documentData.Articles[ind].quantity = dataArt.quantity;
      this.documentData.Articles[ind].prixtotal = dataArt.prixtotal;
    },
    async handlerArticle(data, stockData) {
      var existArticle = false;
      for (const [index, actArticle] of this.documentData.Articles.entries()) {
        if (
          actArticle.Ref_fournisseur == data.Ref_fournisseur &&
          actArticle.Prefixe_tarif == data.Prefixe_tarif
        ) {
          this.documentData.Articles[index].quantity = actArticle.quantity + 1;
          existArticle = true;
          this.setRemiseArticle(
            index,
            actArticle.remise,
            actArticle.quantity,
            actArticle.Prix_euro
          );
        }
      }
      if (existArticle == false) {
        const checkPromo = await axios.post(
          `${process.env.VUE_APP_API}/promo/getone`,
          {
            promoinfo: {
              plateformID: this.user.proprietaire,
              Ref_fournisseur: data.Ref_fournisseur,
              Code_marque: data.Code_marque,
            },
          }
        );
        const foundFournisseur = await axios.post(
          `${process.env.VUE_APP_API}/fournisseur/getone`,
          {
            Prefixe_tarif: data.Prefixe_tarif,
            plateform: this.user.proprietaire,
            client: this.clientChosed,
          }
        );

        var dataTab = data;

        if (foundFournisseur.data.length > 0) {
          dataTab.PrixFournisseurType = foundFournisseur.data[0].typeCondition;
          dataTab.FournisseurSelected = foundFournisseur.data[0].CompanyName;
          if (foundFournisseur.data[0].typeCondition == "prixnet") {
            switch (this.groupClient) {
              case "T1":
                dataTab.Prix_vente = data.Prix_euro;
                dataTab.Prix_euro = data.PNT1;
                break;
              case "T2":
                dataTab.Prix_vente = data.Prix_euro;
                dataTab.Prix_euro = data.PNT2;
                break;
              case "T3":
                dataTab.Prix_vente = data.Prix_euro;
                dataTab.Prix_euro = data.PNT3;
                break;
              case "T4":
                dataTab.Prix_vente = data.Prix_euro;
                dataTab.Prix_euro = data.PNT4;
                break;
              case "T5":
                dataTab.Prix_vente = data.Prix_euro;
                dataTab.Prix_euro = data.PNT5;
                break;
              case "T6":
                dataTab.Prix_vente = data.Prix_euro;
                dataTab.Prix_euro = data.PNT6;
                break;
              default:
                dataTab.Prix_vente = data.Prix_euro;
                break;
            }
          }
        } else {
          // dataTab.PrixFournisseurType = "None";
          dataTab.FournisseurSelected = "Aucun";
          dataTab.Prix_vente = data.Prix_euro;
          dataTab.PNT1 = data.Prix_euro;
          dataTab.PNT2 = data.Prix_euro;
          dataTab.PNT3 = data.Prix_euro;
          dataTab.PNT4 = data.Prix_euro;
          dataTab.PNT5 = data.Prix_euro;
          dataTab.PNT6 = data.Prix_euro;
          dataTab.PNF = data.Prix_euro;
        }

        if (data.Prefixe_tarif == "Vhi") {
          const getPrixLot = await axios.get(
            `${process.env.VUE_APP_API}/vanheck/getPrixLot/${data.Ref_fournisseur}`
          );

          getPrixLot.data.length > 0
            ? (dataTab.PrixLotVH = true)
            : (dataTab.PrixLotVH = false);
        }

        checkPromo.data.length > 0
          ? (dataTab.Promo_set = true)
          : (dataTab.Promo_set = false);

        dataTab.quantity = 1;
        dataTab.remise = await this.getRemiseFourni(dataTab);
        dataTab.commentaire = "";
        dataTab.prixtotal = dataTab.Prix_euro;
        dataTab.prixfinal = dataTab.Prix_euro;
        stockData.length > 0
          ? (dataTab.stockData = stockData[0].stock)
          : (dataTab.stockData = 0);
        await this.addConsigne(dataTab);
        await this.documentData.Articles.push(dataTab);
        let tmpArt = { ...dataTab };
        tmpArt.quantity = 0;
        this.documentTMP.push(tmpArt);
        this.addedArticleTab.push(dataTab);
      }
      this.showAutoComplete = false;
      this.showArticleList = false;
      this.articleSearch = "";
    },
    addConsigne() {
      this.dataConsigne = this.comptoirsearchtab;

      if (
        this.dataConsigne.Montant_consigne &&
        this.dataConsigne.Montant_consigne > 0
      ) {
        // this.dataConsigne.Ref_fournisseur =
        //   "Consigne : " + this.dataConsigne.Ref_fournisseur;
        // this.dataConsigne.Prix_euro = this.comptoirsearchtab.Montant_consigne;
        // dataConsigne.quantity = 1;
        // dataConsigne.remise = 0;
        // dataConsigne.prixtotal = dataConsigne.Prix_euro;
        // dataConsigne.prixfinal = dataConsigne.Prix_euro;
        this.documentData.Articles.push({
          _id: this.comptoirsearchtab._id,
          Ref_fournisseur: "C : " + this.comptoirsearchtab.Ref_fournisseur,
          Prix_euro: this.comptoirsearchtab.Montant_consigne,
          Prix_vente: this.comptoirsearchtab.Montant_consigne,
          PrixFournisseurType: "prixnet",
          disabled: true,
          quantity: this.comptoirsearchtab.quantity,
          quantityPrep: 0,
          quantityReturned: 0,
          remise: 0,
          prixtotal: this.comptoirsearchtab.Prix_euro,
          prixfinal: this.comptoirsearchtab.Prix_euro,
          Code_marque: this.comptoirsearchtab.Code_marque,
          Description: this.comptoirsearchtab.Description,
        });
      }
    },
    getArticles() {
      axios.get(`${process.env.VUE_APP_API}/article/count`).then((response) => {
        this.articleNumber = response.data;
      });
    },
    postAjoutArticle() {
      if (!this.showAjoutRemise) {
        axios.post(`${process.env.VUE_APP_API}/article`, {
          Code_EAN: this.ajoutCode_ean,
          Ref_fournisseur: this.ajoutReference,
          Code_marque: this.ajoutMarque,
          Description: this.ajoutDescription,
          fournisseur: this.ajoutFournisseur.CompanyName,
          Prefixe_tarif: this.ajoutFournisseur.Prefixe_tarif,
          Qte_mini: this.ajoutQuantite,
          Prix_euro: this.ajoutPrix,
          Code_famille_NU: this.ajoutCode_famille,
          Code_sousfamille_NU: this.ajoutCode_sous_famille,
          PNF: this.ajoutPA,
          PNT1: this.ajoutPNT1,
          PNT2: this.ajoutPNT2,
          PNT3: this.ajoutPNT3,
          plateform: this.user.proprietaire,
        });
      } else {
        axios.post(`${process.env.VUE_APP_API}/article`, {
          Code_EAN: this.ajoutCode_ean,
          Ref_fournisseur: this.ajoutReference,
          Code_marque: this.ajoutMarque,
          Description: this.ajoutDescription,
          fournisseur: this.ajoutFournisseur.CompanyName,
          Prefixe_tarif: this.ajoutFournisseur.Prefixe_tarif,
          Qte_mini: this.ajoutQuantite,
          Prix_euro: this.ajoutPrix,
          Code_famille_NU: this.ajoutCode_famille,
          Code_sousfamille_NU: this.ajoutCode_sous_famille,
          Code_remise: this.ajoutCodeRemise,
          plateform: this.user.proprietaire,
        });
      }
    },
    getAjoutArticle() {
      if (!this.showAjoutRemise) {
        this.documentData.Articles.push({
          _id: this.ajoutReference + this.ajoutMarque,
          Code_EAN: this.ajoutCode_ean,
          Ref_fournisseur: this.ajoutReference,
          Code_marque: this.ajoutMarque,
          Description: this.ajoutDescription,
          fournisseur: this.ajoutFournisseur.CompanyName,
          FournisseurSelected: this.ajoutFournisseur.CompanyName,
          Prefixe_tarif: this.ajoutFournisseur.Prefixe_tarif,
          quantity: this.ajoutQuantite,
          Prix_euro: this.ajoutPrix,
          Code_famille_NU: this.ajoutCode_famille,
          Code_sousfamille_NU: this.ajoutCode_sous_famille,
          PNF: this.ajoutPA,
          PNT1: this.ajoutPNT1,
          PNT2: this.ajoutPNT2,
          PNT3: this.ajoutPNT3,
          PrixFournisseurType: "remise",
        });
      } else {
        this.documentData.Articles.push({
          _id: this.ajoutReference + this.ajoutMarque,
          Code_EAN: this.ajoutCode_ean,
          Ref_fournisseur: this.ajoutReference,
          Code_marque: this.ajoutMarque,
          Description: this.ajoutDescription,
          fournisseur: this.ajoutFournisseur.CompanyName,
          FournisseurSelected: this.ajoutFournisseur.CompanyName,
          Prefixe_tarif: this.ajoutFournisseur.Prefixe_tarif,
          quantity: this.ajoutQuantite,
          Prix_euro: this.ajoutPrix,
          Code_famille_NU: this.ajoutCode_famille,
          Code_sousfamille_NU: this.ajoutCode_sous_famille,
          Code_remise: this.ajoutCodeRemise,
          PrixFournisseurType: "prixnet",
        });
        this.prepareQuantity();
      }
    },
    clearPanier() {
      this.clearactionArticle();
      this.documentData.Articles = [];
      this.clearVHloadingstate();
      this.showSaisieChaud = false;
    },
    retourArticles(quantite, art) {
      if (quantite < 0) {
        this.openRetour = true;
        this.fetchHistoriqueRetour(art.Ref_fournisseur);
        this.fetchHistoriqueRetour1(art.Ref_fournisseur);
        this.retour_id = art._id;
        this.retour_art = art.Ref_fournisseur;
      }
    },
    attachRetour(historiqueFileSelected) {
      this.retourSelected = [];
      this.tabRetourHistorique = false;
      this.retourSelected = historiqueFileSelected;
    },
    addRetour() {
      this.openRetour = false;
      this.tabRetourHistorique = !this.tabRetourHistorique;
      this.documentData.Articles.push({
        _id: this.retour_id,
        Ref_fournisseur: "Retour sur Facture n°" + this.retourSelected.Numero,
        Description: this.retourSelected.Date,
        Prix_vente: 0,
        qtyTab: 0,
        remiseTab: 0,
        Prix_euro: 0,
      });
    },
    getFactureArticle(value, state) {
      if (state == true) {
        this.tabFilterHistorique.push(value);
        this.fetchHistoriqueFiltred();
      } else {
        var indexD = this.tabFilterHistorique.indexOf(value);

        if (indexD > -1) {
          this.tabFilterHistorique.splice(indexD, 1);
        }
        this.fetchHistoriqueFiltred();
      }
    },
    getClients() {
      axios
        .get(`${process.env.VUE_APP_API}/client/${this.user.proprietaire}`)
        .then((response) => {
          this.Clients = response.data;
        });
    },
    getPartClients() {
      this.Clients = [];
      axios
        .get(
          `${process.env.VUE_APP_API}/client/clientParticulier/${this.user.proprietaire}`
        )
        .then((response) => {
          this.Clients = response.data;
        });
    },
    postPartClients() {
      axios
        .post(`${process.env.VUE_APP_API}/client`, {
          professionnel: this.professionnelParticulier,
          Name: this.prenomParticulier + " " + this.nomParticulier,
          Adresse: this.adresseParticulier,
          Complement: this.complementParticulier,
          PostalCode: this.codepostalParticulier,
          City: this.villeParticulier,
          Country: this.paysParticulier,
          email: this.emailParticulier,
          telephone: this.telephoneParticulier,
        })
        .then(() => {
          this.getClients();
          this.open = false;
          this.toast.success("Client ajouté !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    addToCommandVH(Ref, Qty, index) {
      this.checkArticleVH({
        Ref_fournisseur: Ref,
        Quantity: Qty,
        logCode: this.plateform.grossierID,
        index: index,
        name: this.depot,
      });
    },
    deleteLine(index) {
      this.suppVH_a02Tab({
        index: index,
        name: this.depot,
        indexDepot: this.indexDepot,
      });
      this.suppVH_PrixLot({ index: index });
    },
    async getOneArticle() {
      this.showAutoComplete = false;
      if (this.articleSearch != "") {
        // axios
        //   .post(`${process.env.VUE_APP_API}/article/getone`, {
        //     Ref_fournisseur: this.articleSearch,
        //   })
        //   .then((response) => {
        //     this.foundArticle = response.data;
        //   });

        await this.searchTranslatedDirect({
          artNum: this.articleSearch,
          marque: "*",
          plateform: this.user.proprietaire,
        });

        this.articleSearch = "";
      } else {
        // this.getArticles(10,10);

        if (this.comptoirsearchtab.length == 1) {
          this.handlerArticle(
            this.comptoirsearchtab[0].article[0],
            this.comptoirsearchtab[0].stockData
          );
          this.showAutoComplete = false;
          this.showArticleList = false;
          this.articleSearch = "";
          //revenirici
        }
      }
    },
    getDoc() {
      if (this.documentType) {
        if (this.documentType == "Facturation") {
          if (this.TotalPriceTemp >= 0) {
            createfacture(this.TabCreateFacture, false, false);
          } else {
            createfacture(this.TabCreateFacture, false, true);
          }

          this.$router.push("/facture");
        }
        if (this.documentType == "Bon de livraison") {
          if (this.TotalPriceTemp >= 0) {
            createlivraison(this.TabCreateLivraison, false, false);
          } else {
            createlivraison(this.TabCreateLivraison, false, true);
          }

          this.$router.push("/bdl");
        }
      }
    },
    getCmdVH() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;

      var pdfinfo = [];

      var prixRemise = 0.0;

      this.reglementTab.push({
        icon: "money",
        placeholder: "Reste dû",
        model: parseFloat(this.setRestant).toFixed(2),
        date: "",
        disabled: false,
        user: this.user.username,
      });

      // this.reglementTab.push({
      //   icon: "money",
      //   placeholder: "Rendu",
      //   model: parseFloat(this.setRendu).toFixed(2),
      //   date: "",
      // });

      this.documentData.Articles.forEach((element, index) => {
        prixRemise =
          element.Prix_euro - element.Prix_euro * (element.remise / 100);
        // prixRemiseSupp =
        //   prixRemise - prixRemise * (this.remiseTabSCD[index] / 100);
        pdfinfo[index] = {
          description: element.Description,
          ref: element.Ref_fournisseur,
          quantite: element.quantity,
          remise: element.remise,
          commentaire: element.commentaire,
          remisegenerale: this.this.docToModify.pdfData[0].remisegenerale,
          // remiseSupp: this.remiseTabSCD[index],
          prixU: prixRemise,
          total: prixRemise * element.quantity,
          totalSansRemise: this.setTotalSansRemise,
        };
        if (this.documentType != "Devis") {
          this.suppStockArticle({
            id: element.Code_EAN,
            Stock: element.stockData,
            inputstock: element.quantity,
          });
          mvStock({
            plateform: this.user.proprietaire,
            Ref_fournisseur: element.Ref_fournisseur,
            Code_EAN: element.Code_EAN,
            Prefixe_tarif: element.Prefixe_tarif,
            User: this.user.username,
            Description: "Transformation devis vers facture : " + "Facture",
            OldStock: parseInt(element.Stock),
            NewStock: parseInt(element.Stock) - parseInt(element.quantity),
          });
        }
      });

      if (this.documentType) {
        this.commandState = false;
        this.loadingCommand = true;
        if (this.documentType == "Facturation") {
          axios
            .post(
              `${process.env.VUE_APP_API}/commandeFournisseur/commanderA05`,
              {
                infoCommande: this.VH_a02Tab[this.indexDepot].lines,
                plateform: this.user.proprietaire,
                Document: this.TabCmdFactureVHtemp,
                Articles: this.VH_articleTab,
                User: this.user.username,
                login: this.plateform.grossierID,
              }
            )
            .then((res) => {
              this.loadingCommand = false;
              this.commandState = true;
              this.resultA05 = res.data;
              this.savea02Tab = this.VH_a02Tab[this.indexDepot].lines;
              this.clearVH_a02Tab();

              this.toast.success("Commande passée !", {
                position: "bottom-right",
                timeout: 1000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 1,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              if (this.TotalPriceTemp >= 0) {
                createfacture(this.TabCreateFacture, false, false);
              } else {
                createfacture(this.TabCreateFacture, false, true);
              }

              this.$router.push("/facture");
            });
        }
        if (this.documentType == "Bon de livraison") {
          axios
            .post(
              `${process.env.VUE_APP_API}/commandeFournisseur/commanderA05`,
              {
                infoCommande: this.VH_a02Tab[this.indexDepot].lines,
                plateform: this.user.proprietaire,
                Document: this.TabCmdBLVHtemp,
                Articles: this.VH_articleTab,
                User: this.user.username,
                login: this.plateform.grossierID,
              }
            )
            .then((res) => {
              this.loadingCommand = false;
              this.commandState = true;
              this.resultA05 = res.data;
              this.savea02Tab = this.VH_a02Tab[this.indexDepot].lines;
              this.clearVH_a02Tab();

              this.toast.success("Commande passée !", {
                position: "bottom-right",
                timeout: 1000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 1,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });

              if (this.TotalPriceTemp >= 0) {
                createlivraison(this.TabCreateLivraison, false, false);
              } else {
                createlivraison(this.TabCreateLivraison, false, true);
              }

              this.$router.push("/bdl");
            });
        }
      }
    },
    setReglement(method) {
      switch (method) {
        case "es":
          this.reglementTab.push({
            img: require("../../src/assets/icons_payments/ESP.png"),
            icon: "payments",
            placeholder: "Espèce",
            model: parseFloat(this.setRestant).toFixed(2),
            date: "",
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "cb":
          this.reglementTab.push({
            img: require("../../src/assets/icons_payments/CB.png"),
            icon: "credit_card",
            placeholder: "Carte Bancaire",
            model: parseFloat(this.setRestant).toFixed(2),
            date: "",
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "cc":
          this.reglementTab.push({
            img: require("../../src/assets/icons_payments/CHQ.png"),
            icon: "money",
            placeholder: "Chèque Comptant",
            model: parseFloat(this.setRestant).toFixed(2),
            date: "",
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "vi":
          this.reglementTab.push({
            img: require("../../src/assets/icons_payments/Virement.png"),
            icon: "account_balance",
            placeholder: "Virement",
            model: parseFloat(this.setRestant).toFixed(2),
            date: "26/04/2022",
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "lc":
          this.reglementTab.push({
            img: require("../../src/assets/icons_payments/LCR.png"),
            icon: "account_balance",
            placeholder: "LCR",
            model: parseFloat(this.setRestant).toFixed(2),
            date: "",
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        default:
          this.errorPaymentMethod = true;
          break;
      }
    },
    removeReglement(index) {
      this.reglementTab.splice(index, 1);
    },
    getFournisseurDispo() {
      axios
        .get(`${process.env.VUE_APP_API}/matriceFourniSet/getall`)
        .then((response) => {
          this.fournisseurs = response.data;
        });
    },
    initFournisseursModel() {
      this.documentData.Articles.forEach((element) => {
        this.fournisseursModel.push(element.FournisseurSelected);
      });
    },
    async getRemiseFourni(article) {
      var remiseToReturn = 0;
      if (article.Code_remise == "") {
        article.Code_remise = "99";
      }
      await axios
        .post(
          `${process.env.VUE_APP_API}/conditionFournisseur/getClientCondition`,
          {
            plateform: this.user.proprietaire,
            fournisseur: article.FournisseurSelected,
            article: article,
          }
        )
        .then((response) => {
          if (response.data[0]) {
            this.tempRemiseFourn[this.documentData.Articles.length] =
              response.data[0].remiseFournisseur;
          }
          if (article.prix_quantitatif && article.prix_quantitatif == true) {
            remiseToReturn = 0;
          } else {
            if (response.data[0]) {
              switch (this.documentData.clientInfo[0].Group.Name) {
                case "T1":
                  remiseToReturn = response.data[0].T1;
                  break;
                case "T2":
                  remiseToReturn = response.data[0].T2;
                  break;
                case "T3":
                  remiseToReturn = response.data[0].T3;
                  break;
                case "T4":
                  remiseToReturn = response.data[0].P1;
                  break;
                case "T5":
                  remiseToReturn = response.data[0].P2;
                  break;
                case "T6":
                  remiseToReturn = response.data[0].P3;
                  break;
                default:
                  remiseToReturn = 0;
                  break;
              }
            }
          }
        });
      return remiseToReturn;
    },
    getMarge(prix, prix_achat, remise, type) {
      if (type == "Remise") {
        prix = prix - prix * (remise / 100);
        // prix = prix - prix * (remiseSupp / 100);
        var marge = prix - prix_achat;
        return parseInt((marge / prix) * 100);
      } else if (type == "PrixNet") {
        prix = prix - prix * (remise / 100);
        // prix = prix - prix * (remiseSupp / 100);
        var margePN = prix - prix_achat;
        return parseInt((margePN / prix) * 100);
      }
    },
    setPrixNetPrice() {
      if (this.documentData.Articles.length > 0) {
        this.loadingPNT = true;
        this.documentData.Articles.forEach((element, index) => {
          if (element.PrixFournisseurType == "prixnet") {
            switch (this.groupClient) {
              case "T1":
                this.documentData.Articles[index].Prix_euro =
                  this.documentData.Articles[index].PNT1;
                this.loadingPNT = false;
                break;
              case "T2":
                this.documentData.Articles[index].Prix_euro =
                  this.documentData.Articles[index].PNT2;
                this.loadingPNT = false;
                break;
              case "T3":
                this.documentData.Articles[index].Prix_euro =
                  this.documentData.Articles[index].PNT3;
                this.loadingPNT = false;
                break;
              case "T4":
                this.documentData.Articles[index].Prix_euro =
                  this.documentData.Articles[index].PNT4;
                break;
              case "T5":
                this.documentData.Articles[index].Prix_euro =
                  this.documentData.Articles[index].PNT5;
                this.loadingPNT = false;
                break;
              case "T6":
                this.documentData.Articles[index].Prix_euro =
                  this.documentData.Articles[index].PNT6;
                this.loadingPNT = false;
                break;
              default:
                this.documentData.Articles[index].Prix_euro =
                  this.documentData.Articles[index].Prix_vente;
                this.loadingPNT = false;
                break;
            }
          }
        });
      }
    },
    getGroupClient() {
      if (this.clientFocus != "" && this.clientChosed == "") {
        this.clientChosed = this.clientFocus;
      }
      if (this.clientChosed != "") {
        axios
          .post(
            `${process.env.VUE_APP_API}/client/getone/${this.clientChosed}`,
            {
              plateform: this.user.plateform,
            }
          )
          .then((response) => {
            if (response.data[0]) {
              if (response.data[0].professionnel) {
                this.groupClient = response.data[0].Group.Name;
                // this.comptoirSession[0].Client = response.data[0];
                this.tel = response.data[0].telephone;
                this.franco = response.data[0].francodeport;
                this.setPrixNetPrice();
              } else {
                // this.comptoirSession[0].Client = response.data[0];
                this.tel = response.data[0].telephone;
                this.setPrixNetPrice();
              }
            }
          });
      } else {
        this.groupClient = "";
        this.clientChosed = "";
      }
      this.$forceUpdate();
    },
    closeCarCat() {
      axios
        .post(
          `https://lkq-europe-prod.apigee.net/gms-api-public-iframe-routes-fr/iframe/retrieve-orders`,
          {
            IDToken: this.carcatToken.id_token,
            ReferenceIdentifier: this.carCatReference,
            PerPage: 1000,
            Page: 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.carcatToken.access_token}`,
              "X-api-key": "IbW4A7bqa447M2c7WhFwagVtbMjL9DLd",
            },
          }
        )
        .then((result) => {
          result.data.Data.Workorders[0].Lines.forEach((element) => {
            this.addpaniercarcat(element.LineCode);
          });
        })
        .catch((error) => {
          this.clearcarcattoken();
        });
    },
    openPromo(reference, marque, index) {
      this.promoShow = true;
      this.indexArticlePromo = index;
      axios
        .post(`${process.env.VUE_APP_API}/promo/getone`, {
          promoinfo: {
            plateformID: this.user.proprietaire,
            Ref_fournisseur: reference,
            Code_marque: marque,
          },
        })
        .then((res) => {
          this.tabPromo = res.data;
        });
    },
    setPrixQuantitatif(quantite, prix) {
      if (
        this.documentData.Articles[this.indexArticlePromo].prix_quantitatif &&
        this.documentData.Articles[this.indexArticlePromo].prix_quantitatif ==
        true
      ) {
        this.removeprixquantitatif({ index: this.indexArticlePromo });
      }
      this.documentData.Articles[this.indexArticlePromo].quantity = quantite;
      this.documentData.Articles[this.indexArticlePromo].remise = 0;
      this.setprixquantitatif({
        index: this.indexArticlePromo,
        quantite: quantite,
        prix: prix,
        remise: 0,
      });
    },
    setPrixNet(quantite, prix) {
      if (
        this.documentData.Articles[this.indexArticlePromo].prix_quantitatif &&
        this.documentData.Articles[this.indexArticlePromo].prix_quantitatif ==
        true
      ) {
        this.removeprixquantitatif({ index: this.indexArticlePromo });
      }
      this.documentData.Articles[this.indexArticlePromo].quantity = quantite;
      this.documentData.Articles[this.indexArticlePromo].remise = 0;
      this.setprixquantitatif({
        index: this.indexArticlePromo,
        quantite: quantite,
        prix: prix,
      });
    },
    setRemisePromo(remise) {
      if (
        this.documentData.Articles[this.indexArticlePromo].prix_quantitatif &&
        this.documentData.Articles[this.indexArticlePromo].prix_quantitatif ==
        true
      ) {
        this.removeprixquantitatif({ index: this.indexArticlePromo });
      }
      this.remiseTab[this.indexArticlePromo] = remise;
    },
    getDispos() {
      this.documentData.Articles.forEach((element, index) => {
        if (element.Prefixe_tarif == "Vhi") {
          this.getDispo(
            element.Ref_fournisseur,
            element.Code_famille_fournisseur,
            element.quantity,
            index
          );
          this.showDispo[index] = true;
        }
      });
    },
    getVh() {
      this.VH_articleTab.forEach((element, index) => {
        this.getDispo(
          element[0].Ref_fournisseur,
          element[0].Code_famille_fournisseur,
          this.qtyCmdVH[0],
          index
        );
      });
    },
    getDispoCmdVH(ref, groupe, quantity, index) {
      this.indexDelaisLivraison = index;
      axios
        .post(`${process.env.VUE_APP_API}/vanheck/getStock/${ref}`, {
          groupe: groupe,
          quantity: quantity,
          login: this.plateform.grossierID,
        })
        .then((response) => {
          this.showDispoCmdVH[index] = true;
          this.tabDispoUAN[index] = response.data;
        });
    },
    getDispo(ref, groupe, quantity, index) {
      this.indexDelaisLivraison = index;
      axios
        .post(`${process.env.VUE_APP_API}/vanheck/getStock/${ref}`, {
          groupe: groupe,
          quantity: quantity,
          login: this.plateform.grossierID,
        })
        .then((response) => {
          this.tabDispoUAN[index] = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },
    getPrixLot(ref, index) {
      this.indexRemise = index;
      axios
        .get(`${process.env.VUE_APP_API}/vanheck/getPrixLot/${ref}`)
        .then((response) => {
          this.PrixLot[index] = response.data;
        });
    },
    getRefSimilaire(ref, index) {
      this.indexRef = index;
      axios
        .post(`${process.env.VUE_APP_API}/vanheck/getRefSimilaire`, {
          Ref_fournisseur: ref,
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.RefSim[index] = response.data;
        });
    },
    autoComplete(ref) {
      if (this.articleSearch != "") {
        axios
          .get(`${process.env.VUE_APP_API}/article/autocomplete/${ref}`)
          .then((response) => {
            this.showAutoComplete = true;
            this.autoCompleteTab = response.data;
          });
      } else {
        this.showAutoComplete = false;
      }
    },
    setAutoComplete(ref) {
      this.articleSearch = ref;
      this.showAutoComplete = false;
      this.getOneArticle();
      this.showAjoutArticle = false;
      this.showArticleList = true;
    },
    // async getBdlsOfTheDayByClient() {
    //   await axios
    //     .post(`${process.env.VUE_APP_API}/bdl/getBdlsOfTheDay`, {
    //       plateform: this.user.plateform,
    //       clientId: this.documentData.clientInfo[0]._id,
    //       returnData: true,
    //       user: this.user.username,
    //     })
    //     .then((response) => {
    //       console.log(response);
    //     });
    // },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
    success() {
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    initDocument() {
      this.documentData = this.docToModify;
      for (const art of this.documentData.Articles) {
        this.documentTMP.push({
          quantity: art.quantity,
          Ref_fournisseur: art.Ref_fournisseur,
        });
      }
      this.documentDataClient = this.docToModify.clientInfo[0];
      if (this.docToModify.pdfData[0].remisegenerale) {
        this.remisegenerale = this.docToModify.pdfData[0].remisegenerale;
      }
    },
    async modifyDocument() {
      try {
        this.loadingModify = true;

        this.documentData.Articles.forEach((element, index) => {
          var prixRemise =
            element.Prix_euro -
            element.Prix_euro * (this.remiseTab[index] / 100);
          this.documentData.pdfData[index] = {
            description: element.Description,
            ref: element.Ref_fournisseur,
            quantite: element.quantity,
            remise: element.remise,
            commentaire: element.commentaire ? element.commentaire : "",
            remisegenerale: this.docToModify.pdfData[0].remisegenerale,
            // Transport: element.fraisdeportsave,
            // remiseSupp: this.remiseTabSCD[index],
            prixU: prixRemise,
            total: prixRemise * element.quantity,
            totalSansRemise: this.setTotalSansRemise,
          };
        });
        // this.documentData.Transport = this.documentData.Transport;

        this.documentData.TotalPrice = this.setTotal;
        this.documentData.TotalPriceTTC = this.setTotalTTC;

        await updateByBLTotal(
          this.user.proprietaire,
          this.documentData.clientInfo[0]._id,
          this.documentData.Numero.toString(),
          this.setTotal
        );

        await axios.put(
          `${process.env.VUE_APP_API}/bdl/${this.documentData._id}`,
          this.documentData
        );

        for (const [indexArt, art] of this.documentData.Articles.entries()) {
          if (art.quantity !== this.documentTMP[indexArt].quantity) {
            await updateStockByQuantity(
              this.user._id,
              this.user.proprietaire,
              art._id,
              art.Ref_fournisseur,
              art.Code_marque,
              art.Code_EAN,
              0,
              0,
              10,
              "",
              this.user.username,
              "BL n°" +
              this.documentData.Numero +
              ": " +
              art.Ref_fournisseur +
              " modifié par " +
              this.user.username,
              art.Prefixe_tarif,
              this.documentData.Client,
              this.documentData.Numero,
              "BL",
              "modification BL",
              -(art.quantity - this.documentTMP[indexArt].quantity)
            );
          }
        }
        for (const dArt of this.deleteArticleTab) {
          await updateStockByQuantity(
            this.user._id,
            this.user.proprietaire,
            dArt._id,
            dArt.Ref_fournisseur,
            dArt.Code_marque,
            dArt.Code_EAN,
            0,
            0,
            10,
            "",
            this.user.username,
            "BL n°" +
            this.documentData.Numero +
            ": " +
            dArt.Ref_fournisseur +
            " supprimé par " +
            this.user.username,
            dArt.Prefixe_tarif,
            this.documentData.Client,
            this.documentData.Numero,
            "BL",
            "modification BL",
            dArt.quantity
          );
        }

        this.loadingModify = false;
        this.showModifBL = false;

        this.toast.success(
          "Les modifications ont été effectuées avec succès!",
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );

        this.$router.push("/bdl");
      } catch (error) {
        this.toast.error("Une erreur s'est produite lors des modifications !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.loadingModify = false;
      }
    },
    deleteArticle(index) {
      //find if article is in addedArticleTab
      const articleToDelete = this.documentData.Articles[index];
      if (
        !this.addedArticleTab.find(
          (art) => art.Ref_fournisseur === articleToDelete.Ref_fournisseur
        )
      ) {
        this.deleteArticleTab.push(articleToDelete);
      }
      this.documentData.Articles.splice(index, 1);
      this.documentData.pdfData.splice(index, 1);
      this.documentTMP.splice(index, 1);
    },
    async confirmModification() {
      this.loadingModify = true;
      try {
        await this.modifyDocument();
        this.createPDF(this.documentData);
      } catch (error) {
        console.error("Modification erreur:", error);
      } finally {
        this.loadingModify = false;
        this.modifierModal = false;
      }
    },
    async confirmDelete() {
      try {
        this.showModal = false;
        await this.deleteDocument();
        this.toast.success("Le document a été supprimé avec succès !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } catch (error) {
        this.toast.error("Une erreur s'est produite lors de la suppression !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    createPDF(objet) {
      if (objet.groupedTab && objet.groupedTab.length > 0) {
        this.openGroupedPDF(objet);
      } else {
        var today = new Date();
        var da = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        var dateedition = getFrDateSansH(objet.Date);
        var datepdf = da + "/" + mm + "/" + yyyy;
        if (dateedition == datepdf) {
          if (objet.TotalPrice >= 0) {
            createlivraison(objet, false, false);
          } else {
            createlivraison(objet, false, true);
          }
        } else if (objet.TotalPrice >= 0) {
          createlivraison(objet, true, false);
        } else {
          createlivraison(objet, true, true);
        }
      }
    },
    openGroupedPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createGroupedDoc(objet, "Bon de livraison", "BL", false);
      } else {
        createGroupedDoc(objet, "Bon de livraison", "BL", true);
      }
    },
    async deleteDocument() {
      for (const art of this.documentData.Articles) {
        await updateStockByQuantity(
          this.user._id,
          this.user.proprietaire,
          art._id,
          art.Ref_fournisseur,
          art.Code_marque,
          art.Code_EAN,
          0,
          0,
          10,
          "",
          this.user.username,
          "BL n°" +
          this.documentData.Numero +
          " supprimé par " +
          this.user.username,
          art.Prefixe_tarif,
          this.documentData.Client,
          this.documentData.Numero,
          "BL",
          "suppression BL",
          -art.quantity
        );
      }

      await deleteByBLNumber(
        this.user.proprietaire,
        this.documentData.clientInfo[0]._id,
        this.documentData.Numero.toString()
      );

      await axios
        .put(
          `${process.env.VUE_APP_API}/bdl/removeBdl/${this.documentData._id}`,
          this.documentData
        )
        .then(() => {
          this.showModifBL = false;
          this.$router.push("/bdl");
        });
    },
    getFournisseursDepot() {
      axios
        .post(`${process.env.VUE_APP_API}/panierFournisseur/getactivated`, {
          plateform: this.user.proprietaire,
          fournisseur: "VAN HECK",
        })
        .then((res) => {
          // this.fournisseurs = res.data;
          if (res.data[0].accountActivated) {
            this.depotTab = res.data[0].Credential[0].entrepot;
          }
        });
    },
    changeDepot(depotToChange, indexToChange, codeDepot) {
      this.depot = depotToChange;
      this.plateform.grossierID = codeDepot;
      this.indexDepot = indexToChange + 1;
      this.getpanierfournisseur({ name: depotToChange });
    },
    async fetchAllBrands() {
      const chkBrand = await axios.post(
        `${process.env.VUE_APP_API}/inventaire/checkBrand`,
        {
          plateform: this.user.proprietaire,
          brand: "",
        }
      );
      if (chkBrand.data) {
        this.originalBrandList = chkBrand.data.map((brand) => brand.mfrName);
        this.lineAddArticle_marque_List = this.originalBrandList;
      }
    },
    showAllBrands() {
      if (this.originalBrandList.length === 0) {
        this.fetchAllBrands();
      } else {
        this.lineAddArticle_marque_List = this.originalBrandList;
      }
    },
    async checkBrand() {
      const searchTerm = this.lineAddArticle_marque.toUpperCase();
      if (searchTerm !== "") {
        this.lineAddArticle_marque_List = this.originalBrandList.filter(
          (brand) => brand.toUpperCase().includes(searchTerm)
        );
      } else {
        this.lineAddArticle_marque_List = this.originalBrandList;
      }
    },
    selectBrand(brand) {
      this.lineAddArticle_marque = brand;
      this.lineAddArticle_marque_List = [];
    },
    selectFirstBrand() {
      if (this.lineAddArticle_marque_List.length > 0) {
        this.selectBrand(this.lineAddArticle_marque_List[0]);
      }
    },
  },
  beforeMount() {
    this.getpanierfournisseur({ name: this.depot });
    this.getFournisseursDepot();
  },
  mounted() {
    this.initDocument();
    this.getClients();
    this.getFournisseur();
    this.getArticles();
    this.getFournisseurDispo();
    this.initFournisseursModel();
    this.getGroupClient();
    // this.prepareQuantity();
    // this.getComment()
    if (this.vehicule.length > 0) {
      this.noteDocument =
        this.vehicule[0].data.AWN_immat +
        " " +
        this.vehicule[0].data.AWN_modele;
    }
  },
};
</script>
<style scoped>
.modal {
  transition: opacity 0.25s ease;
}

.tableTDTH {
  text-align: left;
  padding: 8px;
}

.tableBODY {
  display: block;
  height: 55vh;
  overflow: auto;
}

.tableHEADTR {
  display: table;
  width: 100%;
  table-layout: fixed;
}
</style>
